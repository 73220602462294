import React, {FC} from 'react';
import {formatDate} from '../util/timestamp-formatter';

export interface DateRangeViewProps {
  className?: string;
  from?: Date | null;
  to?: Date | null;
}

export const DateRangeView: FC<DateRangeViewProps> = (props) => {
  const {from, to, ...rest} = props;
  return (
    <div data-cy='date-range-view' {...rest}>
      {(from || to) && (
        <p className='whitespace-nowrap'>
          gültig
          {from && ` ab dem ${formatDate(from)}`}
          {to && ` bis zum ${formatDate(to)}`}
        </p>
      )}
    </div>
  );
};
