import React, {FC, useState} from 'react';
import {Edit} from '@mui/icons-material';
import {PoiType} from '../../poi.generated';
import FormButton from '../../../../components/forms/form-button';
import StatusesForm from './statuses-form';
import StatusList from './status-list';
import {useStatusMessagesQuery} from '../../../../common/queries/status-messages.generated';
import {useUser} from '../../../../context/user/user';
import {FeatureGate} from '../../../../components/feature-gate';
import {FeatureFlagNames} from '../../../../__generated__/graphql';

const Statuses: FC<{poi: PoiType}> = ({poi}) => {
  const [editing, setEditing] = useState(false);
  const user = useUser();

  const [statusMessagesQuery] = useStatusMessagesQuery({
    variables: {poiType: poi.__typename, facilityId: user?.facilityId},
    pause: !user,
  });

  if (statusMessagesQuery.data?.statusMessages.length === 0) {
    return null;
  }

  return (
    <div data-cy='statuses' className='relative w-full bg-ochre-light px-6 py-4 rounded-lg'>
      <div className='flex items-center justify-between'>
        <p className='text-md font-bold'>Statusmeldungen</p>
        <FeatureGate hasOneOf={{name: FeatureFlagNames.SetPoiStatus, poiType: poi.__typename}}>
          <div className='flex'>
            {!editing && (
              <FormButton
                type='button'
                onClick={() => {
                  setEditing(true);
                }}
                data-cy='edit-statuses'
              >
                <Edit className='p-1' />
              </FormButton>
            )}
          </div>
        </FeatureGate>
      </div>
      {editing && statusMessagesQuery.data ? (
        <StatusesForm
          poi={poi}
          close={() => setEditing(false)}
          statusMessages={statusMessagesQuery.data.statusMessages}
        />
      ) : (
        <StatusList statuses={poi.allStatuses} />
      )}
    </div>
  );
};
export default Statuses;
