import React, {FC} from 'react';
import {Add} from '@mui/icons-material';
import {useUser} from '../../../context/user/user';
import {useModalDialog} from '../../../components';
import UserList from './components/user-list';
import {UserFormDialog} from './components/user-form-dialog';
import {ConfirmDialog} from '../../../components/dialogs/confirm-dialog';
import {useUsersQuery} from './users.generated';
import {useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation} from './users-mutations.generated';

export const UsersPage: FC = () => {
  const currentUser = useUser();

  const [usersQuery] = useUsersQuery({
    variables: {facilityId: currentUser?.facilityId},
    pause: !currentUser,
  });

  const [, createUser] = useCreateUserMutation();
  const [, updateUser] = useUpdateUserMutation();
  const [, deleteUser] = useDeleteUserMutation();

  const showDialog = useModalDialog();

  if (!currentUser || usersQuery.data == null) {
    return <div>Lade...</div>;
  }

  return (
    <div className='mb-4'>
      <UserList
        items={usersQuery.data?.users || []}
        onEditUser={async (user) => {
          const updatedUser = await showDialog(UserFormDialog, {user});
          if (updatedUser) {
            await updateUser({input: {id: user.id, ...updatedUser}});
          }
        }}
        onDeleteUser={async (user) => {
          const confirmed = await showDialog(ConfirmDialog, {
            title: 'Löschen',
            description: 'Der User wird unwiderruflich gelöscht und kann sich nicht mehr einloggen.',
            confirmText: 'Löschen',
          });
          if (confirmed) {
            await deleteUser({input: {id: user.id}});
          }
        }}
      />
      <button
        className='flex items-center justify-center mt-3 py-2 text-gray-400 border-dashed border-gray-300 border w-full uppercase font-semibold text-sm hover:border-solid hover:border-ochre hover:bg-ochre hover:text-white'
        data-cy='add-user'
        onClick={async () => {
          const newUser = await showDialog(UserFormDialog, {
            user: {id: '', email: '', roles: []},
            isNew: true,
          });
          if (newUser) {
            await createUser({input: newUser});
          }
        }}
      >
        <Add fontSize='inherit' className='mr-1' />
        Neuer Nutzer
      </button>
    </div>
  );
};
