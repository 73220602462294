import * as Types from '../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type PoiTypesQueryVariables = Types.Exact<{[key: string]: never}>;

export type PoiTypesQuery = {__typename?: 'Query'; poiTypes: Array<PoiTypeDefinition>};

export type PoiTypeDefinition = {__typename?: 'POITypeDefinition'; types: Array<string>; name: string};

export const PoiTypesDocument = gql`
  query PoiTypes {
    poiTypes @export(exportName: "PoiTypeDefinition") {
      types
      name
    }
  }
`;

export function usePoiTypesQuery(options?: Omit<Urql.UseQueryArgs<PoiTypesQueryVariables>, 'query'>) {
  return Urql.useQuery<PoiTypesQuery>({query: PoiTypesDocument, ...options});
}
