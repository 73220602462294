import {FC} from 'react';
import {usePanoramasQuery, Panorama, useUpdatePanoramasMutation} from './panoramas.generated';
import {useUser} from '../../../../../context/user/user';
import {PanoramaInput} from './panorama-input';
import {PanoramaLocation} from '../../../../../__generated__/graphql';
import {useUrqlRequest} from '../../../../../hooks/useUrqlRequest';
import {TextButton} from '../../../../../components';
import {useForm, Controller, SubmitHandler} from 'react-hook-form';
import {useOutcomeToast} from '../../../../../hooks/useOutcomeToast';

type PanoramaForm = Partial<Record<PanoramaLocation, File>>;
const locations = Object.values(PanoramaLocation);

export const PanoramaList: FC = () => {
  const user = useUser();
  const [{data}] = useUrqlRequest(usePanoramasQuery, {variables: {facilityId: user?.facilityId}});
  const {handleSubmit, control, reset} = useForm<PanoramaForm>();
  const [, updatePanoramas] = useUpdatePanoramasMutation();

  const locationToPanorama: Partial<Record<PanoramaLocation, Panorama>> = {};
  const panoramas = data?.panoramasForFacility ?? [];
  for (const panorama of panoramas) {
    locationToPanorama[panorama.location] ??= panorama;
  }

  const toast = useOutcomeToast();

  const onSubmit: SubmitHandler<PanoramaForm> = async (form) => {
    const input = locations.map((l) => ({location: l, file: form[l]})).filter(({file}) => !!file);

    const result = await updatePanoramas({input: {panoramas: input}});
    const success = !result.error && !!result.data?.panoramasUpdate;
    toast(success);

    if (success) reset();
  };

  return (
    <form className='flex flex-col space-y-4' onSubmit={handleSubmit(onSubmit)}>
      <h2 className='text-2xl font-bold text-ochre-dark'>Bilder</h2>
      <p className='text-md text-black'>Bilder dürfen maximal 2MB groß sein.</p>
      {locations.map((location, i) => (
        <div className={i % 2 === 0 ? '' : 'bg-ochre-light'} key={location}>
          <Controller
            control={control}
            name={location}
            render={({field}) => (
              <PanoramaInput location={location} panorama={locationToPanorama[location]} onChange={field.onChange} />
            )}
          />
        </div>
      ))}
      <TextButton type='submit'>Speichern</TextButton>
    </form>
  );
};
