import React, {FC} from 'react';
import {FieldError, Path, RegisterOptions, UseFormRegister} from 'react-hook-form';

export interface LoginForm {
  email: string;
  password: string;
}

type Props = {
  type: string;
  placeholder: string;
  name: Path<LoginForm>;
  register: UseFormRegister<LoginForm>;
  error: FieldError | undefined;
  validation?: RegisterOptions;
};

const Input: FC<Props> = ({type, placeholder, name, register, error, validation}) => (
  <div className='w-full md:w-72 my-4'>
    <input
      type={type}
      placeholder={placeholder}
      className={`w-full px-1 border-0 border-b-2 focus:ring-0 focus:shadow-none focus:border-blue ${
        error ? 'border-red-light text-red-light placeholder-red-light' : 'border-ochre text-black placeholder-ochre'
      }`}
      {...register(name, validation)}
    />
    <div className='h-1 self-start px-1 text-red-light'>{error && error.message}</div>
  </div>
);
export default Input;
