import React, {FC, useContext, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useLoginMutation} from './login.generated';
import Input, {LoginForm} from './components/input';
import {TextButton} from '../../../components';
import {useUrqlRequest} from '../../../hooks/useUrqlRequest';
import {UserContext} from '../../../context/user/context';

const Login: FC = () => {
  const userContext = useContext(UserContext);
  const [loginResult, login] = useUrqlRequest(useLoginMutation);
  const {
    register,
    setError,
    formState: {errors},
    handleSubmit,
  } = useForm<LoginForm>();

  const onSubmit: SubmitHandler<LoginForm> = (data) =>
    login({input: data}).then((res) => {
      if (res.data?.authLogin) {
        userContext.update();
      }
    });

  useEffect(() => {
    if (loginResult.error) {
      const hasCode = (code: string) => loginResult.error?.graphQLErrors.some((e) => e.extensions?.code === code);
      if (hasCode('INVALID_CREDENTIAL')) {
        setError('password', {
          type: 'credentials',
          message: 'Falsches Passwort!',
        });
      } else if (hasCode('VALIDATION')) {
        setError('password', {
          type: 'minLength',
          message: 'Das Passwort muss mindestens 6 Zeichen lang sein!',
        });
      } else {
        setError('password', {
          message: 'Unbekannter Fehler!',
        });
      }
    }
  }, [loginResult, setError]);

  return (
    <form className='flex flex-col items-center' onSubmit={handleSubmit(onSubmit)}>
      <div className='self-start mb-6'>
        <p className='font-bold text-2xl'>Login</p>
        <div className='h-0.5 w-6 bg-ochre -mt-1 rounded-lg' />
      </div>

      <Input
        type='email'
        placeholder='E-Mail'
        register={register}
        name='email'
        error={errors.email}
        validation={{required: true}}
      />

      <Input
        type='password'
        placeholder='Passwort'
        register={register}
        name='password'
        error={errors.password}
        validation={{required: true}}
      />

      <TextButton type='submit'>Weiter</TextButton>
    </form>
  );
};

export default Login;
