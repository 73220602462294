import React, {FC} from 'react';
import {Announcement, Info, People} from '@mui/icons-material';
import {StatusMessagesPage} from './status-messages/status-messages-page';
import {UsersPage} from './users/users-page';
import {FeatureFlagNames} from '../../__generated__/graphql';
import {SidebarPageDefinition, SidebarWithPages} from '../../components/sidebar-with-pages/sidebar-with-pages';
import {SystemInfoPage} from './system-info/system-info-page';

export const Settings: FC = () => {
  const settingsPageDefs: SidebarPageDefinition[] = [
    {
      title: 'Statusmeldungen',
      icon: Announcement,
      routerId: 'status-messages',
      component: StatusMessagesPage,
      featureGate: FeatureFlagNames.ManageStatus,
    },
    {title: 'Nutzer', icon: People, routerId: 'users', component: UsersPage, featureGate: FeatureFlagNames.ManageUsers},
    {
      title: 'System',
      icon: Info,
      routerId: 'system-info',
      component: SystemInfoPage,
      featureGate: FeatureFlagNames.ManageUsers,
    },
  ];

  return <SidebarWithPages defs={settingsPageDefs} title='Einstellungen' />;
};
