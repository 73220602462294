import React, {FC} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {Delete, Menu} from '@mui/icons-material';
import {Control, Controller, UseFormRegister, UseFormStateReturn, useWatch} from 'react-hook-form';
import {NewsLinkForm} from '../form-type';
import {NewsLink as ServerNewsLink} from '../news.generated';
import {useDialog} from '../../../../context/dialog/dialog';
import {IconDefinition, IconPicker} from '../../../../components';

type FormErrors = UseFormStateReturn<NewsLinkForm>['errors'];

interface NewsLinkProps extends ServerNewsLink {
  index: number;
  onDelete: () => void;
  register: UseFormRegister<NewsLinkForm>;
  control: Control<NewsLinkForm>;
  errors: FormErrors;
  icons: IconDefinition[];
}

const getClassName = (errors: FormErrors, order: number, field: 'title' | 'href') => {
  const classes = ['flex-1 rounded-lg focus:border-blue-light'];
  const fieldErrors = errors.links?.[order]?.[field];
  if (fieldErrors) classes.push('border-2', 'border-red');
  return classes.join(' ');
};

const NewsLink: FC<NewsLinkProps> = ({title, href, id, index, icons, onDelete, register, control, errors}) => {
  const removeLink = useDialog({
    title: 'Link löschen',
    message: 'Wollen Sie den Link wirklich löschen?',
    actionCallback: onDelete,
  });

  const iconId = useWatch({
    name: `links.${index as 0}.iconId` as const,
    control,
  });

  return (
    <Draggable draggableId={id} index={index} key={id}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className='bg-white border shadow-lg p-6 flex items-center mb-6 rounded-lg'
        >
          <div {...provided.dragHandleProps}>
            <Menu className='text-black' />
          </div>
          <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 ml-4 flex-1 text-black'>
            <Controller
              name={`links.${index as 0}.iconId` as const}
              control={control}
              defaultValue={iconId}
              render={({field: {onChange}}) => <IconPicker icons={icons} value={iconId} onChange={onChange} />}
            />
            <input
              placeholder='Titel'
              defaultValue={title}
              className={getClassName(errors, index, 'title')}
              type='text'
              {...register(`links.${index}.title` as const, {required: true})}
              size={1}
            />
            <input
              placeholder='Link'
              defaultValue={href}
              className={getClassName(errors, index, 'href')}
              type='text'
              {...register(`links.${index}.href` as const, {required: true})}
              size={1}
            />
          </div>
          <button
            className='text-black ml-4'
            type='button'
            onClick={removeLink.getConfirmation}
            data-cy='delete-news-link'
          >
            <Delete />
          </button>
        </div>
      )}
    </Draggable>
  );
};

export default NewsLink;
