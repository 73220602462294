import {useMemo} from 'react';
import {User} from './user.generated';
import {FeatureFlagNames} from '../../__generated__/graphql';
import {useUser} from './user';

export type SingleFeatureGateSpecification = FeatureFlagNames | {name: FeatureFlagNames; poiType: string};

export type FeatureGateSpecification = SingleFeatureGateSpecification | SingleFeatureGateSpecification[];

export class FeatureFlags {
  constructor(private user: User | undefined) {}

  public hasOneOf(featureGate: FeatureGateSpecification): boolean {
    if (!this.user) return false;

    const featureGates = Array.isArray(featureGate) ? featureGate : [featureGate];

    return this.user.featureFlags.some((ff) =>
      featureGates.some((fg) => {
        if (ff.__typename === 'GeneralFeatureFlag') {
          return typeof fg === 'string' ? fg === ff.name : fg.name === ff.name;
        }
        if (ff.__typename === 'FeatureFlagForPOIType') {
          return typeof fg === 'string' ? fg === ff.name : fg.name === ff.name && fg.poiType === ff.pOIType;
        }

        return false;
      }),
    );
  }

  public hasUser(): boolean {
    return !!this.user;
  }
}

export const useFeatureFlags = (): FeatureFlags => {
  const user = useUser();

  return useMemo(() => new FeatureFlags(user), [user]);
};
