import React, {FC} from 'react';
import FocusLock from 'react-focus-lock';
import Loader from 'react-loader-spinner';
import {LoadingContext, LoadingProvider} from '../context/loading/loading';

interface LoadingContainerProps {
  children: React.ReactNode;
  containerClass: string;
}

const LoadingOverlay: FC = () => (
  <FocusLock>
    <div
      id='loading-overlay'
      tabIndex={-1}
      className='bg-black bg-opacity-10 absolute w-full h-full flex flex-col items-center p-8 outline-none'
    >
      <Loader type='TailSpin' color='#b59067' height={75} width={75} />
    </div>
  </FocusLock>
);

export const LoadingCard: FC<LoadingContainerProps> = ({children, containerClass}) => (
  <LoadingProvider>
    <div className='bg-white rounded-none md:rounded-lg shadow-card relative flex flex-col md:self-center overflow-hidden'>
      <LoadingContext.Consumer>{({isLoading}) => isLoading && <LoadingOverlay />}</LoadingContext.Consumer>
      <div className={containerClass}>{children}</div>
    </div>
  </LoadingProvider>
);
