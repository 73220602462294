import React, {ChangeEventHandler, forwardRef, useCallback} from 'react';
import clsx from 'clsx';
import {PoiTypeDefinition} from '../common/queries/poi-types.generated';

export type PoiType = PoiTypeDefinition & {disabled?: boolean};

export interface PoiTypeSelectorProps {
  className?: string;
  value: string;
  onChange?: (nextPoiType: string) => void;
  poiTypes: PoiType[];
}

const typenameDisplaynameOverrides: Record<string, string> = {
  GuidedTour: 'Führungen ',
};

export const PoiTypeSelector = forwardRef<HTMLSelectElement, PoiTypeSelectorProps>(
  ({onChange, value, poiTypes, className, ...rest}, ref) => {
    const handleChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
      (evt) => {
        if (onChange) {
          const {value: nextPoiType} = evt.target;
          onChange(nextPoiType);
        }
      },
      [onChange],
    );
    return (
      <select
        onChange={handleChange}
        className={clsx('border-transparent', className)}
        ref={ref}
        value={value}
        data-cy='poi-type-selector'
        {...rest}
      >
        {poiTypes.flatMap(({name, types, disabled}) =>
          types.map((type) => (
            <option key={type} value={type} disabled={disabled}>
              {typenameDisplaynameOverrides[type] ?? name}
            </option>
          )),
        )}
      </select>
    );
  },
);
