import React, {FC, useContext, useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import Login from './login/login';
import LoggedOutLayout from '../../layout/logged-out-layout/logged-out-layout';

import {UserContext} from '../../context/user/context';

const AuthRoutes: FC = () => {
  const {loggedIn} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) navigate('/');
  }, [loggedIn, navigate]);

  return (
    <LoggedOutLayout>
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='*' element={<Navigate to='login' />} />
      </Routes>
    </LoggedOutLayout>
  );
};

export default AuthRoutes;
