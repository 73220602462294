import {motion} from 'framer-motion';
import React, {FC, useState} from 'react';
import ScrollUpImage from '../../../assets/icons/scroll_up.png';

const ScrollUp: FC = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 300);
  };

  window.addEventListener('scroll', toggleVisibility);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <motion.div className={`${visible ? 'block' : 'hidden'} fixed inset-y-1/2 right-0`}>
      <button onClick={scrollUp} className='hover:brightness-75'>
        <img src={ScrollUpImage} className='w-16 shadow-lg' alt='Scroll Up Button' />
      </button>
    </motion.div>
  );
};
export default ScrollUp;
