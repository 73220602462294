import React, {FC, Fragment} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {AccountCircle, Notifications} from '@mui/icons-material';
import {Menu, Transition} from '@headlessui/react';
import NavigationLinkInterface from './navigation-link-interface';
import Logo from '../../../assets/logo.svg';

interface Props {
  navigationLinks: NavigationLinkInterface[];
  profileLinks: NavigationLinkInterface[];
}

const NavigationLink: FC<NavigationLinkInterface> = ({label, href, onClick, dataCy}) => (
  <NavLink
    to={href ?? ''}
    onClick={onClick}
    className={({isActive}) =>
      isActive
        ? 'bg-ochre-dark text-white px-3 py-2 rounded-md text-sm font-medium'
        : 'text-ochre hover:text-white hover:bg-ochre px-3 py-2 rounded-md text-sm font-medium'
    }
    data-cy={dataCy}
  >
    {label}
  </NavLink>
);

const ProfileLink: FC<NavigationLinkInterface> = ({label, href, onClick, dataCy}) => (
  <NavLink
    to={href ? `/${href}` : '.'}
    onClick={onClick}
    className={({isActive}) =>
      `${isActive ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100`
    }
    data-cy={dataCy}
  >
    {label}
  </NavLink>
);

const DesktopNavigation: FC<Props> = ({navigationLinks, profileLinks}) => (
  <div className='hidden md:block h-64 bg-white'>
    <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
      <div className='border-b border-ochre'>
        <div className='flex items-center justify-between h-16 px-4 sm:px-0'>
          <div className='flex items-center flex-grow'>
            <Link to='/' className='flex-shrink-0'>
              <img className='w-16' src={Logo} alt='Logo' />
            </Link>

            <div className='ml-10 flex items-baseline space-x-4'>
              {navigationLinks.map((link) => (
                <NavigationLink key={link.label} {...link} />
              ))}
            </div>

            <div className='ml-auto flex items-center'>
              <button
                type='button'
                className='p-1 text-ochre rounded-full hover:text-ochre-dark text-sm focus:outline-none'
              >
                <Notifications />
              </button>

              <Menu as='div' className='ml-3 relative'>
                {({open}) => (
                  <>
                    <div>
                      <Menu.Button
                        className='max-w-xs text-ochre-light bg-ochre rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-ochre-dark focus:ring-white hover:bg-ochre-dark'
                        data-cy='account-menu-button'
                      >
                        <AccountCircle />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='opacity-0 scale-95'
                      enterTo='opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='opacity-100 scale-100'
                      leaveTo='opacity-0 scale-95'
                    >
                      <Menu.Items
                        static
                        className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                      >
                        {profileLinks.map((link) => (
                          <Menu.Item key={link.label}>
                            <ProfileLink {...link} />
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DesktopNavigation;
