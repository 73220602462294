import React, {useCallback} from 'react';
import WeekdayDisplay from './weekday-display';
import {extractWeekdays, Weekday, WEEKDAYS, WeekdaySelection} from './weekdays';

interface WeekdayCheckboxProps {
  weekday: Weekday;
  label: string;
  onChange: (checked: boolean, weekday: Weekday) => void;
  value: boolean;
  readonly?: boolean;
}

const WeekdayCheckbox = React.forwardRef<HTMLLabelElement, WeekdayCheckboxProps>((props, forwardedRef) => {
  const {weekday, label, onChange, readonly, value} = props;

  const classes = readonly ? '' : 'cursor-pointer';

  return (
    <div role='checkbox' aria-checked={value}>
      <label
        className={`block focus-within:ring ring-red-900 ${classes}`}
        ref={forwardedRef}
        data-cy={`weekday-${weekday}`}
      >
        {!readonly && (
          <input
            aria-hidden
            className='sr-only'
            type='checkbox'
            checked={value}
            onChange={(evt) => {
              onChange(evt.target.checked, weekday);
            }}
          />
        )}
        <WeekdayDisplay label={label} enabled={value} />
      </label>
    </div>
  );
});

export interface WeekdayPickerProps {
  value: Partial<WeekdaySelection>;
  readonly?: boolean;
  onChange?: (value: WeekdaySelection) => void;
}

const WeekdayPicker = React.forwardRef<HTMLLabelElement, WeekdayPickerProps>((props, ref) => {
  const {value, onChange, readonly, ...rest} = props;
  const weekdays = extractWeekdays(value);
  const handleCheckboxChange = useCallback(
    (checked: boolean, weekday: Weekday) =>
      onChange &&
      onChange({
        ...weekdays,
        [weekday]: checked,
      }),
    [weekdays, onChange],
  );

  return (
    <div className='flex flex-wrap gap-2' data-cy='weekday-picker' {...rest}>
      {WEEKDAYS.map(({name, label}, i) => (
        <WeekdayCheckbox
          key={name}
          weekday={name}
          label={label}
          onChange={handleCheckboxChange}
          value={weekdays[name]}
          readonly={readonly}
          ref={i === 0 ? ref : null}
        />
      ))}
    </div>
  );
});

export default WeekdayPicker;
