import React, {FC} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {createModalDialog, ModalDialogHeader, useModalDialogController} from '../../../../components';
import FormField from '../../../../components/forms/form-field';
import {useUpdateCurrentUserPasswordMutation} from '../users-mutations.generated';

export interface UserPasswordFormData {
  currentPassword: string;
  newPassword: string;
}

const DialogContent: FC = () => {
  const [, updatePassword] = useUpdateCurrentUserPasswordMutation();

  const {register, handleSubmit, formState, setError} = useForm<UserPasswordFormData>({
    defaultValues: {},
  });

  const {resolve, dismiss} = useModalDialogController<boolean>();

  const submit: SubmitHandler<UserPasswordFormData> = async (input: UserPasswordFormData) => {
    const success = await updatePassword({input}).then(({data}) => data?.currentUserUpdatePassword);

    if (success) {
      resolve(true);
    } else if (success === false) {
      setError('currentPassword', {message: 'Falsches Passwort'});
    }
  };

  return (
    <>
      <ModalDialogHeader title='Passwort ändern' description='Geben Sie Ihr aktuelles und ein neues Passwort ein.' />
      <form onSubmit={handleSubmit(submit)} data-cy='user-password-form'>
        <div className='flex flex-row-reverse'>
          <div className='flex-1 relative'>
            <div className='pr-6 pl-4 py-3 w-full'>
              <FormField name='currentPassword' errors={formState.errors}>
                <label className='flex flex-col mt-5'>
                  <span className='text-sm'>Aktuelles Passwort</span>
                  <input
                    type='password'
                    autoComplete='current-password'
                    pattern='.{6,}'
                    title='6 oder mehr Zeichen'
                    className='bg-ochre bg-opacity-25 border-ochre text-black'
                    data-cy='input-current-password'
                    {...register('currentPassword', {required: true})}
                  />
                </label>
              </FormField>
              <FormField name='newPassword' errors={formState.errors}>
                <label className='flex flex-col mt-5'>
                  <span className='text-sm'>Neues Passwort</span>
                  <input
                    type='password'
                    autoComplete='new-password'
                    pattern='.{6,}'
                    title='6 oder mehr Zeichen'
                    className='bg-ochre bg-opacity-25 border-ochre text-black'
                    data-cy='input-new-password'
                    {...register('newPassword', {
                      required: true,
                    })}
                  />
                </label>
              </FormField>
            </div>
          </div>
        </div>
        <div className='flex mt-2'>
          <button
            type='button'
            className='flex-1 py-2 bg-gray-400 bg-opacity-25 text-black'
            onClick={dismiss}
            data-cy='dialog-cancel'
          >
            Abbrechen
          </button>
          <button type='submit' className='flex-1 py-2 bg-red text-white' data-cy='dialog-save'>
            Passwort ändern
          </button>
        </div>
      </form>
    </>
  );
};

export const CurrentUserPasswordDialog = createModalDialog(() => <DialogContent />, false);
