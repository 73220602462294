import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';

enum Lifecycle {
  Initial,
  BeforeFirstUpdate,
  AfterFirstUpdate,
}

/**
 * Like `React.useState`, but updates its state automatically if the value of `newestState` changes.
 *
 * Use this if you need a late binding of the initial state based on an asynchronous hook but still want to be
 * able to change the state manually as you would with `useState`.
 *
 * @param newestState An initial state that is expected to change at some point, e.g after a request.
 * @param keepTrackingAfterFirstUpdate Continue tracking `newestState` after the first time the state changed.
 */
export const useNewestState = <T>(
  newestState: T,
  keepTrackingAfterFirstUpdate = false,
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(newestState);
  const lifecycle = useRef(Lifecycle.Initial);
  const wrappedSetState = useCallback((action: SetStateAction<T>) => {
    setState(action);
    lifecycle.current = Lifecycle.AfterFirstUpdate;
  }, []);
  useEffect(() => {
    switch (lifecycle.current) {
      case Lifecycle.Initial:
        // Calling setState here is redundant because `state` is guaranteed to be identical to `newestState`.
        lifecycle.current = Lifecycle.BeforeFirstUpdate;
        break;
      case Lifecycle.BeforeFirstUpdate:
        setState(newestState);
        lifecycle.current = Lifecycle.AfterFirstUpdate;
        break;
      default:
        if (keepTrackingAfterFirstUpdate) {
          setState(newestState);
        }
        break;
    }
  }, [lifecycle, newestState, keepTrackingAfterFirstUpdate]);
  return [state, wrappedSetState];
};
