import React from 'react';
import {FieldError, FieldErrors, get} from 'react-hook-form';

export interface FormFieldProps {
  className?: string;
  errors?: FieldErrors | FieldError;
  name: string;
}

/**
 * Wrapper component for form fields.
 *
 * This displays an error message underneath the component if an error is detected
 * @param name `name of field related to wrapped component`
 * @param errors `errors` from `useForm() formState`
 * @constructor
 */
const FormField: React.FC<FormFieldProps> = ({name, errors, children, ...rest}) => {
  const error = errors && 'message' in errors ? errors : get(errors, name);
  return (
    <div>
      <div {...rest}>{children}</div>
      {error && error?.message ? (
        <div className='text-red text-sm mt-1' data-cy={`form-field-error-${name}`}>
          {error.message}
        </div>
      ) : null}
    </div>
  );
};

export default FormField;
