import * as Types from '../../../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PanoramaInput = {
  file: any;
  location: Types.PanoramaLocation;
};

export type UpdatePanoramasInput = {
  panoramas: Array<PanoramaInput>;
};

export type PanoramasQueryVariables = Types.Exact<{
  facilityId: any;
}>;

export type PanoramasQuery = {__typename?: 'Query'; panoramasForFacility: Array<Panorama>};

export type Panorama = {__typename?: 'Panorama'} & PanoramaFragment;

export type UpdatePanoramasMutationVariables = Types.Exact<{
  input: UpdatePanoramasInput;
}>;

export type UpdatePanoramasMutation = {
  __typename?: 'Mutation';
  panoramasUpdate: Array<{__typename?: 'Panorama'} & PanoramaFragment>;
};

export type PanoramaFragment = {
  __typename?: 'Panorama';
  id: any;
  location: Types.PanoramaLocation;
  image: {__typename?: 'Image'; id: any; objectName: string};
};

export const PanoramaFragmentDoc = gql`
  fragment Panorama on Panorama {
    id
    location
    image {
      id
      objectName
    }
  }
`;
export const PanoramasDocument = gql`
  query Panoramas($facilityId: FacilityId!) {
    panoramasForFacility(facilityId: $facilityId) @export(exportName: "Panorama") {
      ...Panorama
    }
  }
  ${PanoramaFragmentDoc}
`;

export function usePanoramasQuery(options: Omit<Urql.UseQueryArgs<PanoramasQueryVariables>, 'query'>) {
  return Urql.useQuery<PanoramasQuery>({query: PanoramasDocument, ...options});
}
export const UpdatePanoramasDocument = gql`
  mutation UpdatePanoramas($input: UpdatePanoramasInput!) {
    panoramasUpdate(input: $input) {
      ...Panorama
    }
  }
  ${PanoramaFragmentDoc}
`;

export function useUpdatePanoramasMutation() {
  return Urql.useMutation<UpdatePanoramasMutation, UpdatePanoramasMutationVariables>(UpdatePanoramasDocument);
}
