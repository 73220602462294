import * as Types from '../../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ThemeWorldsQueryVariables = Types.Exact<{
  facilityId: any;
}>;

export type ThemeWorldsQuery = {__typename?: 'Query'; themeWorlds: Array<ThemeWorldType>};

export type ThemeWorldType = {__typename: 'ThemeWorld'; id: any; name: string; color: string};

export const ThemeWorldsDocument = gql`
  query ThemeWorlds($facilityId: FacilityId!) {
    themeWorlds(facilityId: $facilityId) @export(exportName: "ThemeWorldType") {
      __typename
      id
      name
      color
    }
  }
`;

export function useThemeWorldsQuery(options: Omit<Urql.UseQueryArgs<ThemeWorldsQueryVariables>, 'query'>) {
  return Urql.useQuery<ThemeWorldsQuery>({query: ThemeWorldsDocument, ...options});
}
