import * as Types from '../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginOrRegisterInput = {
  email: string;
  password: string;
};

export type LoginMutationVariables = Types.Exact<{
  input: LoginOrRegisterInput;
}>;

export type LoginMutation = {__typename?: 'Mutation'; authLogin: boolean};

export const LoginDocument = gql`
  mutation Login($input: LoginOrRegisterInput!) {
    authLogin(input: $input)
  }
`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
