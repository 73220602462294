import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {createClient, dedupExchange, cacheExchange, Provider} from 'urql';
import {multipartFetchExchange} from '@urql/exchange-multipart-fetch';
import {devtoolsExchange} from '@urql/devtools';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {CaptureConsole, Offline, ReportingObserver} from '@sentry/integrations';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';

import './index.css';
import {BrowserTracing} from '@sentry/tracing';
import AuthRoutes from './routes/auth/routes';
import {UserConsumer, UserProvider} from './context/user/user';
import AppRoutes from './routes/app-routes';

import generatedGitInfo from './generatedGitInfo.json';
import {Deeplinks} from './routes/deeplinks/deeplinks';

const client = createClient({
  url: '/graphql',
  exchanges: [devtoolsExchange, dedupExchange, cacheExchange, multipartFetchExchange],
  fetchOptions: {credentials: 'include'},
});

// To make use of the v6 instrumentation, we need to create an element inside BrowserRouter
const AppWithSentry = () => {
  // using a 3rd party package until sentry supports react-router v6 natively; active issue:
  //  https://github.com/getsentry/sentry-javascript/issues/4118
  const routingInstrumentation = useRoutingInstrumentation();

  useEffect(() => {
    Sentry.init({
      dsn: 'https://23178db1d8b64de9a39543477c2be567@sentry.atmina.systems/8',
      integrations: [
        new BrowserTracing({
          routingInstrumentation,
          shouldCreateSpanForRequest: () => true,
          tracingOrigins: [/.*/],
        }),
        new CaptureConsole(),
        new Offline(),
        new ReportingObserver(),
      ],
      tracesSampleRate: 1.0,
      attachStacktrace: true,
      environment: generatedGitInfo.branch,
      release: generatedGitInfo.hash,
      debug: false,
    });
  }, [routingInstrumentation]);

  return (
    <Sentry.ErrorBoundary>
      <UserProvider>
        <Routes>
          <Route path='/auth/*' element={<AuthRoutes />} />
          <Route path={'/deeplinks/*'} element={<Deeplinks />} />
          <Route
            path='/*'
            element={
              <UserConsumer>
                {(context) => (context.loggedIn ? <AppRoutes /> : <Navigate to='/auth/login' />)}
              </UserConsumer>
            }
          />
        </Routes>
      </UserProvider>
    </Sentry.ErrorBoundary>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider value={client}>
      <BrowserRouter>
        <AppWithSentry />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
