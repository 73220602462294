import React, {createContext, FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useDebounce} from 'react-use';

interface LoadingContextProps {
  isLoading: boolean;

  requestedLoading: boolean;
  setRequestedLoading: (loading: boolean) => void;

  activeRequests: number[];
  addActiveRequest: (requestId: number) => void;
  removeActiveRequest: (requestId: number) => void;
}

export const LoadingContext = createContext<LoadingContextProps>({
  isLoading: false,

  requestedLoading: false,
  setRequestedLoading: () => {},

  activeRequests: [],
  addActiveRequest: () => {},
  removeActiveRequest: () => {},
});

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider: FC<LoadingProviderProps> = ({children}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [requestedLoading, setRequestedLoading] = useState<boolean>(false);
  const [activeRequests, setActiveRequests] = useState<number[]>([]);

  const [lastLoadingSetActive, setLastLoadingSetActive] = useState<number>(0);

  const addActiveRequest = useCallback((requestId: number) => {
    setActiveRequests((requests) => [...requests, requestId]);
  }, []);

  const removeActiveRequest = useCallback((requestId: number) => {
    setActiveRequests((requests) => requests.filter((id) => id !== requestId));
  }, []);

  const shouldBeLoading = requestedLoading || activeRequests.length > 0;
  const currentLoadingTime = Date.now() - lastLoadingSetActive;
  const offDelay = 500 - Math.min(500, currentLoadingTime);

  useDebounce(
    () => {
      setLoading(shouldBeLoading);

      if (shouldBeLoading) setLastLoadingSetActive(Date.now());
    },
    shouldBeLoading ? 100 : offDelay,
    [shouldBeLoading],
  );

  const providedValue = useMemo(
    () => ({
      isLoading,

      requestedLoading,
      setRequestedLoading,

      addActiveRequest,
      removeActiveRequest,
      activeRequests,
    }),
    [isLoading, requestedLoading, addActiveRequest, removeActiveRequest, activeRequests],
  );

  return <LoadingContext.Provider value={providedValue}>{children}</LoadingContext.Provider>;
};

type UseLoading = ((newLoading: boolean, changeState?: boolean) => void) | (() => boolean);
export const useLoading: UseLoading = (newLoading?: boolean, changeState = true) => {
  const context = useContext(LoadingContext);

  useEffect(() => {
    if (newLoading !== undefined && changeState) context.setRequestedLoading(newLoading);
  });

  if (newLoading === undefined) return context.isLoading;
  return undefined;
};
