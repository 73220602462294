import React, {CSSProperties, FC, Fragment} from 'react';
import {CloseRounded} from '@mui/icons-material';
import {Dialog, Transition} from '@headlessui/react';
import clsx from 'clsx';

export interface ModalProps {
  className?: string;
  title?: string;
  description?: string;
  isOpen: boolean;
  onDismiss: () => void;
  dismissible?: boolean;
  style?: CSSProperties;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
}

export const ModalDialog: FC<ModalProps> = (props) => {
  const {children, className, isOpen, onDismiss, dismissible = true, initialFocus, style} = props;

  const dialogClassNames = clsx(
    'relative bg-white rounded-lg shadow-lg w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl mx-auto overflow-y-auto transition-all',
    className,
  );
  return (
    <Transition show={isOpen} as={Fragment} appear>
      <Dialog onClose={onDismiss} as='div' className='fixed inset-0 z-10 overflow-y-auto' initialFocus={initialFocus}>
        <div className='z-10 flex items-center justify-center min-h-screen'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black-dark bg-opacity-50' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className={dialogClassNames} style={style}>
              <div className='bg-ochre-light'>{children}</div>
              {dismissible && (
                // Move dismiss button here so it comes after all other components in DOM order
                // (This is relevant for initial focus in modal focus trap, as well as tabbing behavior)
                <div>
                  <button
                    onClick={onDismiss}
                    className='absolute top-0 right-0 text-white p-5'
                    data-cy='dismiss-dialog'
                    data-testid='dismiss-dialog'
                  >
                    <CloseRounded />
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export interface ModalDialogHeaderProps {
  title: string;
  description: string;
}

export const ModalDialogHeader: FC<ModalDialogHeaderProps> = ({title, description}) => (
  <div className='bg-ochre overflow-hidden text-white px-6 py-4'>
    <div className='flex text-xl'>{title && <Dialog.Title className='text-semibold'>{title}</Dialog.Title>}</div>
    {description && <Dialog.Description className='text-ochre-light text-xs'>{description}</Dialog.Description>}
  </div>
);
