import React, {FC} from 'react';
import {Add} from '@mui/icons-material';

interface Props {
  callback: () => void;
}

const Divider: FC = () => <div className='h-0.5 w-full bg-black-light rounded-lg mx-6' />;

const AddButton: FC<Props> = ({callback, ...rest}) => (
  <div
    id='add-link'
    className='group w-full flex justify-center items-center mt-2 text-white hover:cursor-pointer'
    onClick={callback}
    role='button'
    tabIndex={0}
    onKeyPress={(event) => {
      if (event.key === 'Enter') {
        callback();
      }
    }}
    {...rest}
  >
    <Divider />
    <div className='flex justify-center align-middle bg-ochre rounded-full shadow-lg group-hover:bg-ochre-dark'>
      <Add className='m-2' />
    </div>
    <Divider />
  </div>
);

export default AddButton;
