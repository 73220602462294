import * as Types from '../../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StatusScheduleInput = {
  closingTime: any;
  friday: boolean;
  monday: boolean;
  openingTime: any;
  saturday: boolean;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  validFrom?: any | null;
  validUntil?: any | null;
  wednesday: boolean;
};

export type UpdateStatusInput = {
  id?: any | null;
  schedule?: StatusScheduleInput | null;
  statusMessageId: any;
};

export type UpdateStatusesInput = {
  poiId: any;
  statuses: Array<UpdateStatusInput>;
};

export type UpdateStatusesMutationVariables = Types.Exact<{
  input: UpdateStatusesInput;
}>;

export type UpdateStatusesMutation = {
  __typename?: 'Mutation';
  statusesUpdate: {__typename?: 'StatusesPayload'; statuses: Array<{__typename?: 'Status'; id: any}>};
};

export const UpdateStatusesDocument = gql`
  mutation UpdateStatuses($input: UpdateStatusesInput!) {
    statusesUpdate(input: $input) {
      statuses {
        id
      }
    }
  }
`;

export function useUpdateStatusesMutation() {
  return Urql.useMutation<UpdateStatusesMutation, UpdateStatusesMutationVariables>(UpdateStatusesDocument);
}
