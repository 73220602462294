import React, {FC, useState} from 'react';
import {ExpandLess, ExpandMore} from '@mui/icons-material';

import AnimateHeight from 'react-animate-height';
import PoiCard from './poi-card';
import {useUser} from '../../../context/user/user';
import {PoiType} from '../poi.generated';

interface Props {
  name: string;
  icon: string;
  pointsOfInterest: Array<PoiType>;
}

const Category: FC<Props> = ({name, icon, pointsOfInterest}) => {
  const [expand, setExpand] = useState(true);

  const user = useUser();
  if (!user) return <div>Login first.</div>;

  return (
    <div className='mx-2 mt-4' data-category={name}>
      <button
        onClick={() => {
          setExpand(!expand);
        }}
        className='flex items-center w-full h-12 cursor-pointer focus:outline-none'
      >
        <div className='flex-grow h-px ml-1 bg-ochre' />
        <div className='flex mx-4'>
          <img src={icon} className='w-6 mr-3' alt='Icon der Kategorie' />
          <p className='text-lg text-black'>
            {name} ({pointsOfInterest.length})
          </p>
        </div>
        <div className='flex-grow h-px bg-ochre mr-4' />
        {expand ? <ExpandLess /> : <ExpandMore />}
      </button>

      <AnimateHeight duration={500} height={expand ? 'auto' : '0%'}>
        <div className='flex flex-wrap'>
          {pointsOfInterest.map((p) => (
            <PoiCard key={p.id} poi={p} />
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default Category;
