import React, {FC, useCallback} from 'react';
import {StatusMessage} from '../../../../components/status-message';
import {StatusMessageType} from '../../../../common/queries/status-messages.generated';

export interface StatusMessageListProps {
  className?: string;
  poiType: string;
  languageId: string;
  items: StatusMessageType[];
  onEditMessage: (message: StatusMessageType) => void;
  onDeleteMessage: (message: StatusMessageType) => void;
}

const getTranslation = (translations: StatusMessageType['translations'], languageId: string) => {
  if (translations.length === 0) {
    throw new Error('Translations array is empty');
  }
  return translations.find((t) => t.languageId === languageId) || translations[0];
};

const StatusMessageList: FC<StatusMessageListProps> = (props) => {
  const {items, languageId, onEditMessage, onDeleteMessage, poiType, ...rest} = props;

  const handleDelete = useCallback(
    (index: number) => {
      onDeleteMessage(items[index]);
    },
    [onDeleteMessage, items],
  );

  const handleEdit = useCallback(
    (index: number) => {
      onEditMessage(items[index]);
    },
    [onEditMessage, items],
  );

  return (
    <ul {...rest} data-cy='status-message-list'>
      {items.map((msg, index) => {
        const {title, description, url} = getTranslation(msg.translations, languageId);
        return (
          <StatusMessage
            key={msg.id}
            poiType={poiType}
            index={index}
            title={title}
            url={url}
            description={description}
            onEdit={handleEdit}
            onDelete={handleDelete}
            className='even:bg-gray-50 px-4 py-3'
          />
        );
      })}
    </ul>
  );
};

export default StatusMessageList;
