import React, {FC, useContext, useState} from 'react';
import {CloseRounded} from '@mui/icons-material';
import {TextButton} from '../../components';

interface DialogContextProps {
  openDialog: ({title, message, actionCallback}: DialogConfig) => void;
}

const DialogContext = React.createContext<DialogContextProps>({
  openDialog: () => {},
});

interface DialogProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onDismiss: () => void;
}

const Dialog: FC<DialogProps> = ({title, message, onConfirm, onDismiss}) => (
  <div className='fixed w-full h-full'>
    <div className='w-full h-full bg-black opacity-50' />
    <div className='flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-11/12 lg:w-1/3 rounded-lg shadow-lg px-8 py-6'>
      <div className='flex justify-between'>
        <p className='font-bold text-2xl text-black'>{title}</p>
        <button onClick={onDismiss}>
          <CloseRounded className='text-black' />
        </button>
      </div>
      <div className='h-px bg-ochre my-4' />
      <p className='text-black'>{message}</p>
      <div className='flex self-end'>
        <TextButton onClick={onConfirm} type='button' className='mx-2'>
          Ja
        </TextButton>
        <TextButton onClick={onDismiss} type='button'>
          Nein
        </TextButton>
      </div>
    </div>
  </div>
);

interface DialogProviderProps {
  children: React.ReactNode;
}

interface DialogConfig {
  title: string;
  message: string;
  actionCallback: () => void;
}

export const DialogProvider: FC<DialogProviderProps> = ({children}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig | undefined>(undefined);

  const openDialog = ({title, message, actionCallback}: DialogConfig) => {
    setDialogOpen(true);
    setDialogConfig({title, message, actionCallback});
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(undefined);
  };

  const onConfirm = () => {
    dialogConfig?.actionCallback();
    resetDialog();
  };

  const onDismiss = () => {
    resetDialog();
  };

  return (
    <DialogContext.Provider value={{openDialog}}>
      {children}
      {dialogOpen && dialogConfig !== undefined && (
        <Dialog
          title={dialogConfig?.title}
          message={dialogConfig?.message}
          onConfirm={onConfirm}
          onDismiss={onDismiss}
        />
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = ({title, message, actionCallback}: DialogConfig): {getConfirmation: () => Promise<void>} => {
  const {openDialog} = useContext(DialogContext);
  if (openDialog === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  const getConfirmation = () =>
    new Promise<void>((res) => {
      openDialog({title, message, actionCallback});
      res();
    });

  return {getConfirmation};
};
