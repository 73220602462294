export type Weekday = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
export type WeekdaySelection = Record<Weekday, boolean>;

export const WEEKDAYS: {name: Weekday; label: string}[] = [
  {
    name: 'monday',
    label: 'Mo',
  },
  {
    name: 'tuesday',
    label: 'Di',
  },
  {
    name: 'wednesday',
    label: 'Mi',
  },
  {
    name: 'thursday',
    label: 'Do',
  },
  {
    name: 'friday',
    label: 'Fr',
  },
  {
    name: 'saturday',
    label: 'Sa',
  },
  {
    name: 'sunday',
    label: 'So',
  },
];

export const extractWeekdays = (obj: Partial<WeekdaySelection>): WeekdaySelection => ({
  monday: obj.monday ?? false,
  tuesday: obj.tuesday ?? false,
  wednesday: obj.wednesday ?? false,
  thursday: obj.thursday ?? false,
  friday: obj.friday ?? false,
  saturday: obj.saturday ?? false,
  sunday: obj.sunday ?? false,
});
