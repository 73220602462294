import React, {FC} from 'react';
import {useTitle} from 'react-use';
import {Link} from 'react-router-dom';
import Logo from '../../assets/logo.svg';

const Home: FC = () => {
  useTitle('Home');

  return (
    <div className='flex flex-col md:flex-row items-center'>
      <div className='text-black text-justify'>
        <h2 className='font-bold text-xl'>Willkommen im Adminbereich der Zoo-App!</h2>
        <p className='mt-4'>
          Unter dem Menüpunkt{' '}
          <Link to='/poi' className='text-red hover:underline font-medium'>
            POI
          </Link>{' '}
          können Sie Statusmeldungen für die Icons auf der Zoo-Karte in der App auswählen. Suchen Sie dazu den
          entsprechenden Point of Interest (POI) aus, klicken Sie auf das Stiftsymbol neben „Statusmeldungen“, klicken
          Sie dann auf das + und wählen Sie die passende Meldung aus. Optional können Sie auch Start- sowie End-Datum
          und -Uhrzeit eingeben.
        </p>
        <p className='mt-4'>
          Wenn Sie Fragen oder Anregungen zu weiteren Statusmeldungen haben, wenden Sie sich bitte an die
          Bereichsleiter.
        </p>
        <p className='mt-4'>
          Änderungen an den Menüpunkten in der App können die Administratoren vornehmen. Bitte wenden Sie sich dazu am
          besten per Mail an{' '}
          <a href='mailto:sebastian.baer@erlebnis-zoo.de' className='text-red hover:underline font-medium'>
            sebastian.baer@erlebnis-zoo.de
          </a>
          .
        </p>
      </div>
      <img className='w-52 m-16 md:pl-6' src={Logo} alt='Logo' />
    </div>
  );
};

export default Home;
