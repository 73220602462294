import * as Types from '../../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AttachDocumentInput = {
  file: any;
  pOIId: any;
};

export type RemoveDocumentInput = {
  fileId: any;
  pOIId: any;
};

export type PoiDocumentsQueryVariables = Types.Exact<{
  id: any;
}>;

export type PoiDocumentsQuery = {
  __typename?: 'Query';
  pOI:
    | ({__typename?: 'Animal'} & PoiWithDocuments_Animal_Fragment)
    | ({__typename?: 'ConstructionSite'} & PoiWithDocuments_ConstructionSite_Fragment)
    | ({__typename?: 'Exhibition'} & PoiWithDocuments_Exhibition_Fragment)
    | ({__typename?: 'FirstAid'} & PoiWithDocuments_FirstAid_Fragment)
    | ({__typename?: 'Gastronomy'} & PoiWithDocuments_Gastronomy_Fragment)
    | ({__typename?: 'GuidedTour'} & PoiWithDocuments_GuidedTour_Fragment)
    | ({__typename?: 'Highlight'} & PoiWithDocuments_Highlight_Fragment)
    | ({__typename?: 'Playground'} & PoiWithDocuments_Playground_Fragment)
    | ({__typename?: 'SanitaryFacility'} & PoiWithDocuments_SanitaryFacility_Fragment)
    | ({__typename?: 'Service'} & PoiWithDocuments_Service_Fragment)
    | ({__typename?: 'Shop'} & PoiWithDocuments_Shop_Fragment);
};

export type AttachDocumentMutationVariables = Types.Exact<{
  input: AttachDocumentInput;
}>;

export type AttachDocumentMutation = {
  __typename?: 'Mutation';
  documentAttach:
    | ({__typename?: 'Animal'} & PoiWithDocuments_Animal_Fragment)
    | ({__typename?: 'ConstructionSite'} & PoiWithDocuments_ConstructionSite_Fragment)
    | ({__typename?: 'Exhibition'} & PoiWithDocuments_Exhibition_Fragment)
    | ({__typename?: 'FirstAid'} & PoiWithDocuments_FirstAid_Fragment)
    | ({__typename?: 'Gastronomy'} & PoiWithDocuments_Gastronomy_Fragment)
    | ({__typename?: 'GuidedTour'} & PoiWithDocuments_GuidedTour_Fragment)
    | ({__typename?: 'Highlight'} & PoiWithDocuments_Highlight_Fragment)
    | ({__typename?: 'Playground'} & PoiWithDocuments_Playground_Fragment)
    | ({__typename?: 'SanitaryFacility'} & PoiWithDocuments_SanitaryFacility_Fragment)
    | ({__typename?: 'Service'} & PoiWithDocuments_Service_Fragment)
    | ({__typename?: 'Shop'} & PoiWithDocuments_Shop_Fragment);
};

export type RemoveDocumentMutationVariables = Types.Exact<{
  input: RemoveDocumentInput;
}>;

export type RemoveDocumentMutation = {
  __typename?: 'Mutation';
  documentRemove:
    | ({__typename?: 'Animal'} & PoiWithDocuments_Animal_Fragment)
    | ({__typename?: 'ConstructionSite'} & PoiWithDocuments_ConstructionSite_Fragment)
    | ({__typename?: 'Exhibition'} & PoiWithDocuments_Exhibition_Fragment)
    | ({__typename?: 'FirstAid'} & PoiWithDocuments_FirstAid_Fragment)
    | ({__typename?: 'Gastronomy'} & PoiWithDocuments_Gastronomy_Fragment)
    | ({__typename?: 'GuidedTour'} & PoiWithDocuments_GuidedTour_Fragment)
    | ({__typename?: 'Highlight'} & PoiWithDocuments_Highlight_Fragment)
    | ({__typename?: 'Playground'} & PoiWithDocuments_Playground_Fragment)
    | ({__typename?: 'SanitaryFacility'} & PoiWithDocuments_SanitaryFacility_Fragment)
    | ({__typename?: 'Service'} & PoiWithDocuments_Service_Fragment)
    | ({__typename?: 'Shop'} & PoiWithDocuments_Shop_Fragment);
};

export type PoiWithDocuments_Animal_Fragment = {
  __typename?: 'Animal';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_ConstructionSite_Fragment = {
  __typename?: 'ConstructionSite';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_Exhibition_Fragment = {
  __typename?: 'Exhibition';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_FirstAid_Fragment = {
  __typename?: 'FirstAid';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_Gastronomy_Fragment = {
  __typename?: 'Gastronomy';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_GuidedTour_Fragment = {
  __typename?: 'GuidedTour';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_Highlight_Fragment = {
  __typename?: 'Highlight';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_Playground_Fragment = {
  __typename?: 'Playground';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_SanitaryFacility_Fragment = {
  __typename?: 'SanitaryFacility';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_Service_Fragment = {
  __typename?: 'Service';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocuments_Shop_Fragment = {
  __typename?: 'Shop';
  id: any;
  documents: Array<{__typename?: 'Document'} & DocumentFragment>;
};

export type PoiWithDocumentsFragment =
  | PoiWithDocuments_Animal_Fragment
  | PoiWithDocuments_ConstructionSite_Fragment
  | PoiWithDocuments_Exhibition_Fragment
  | PoiWithDocuments_FirstAid_Fragment
  | PoiWithDocuments_Gastronomy_Fragment
  | PoiWithDocuments_GuidedTour_Fragment
  | PoiWithDocuments_Highlight_Fragment
  | PoiWithDocuments_Playground_Fragment
  | PoiWithDocuments_SanitaryFacility_Fragment
  | PoiWithDocuments_Service_Fragment
  | PoiWithDocuments_Shop_Fragment;

export type DocumentFragment = {
  __typename?: 'Document';
  id: any;
  objectName: string;
  originalFileName: string;
  pOIId: any;
};

export const DocumentFragmentDoc = gql`
  fragment Document on Document {
    id
    objectName
    originalFileName
    pOIId
  }
`;
export const PoiWithDocumentsFragmentDoc = gql`
  fragment PoiWithDocuments on POI {
    id
    documents {
      ...Document
    }
  }
  ${DocumentFragmentDoc}
`;
export const PoiDocumentsDocument = gql`
  query PoiDocuments($id: POIId!) {
    pOI(id: $id) {
      ...PoiWithDocuments
    }
  }
  ${PoiWithDocumentsFragmentDoc}
`;

export function usePoiDocumentsQuery(options: Omit<Urql.UseQueryArgs<PoiDocumentsQueryVariables>, 'query'>) {
  return Urql.useQuery<PoiDocumentsQuery>({query: PoiDocumentsDocument, ...options});
}
export const AttachDocumentDocument = gql`
  mutation AttachDocument($input: AttachDocumentInput!) {
    documentAttach(input: $input) {
      ...PoiWithDocuments
    }
  }
  ${PoiWithDocumentsFragmentDoc}
`;

export function useAttachDocumentMutation() {
  return Urql.useMutation<AttachDocumentMutation, AttachDocumentMutationVariables>(AttachDocumentDocument);
}
export const RemoveDocumentDocument = gql`
  mutation RemoveDocument($input: RemoveDocumentInput!) {
    documentRemove(input: $input) {
      ...PoiWithDocuments
    }
  }
  ${PoiWithDocumentsFragmentDoc}
`;

export function useRemoveDocumentMutation() {
  return Urql.useMutation<RemoveDocumentMutation, RemoveDocumentMutationVariables>(RemoveDocumentDocument);
}
