import {FC} from 'react';
import {PanoramaList} from './components/panoramas/panorama-list';
import {ContentStrings} from './components/content-strings/content-strings';

export const Content: FC = () => {
  return (
    <>
      <PanoramaList />
      <ContentStrings />
    </>
  );
};
