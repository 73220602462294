import React, {FC} from 'react';
import Header from './components/header';
import Footer from './components/footer';
import useRandomImage from '../../hooks/useRandomImage';
import {LoadingCard} from '../../components';

interface LoggedOutLayoutProps {
  children: React.ReactNode;
}

const LoggedOutLayout: FC<LoggedOutLayoutProps> = ({children}) => (
  <div className={`${useRandomImage()} h-screen bg-cover flex flex-col justify-between`}>
    <Header />
    <LoadingCard containerClass='w-full md:w-auto self-center p-8'>{children}</LoadingCard>
    <Footer />
  </div>
);

export default LoggedOutLayout;
