import React, {FC, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {LoadingCard} from '../../../components';

const Main: FC = ({children}) => {
  const [title, setTitle] = useState(document.title);
  const location = useLocation();

  useEffect(() => {
    setTitle(document.title);
  }, [title, location]);

  return (
    <div className='-mt-52 flex justify-center flex-grow'>
      <div className='max-w-7xl px-0 sm:px-4 lg:px-8 flex-grow mb-16'>
        <header className='pt-10 pb-6 px-5 sm:px-6'>
          <h1 className='text-3xl font-bold text-ochre-dark'>{title}</h1>
        </header>
        <LoadingCard containerClass='flex flex-col min-h-48 px-5 py-6 sm:px-6'>{children}</LoadingCard>
      </div>
    </div>
  );
};

export default Main;
