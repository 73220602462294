import React, {FC} from 'react';
import {createModalDialog, ModalDialogHeader, useModalDialogController} from '../modal-dialog';

export interface ConfirmDialogProps {
  title: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
}
const ConfirmDialogContent: FC<ConfirmDialogProps> = (props) => {
  const {confirmText = 'Bestätigen', cancelText = 'Abbrechen', ...headerProps} = props;
  const {resolve, dismiss} = useModalDialogController<boolean>();
  return (
    <>
      <ModalDialogHeader {...headerProps} />
      <div className='flex'>
        <button className='flex-1 py-2 bg-gray-400 bg-opacity-25 text-black' onClick={dismiss} data-cy='dialog-cancel'>
          {cancelText}
        </button>
        <button className='flex-1 py-2 bg-red text-white' onClick={() => resolve(true)} data-cy='dialog-confirm'>
          {confirmText}
        </button>
      </div>
    </>
  );
};

export const ConfirmDialog = createModalDialog(
  (props: ConfirmDialogProps) => <ConfirmDialogContent {...props} />,
  false,
);
