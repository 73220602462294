/*
 * This file solely exists to eliminate an import cycle (useUrqlRequest imports the context to logout, user.tsx imports
 *   useUrqlRequest to query the user).
 */
import {Context, createContext} from 'react';
import {User} from './user.generated';

export interface UserContextProps {
  readonly loggedIn: boolean;
  user: User | undefined;
  update: () => void;
}

export const UserContext: Context<UserContextProps> = createContext<UserContextProps>({
  loggedIn: false,
  user: undefined,
  update: () => {},
});
