import React, {FC} from 'react';
import {BusinessTime} from '../../poi.generated';
import WeekdayPicker from './weekday-picker';
import FormField from '../../../../components/forms/form-field';
import {extractWeekdays, WeekdaySelection} from './weekdays';
import {DateRangePicker} from '../../../../components';
import {DateRange, validateDateRange} from '../../../../common/date-range';
import {FormBuilderRegister} from '../../../../components/forms/formbuilder';

const validateBusinessDays = (weekdays: WeekdaySelection) =>
  Object.values(weekdays).some((day) => day) || 'Wählen Sie mindestens einen Tag aus.';

export interface BusinessTimeFormValues {
  id?: BusinessTime['id'];
  weekdays: WeekdaySelection;
  open: DateRange;
  valid: DateRange;
}

export const businessTimeToFormValues = (businessTime: BusinessTime): BusinessTimeFormValues => {
  return {
    ...businessTime,
    weekdays: extractWeekdays(businessTime),
    open: {
      from: businessTime.openingTime ? new Date(businessTime.openingTime) : null,
      to: businessTime.closingTime ? new Date(businessTime.closingTime) : null,
    },
    valid: {
      from: businessTime.validFrom ? new Date(businessTime.validFrom) : null,
      to: businessTime.validUntil ? new Date(businessTime.validUntil) : null,
    },
  };
};

export const formValuesToBusinessTime = (
  values: BusinessTimeFormValues,
  withId = true,
): Omit<BusinessTime, '__typename' | 'id'> & {id?: BusinessTime['id']} => {
  const res = {
    id: values.id,
    validFrom: values.valid.from,
    validUntil: values.valid.to,
    openingTime: values.open.from,
    closingTime: values.open.to,
    ...values.weekdays,
  };
  if (!withId) {
    delete res.id;
  }
  return res;
};

export const BusinessTimeSubform: FC<{
  entityNamePlural?: string;
  on: FormBuilderRegister<BusinessTimeFormValues>;
}> = ({on, entityNamePlural = 'Öffnungszeiten'}) => {
  const openController = on.open.useController();

  const weekdaysController = on.weekdays.useController({
    rules: {validate: validateBusinessDays},
  });

  const validController = on.valid.useController({
    rules: {validate: validateDateRange},
  });

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-col gap-2'>
        <p className='text-lg'>Bitte geben Sie die {entityNamePlural} an.</p>

        <FormField errors={openController.fieldState.error} name='openingTime'>
          <DateRangePicker
            {...on.open.useController().field}
            mode='time'
            fromRequired
            toRequired
            data-cy='opening-time-picker'
          />
        </FormField>
      </div>

      <FormField className='flex flex-col gap-2' errors={weekdaysController.fieldState.error} name='weekdays'>
        <p className='text-lg'>An welchen Tagen gelten die {entityNamePlural}?</p>
        <WeekdayPicker {...weekdaysController.field} />
      </FormField>

      <div className='flex flex-col gap-2'>
        <p className='text-lg'>
          <span className='text-opacity-50 text-black'>Optional: </span>
          Gelten diese {entityNamePlural} nur für einen speziellen Zeitraum?
        </p>
        <p className='text-sm'>Wenn nur ein Startdatum eingegeben wird, gilt der Zeitraum ab diesem Punkt bis immer</p>

        <FormField errors={validController.fieldState.error} name='valid'>
          <DateRangePicker {...validController.field} mode='date' data-cy='valid-date-picker' />
        </FormField>
      </div>
    </div>
  );
};
export default BusinessTimeSubform;
