import React, {FC, PropsWithChildren} from 'react';
import {NavLink} from 'react-router-dom';
import {SvgIconComponent} from '@mui/icons-material';
import clsx from 'clsx';

export interface SidebarProps {
  className?: string;
}

interface SidebarItemProps {
  title: string;
  icon: SvgIconComponent;
  routerId: string;
}

const SidebarItem: FC<SidebarItemProps> = ({title, icon: Icon, routerId}) => (
  <li title={title}>
    <NavLink
      to={routerId}
      className={({isActive}) =>
        `flex items-center h-10 border-l-4 ${isActive ? 'bg-ochre-light border-ochre-dark' : 'border-transparent'}`
      }
    >
      <div className='text-2xl text-ochre flex flex-col align-middle px-1 lg:px-2'>
        <Icon fontSize='inherit' />
      </div>
      <span className='inline-block hidden whitespace-nowrap lg:inline-block pl-2 pr-4'>{title}</span>
    </NavLink>
  </li>
);

export const Sidebar: FC<SidebarProps> & {Item: typeof SidebarItem} = Object.assign(
  (props: PropsWithChildren<SidebarProps>) => {
    const {className, ...rest} = props;
    return <ul className={clsx('w-10 lg:w-min', className)} data-cy='sidebar' {...rest} />;
  },
  {Item: SidebarItem},
);
