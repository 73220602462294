import {Cancel, ContentCopy} from '@mui/icons-material';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import {useLockBodyScroll} from 'react-use';
import {FeatureFlagNames} from '../../../__generated__/graphql';
import {ReactComponent as StatusIcon} from '../../../assets/icons/status.svg';
import {TextButton} from '../../../components';
import {FeatureGate} from '../../../components/feature-gate';
import {useOutcomeToast} from '../../../hooks/useOutcomeToast';
import {useUrqlRequest} from '../../../hooks/useUrqlRequest';
import {PoiType, useSetClosedMutation} from '../poi.generated';
import BusinessTimes from './business-times/business-times';
import {Documents} from './documents/documents';
import Information from './information/information';
import Statuses from './statuses/statuses';

interface Props {
  pointsOfInterest: Array<PoiType>;
}

const OpenPoiCard: FC<{poi: PoiType | undefined}> = ({poi}) => {
  const navigate = useNavigate();
  useLockBodyScroll();

  const [{fetching}, setClosed] = useUrqlRequest(useSetClosedMutation);
  const isAnimal = poi?.__typename === 'Animal';
  const linkCode = `${isAnimal ? 'animal' : 'poi'}:${poi?.id}`;

  const toast = useOutcomeToast({
    successText: 'Link-Code kopiert!',
    errorText: 'Beim Kopieren ist ein Fehler aufgetreten, Sie können diesen Code manuell kopieren: ' + linkCode,
  });

  const copyQuillLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(linkCode);
      toast(true);
    } catch {
      toast(false);
    }
  }, [toast, linkCode]);

  if (!poi) return <Navigate to='/poi' />;

  return (
    <motion.div
      className='fixed inset-0 py-24 px-4 md:p-16 overflow-y-scroll hide-scrollbar'
      initial={{backgroundColor: 'rgba(0, 0, 0, 0)'}}
      animate={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
      exit={{backgroundColor: 'rgba(0, 0, 0, 0)'}}
      onClick={() => navigate(-1)}
    >
      <motion.div
        className='md:max-w-screen-md lg:max-w-screen-lg flex flex-col mx-auto bg-white shadow-expandable rounded-lg overflow-hidden'
        layoutId={poi.id}
        onClick={(e) => e.stopPropagation()}
      >
        <motion.div className='relative flex items-center w-full h-56 sm:h-96 overflow-hidden' layoutId={`q${poi.id}`}>
          <Link
            data-cy='poi-close-button'
            className='absolute flex items-center top-0 right-0 rounded-full bg-ochre-dark m-4 focus:outline-none'
            to='/poi'
          >
            <Cancel className='text-ochre-light' />
          </Link>
          <motion.img
            className='h-full w-full object-cover'
            src={`/api/file/${poi.previewImage.fileId}`}
            alt='Bild des POIs'
            layoutId={`image-${poi.id}`}
          />
          {poi.allStatuses.length > 0 && <StatusIcon className='absolute w-12 h-12 top-4 left-4' />}
        </motion.div>
        <motion.div
          className='max-w-full whitespace-nowrap text-black p-4'
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          layoutId={`t${poi.id}`}
        >
          <div className='flex justify-between items-center'>
            <div className='flex'>
              <span className='text-lg overflow-ellipsis overflow-hidden'>
                {poi?.translation.name}{' '}
                {poi.closed && <span className='text-red text-sm font-medium'>(Geschlossen)</span>}
              </span>

              <FeatureGate hasOneOf={FeatureFlagNames.ManageNewsAndUpdates}>
                <button
                  className='ml-2 text-md'
                  onClick={copyQuillLink}
                  title='Kopieren des Link-Codes für bearbeitbare Texte'
                >
                  <ContentCopy fontSize='inherit' />
                </button>
              </FeatureGate>
            </div>

            {poi.__typename !== 'Animal' && poi.__typename !== 'ConstructionSite' && (
              <TextButton
                className={clsx('mt-0 w-auto', poi.closed ? 'bg-ochre' : 'bg-red')}
                onClick={() => setClosed({input: {pOIId: poi.id, setClosed: !poi.closed}})}
                disabled={fetching}
              >
                {poi.closed ? 'Schließung aufheben' : 'Einrichtung schließen'}
              </TextButton>
            )}
          </div>
          <p className='opacity-75'>{poi?.themeWorld.name}</p>
        </motion.div>
        <div className='flex flex-col gap-y-3 w-full text-black p-4'>
          <BusinessTimes poi={poi} />
          <Information poi={poi} />
          <Statuses poi={poi} />
          <Documents poi={poi} />
        </div>
      </motion.div>
    </motion.div>
  );
};

const AnimatedOpenPoiCard: FC<Props> = ({pointsOfInterest}) => {
  const {id} = useParams<{id: string}>();
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const poi = pointsOfInterest.find((p) => p.id === id);

  return <AnimatePresence>{id && mounted && <OpenPoiCard poi={poi} />}</AnimatePresence>;
};

export default AnimatedOpenPoiCard;
