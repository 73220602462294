import * as Types from '../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type IconsQueryVariables = Types.Exact<{[key: string]: never}>;

export type IconsQuery = {__typename?: 'Query'; icons: Array<{__typename?: 'Icon'; id: string}>};

export const IconsDocument = gql`
  query Icons {
    icons {
      id
    }
  }
`;

export function useIconsQuery(options?: Omit<Urql.UseQueryArgs<IconsQueryVariables>, 'query'>) {
  return Urql.useQuery<IconsQuery>({query: IconsDocument, ...options});
}
