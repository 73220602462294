import React, {ButtonHTMLAttributes, FC} from 'react';

export const TextButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  type = 'button',
  className,
  ...rest
}) => (
  <button
    type={type}
    className={`w-full md:w-auto self-end h-9 mt-6 px-6 bg-ochre box-border border-2 border-transparent text-white rounded-lg focus:outline-none focus:border-ochre-dark focus:shadow-lg disabled:opacity-50 disabled:bg-gray-400 disabled:cursor-default${
      className ? ` ${className}` : ''
    }`}
    {...rest}
  >
    {children}
  </button>
);
