import {FC} from 'react';

export interface WeekdayDisplayProps {
  label: string;
  enabled: boolean;
}

const WeekdayDisplay: FC<WeekdayDisplayProps> = ({label, enabled}) => (
  <div
    style={{
      backgroundColor: enabled ? '#af1f29' : '#b59067',
      opacity: enabled ? 1 : 0.25,
      color: enabled ? '#fff' : '#575757',
    }}
    className='flex justify-center w-9 py-0.5 pointer-events-none bg-ochre opacity-25 text-black transition-all'
  >
    {label}
  </div>
);
export default WeekdayDisplay;
