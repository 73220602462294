import React, {FC} from 'react';
import clsx from 'clsx';
import {formatDefaultDate, formatTime, parseDate, parseTime} from '../../util/timestamp-formatter';
import {DateRange} from '../../common/date-range';

export type DateRangePickerMode = 'date' | 'time' | 'datetime';

export interface DateRangePickerProps {
  value: DateRange;
  onChange: (value: DateRange) => void;
  mode: DateRangePickerMode;
  fromRequired?: boolean;
  toRequired?: boolean;
}

const ensureDateRange = (value: {from?: unknown; to?: unknown}): value is DateRange => {
  if ((value.from != null && !(value.from instanceof Date)) || (value.to != null && !(value.to instanceof Date))) {
    throw new Error('DateRange contained non-Date types which must be converted');
  }
  return true;
};

const assignDate = (referenceDate: Date | null, date: Date, mode: 'date' | 'time'): Date => {
  const result = referenceDate ? new Date(referenceDate) : new Date();
  if (mode === 'date') {
    result.setUTCFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  } else {
    result.setUTCHours(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
  }
  return result;
};

// Remove button uses an anchor tag instead of a button because it does not receive the click event that is redirected
// onto the first interactive element when a label containing this element is clicked.
const RemoveButton: FC<{onClick: () => void}> = (props) => {
  const {onClick, ...rest} = props;
  return (
    <a
      role='button'
      tabIndex={0}
      className='text-ochre'
      onClick={(event) => {
        // Prevent event from propagating further, which would in this case focus the input field and open the picker.
        event.preventDefault();
        onClick();
      }}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      {...rest}
    >
      (entfernen)
    </a>
  );
};

export const DateRangePicker = React.forwardRef<HTMLLabelElement, DateRangePickerProps>((props, ref) => {
  const {mode, value = {from: null, to: null}, fromRequired, toRequired, onChange: _onChange, ...rest} = props;
  ensureDateRange(value);

  const hasDateInput = mode === 'date' || mode === 'datetime';
  const hasTimeInput = mode === 'time' || mode === 'datetime';

  const onChange = (nextValue: DateRange) => {
    _onChange(nextValue);
  };

  const handleChange = (field: 'from' | 'to', raw: string, _mode: 'date' | 'time') => {
    const nextValue = {...value};
    const parsedValue = _mode === 'time' ? parseTime(raw, value[field]) : parseDate(raw);

    if (field === 'from') {
      nextValue.from = parsedValue && assignDate(nextValue.from || new Date(), parsedValue, _mode);
    } else {
      nextValue.to = parsedValue && assignDate(nextValue.to || new Date(), parsedValue, _mode);
    }
    onChange(nextValue);
  };

  return (
    <div className='flex flex-wrap gap-y-4' {...rest}>
      <label className='flex flex-col text-sm group-input' ref={ref}>
        <span>
          Von{' '}
          {value.from != null && !fromRequired ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <RemoveButton
              onClick={() => {
                onChange({...value, from: null});
              }}
            />
          ) : null}
        </span>
        {hasDateInput && (
          <input
            type='date'
            className='h-8 bg-ochre bg-opacity-25 border-none tabular-nums font-system'
            value={value.from != null ? formatDefaultDate(value.from) : ''}
            onChange={(evt) => handleChange('from', evt.target.value, 'date')}
            required={fromRequired}
            data-testid='date-range-from-date'
            data-cy='date-range-from-date'
          />
        )}
        {hasTimeInput && (
          <input
            type='time'
            className={clsx('h-8 bg-ochre bg-opacity-25 border-none tabular-nums font-system', hasDateInput && 'mt-1')}
            value={value.from != null ? formatTime(value.from) : ''}
            onChange={(evt) => handleChange('from', evt.target.value, 'time')}
            required={fromRequired}
            data-testid='date-range-from-time'
            data-cy='date-range-from-time'
          />
        )}
      </label>

      <p className={clsx('mx-4', mode === 'datetime' ? 'self-center mt-4' : 'self-end')}>-</p>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <label className='flex flex-col text-sm group-input'>
        <span>
          Bis{' '}
          {value.to != null && !toRequired ? (
            <RemoveButton
              onClick={() => {
                onChange({...value, to: null});
              }}
            />
          ) : null}
        </span>
        {hasDateInput && (
          <input
            type='date'
            className='h-8 bg-ochre bg-opacity-25 border-none tabular-nums font-system'
            value={value.to != null ? formatDefaultDate(value.to) : ''}
            onChange={(evt) => handleChange('to', evt.target.value, 'date')}
            required={toRequired}
            data-testid='date-range-to-date'
            data-cy='date-range-to-date'
          />
        )}
        {hasTimeInput && (
          <input
            type='time'
            className={clsx('h-8 bg-ochre bg-opacity-25 border-none tabular-nums font-system', hasDateInput && 'mt-1')}
            value={value.to != null ? formatTime(value.to) : ''}
            onChange={(evt) => handleChange('to', evt.target.value, 'time')}
            required={toRequired}
            data-testid='date-range-to-time'
            data-cy='date-range-to-time'
          />
        )}
      </label>
    </div>
  );
});
