export const formatTime = (timestamp?: Date): string => {
  if (timestamp == null) return '--:--';

  return new Date(timestamp).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};

export const parseTime = (timestamp: string | null | undefined, referenceDate?: Date | null): Date | null => {
  if (timestamp) {
    const match = timestamp.match(/(\d+):(\d+)/);
    if (!match) {
      throw new Error(`Invalid time format: ${timestamp}`);
    }
    const [, hours, minutes] = match;
    const date = new Date(referenceDate || new Date());
    date.setHours(Number(hours), Number(minutes));
    return date;
  }
  return null;
};

export const formatDate = (timestamp: Date): string => new Date(timestamp).toLocaleDateString('de-DE');

export const formatDefaultDate = (timestamp?: Date): string | undefined => {
  if (timestamp == null) return undefined;

  const date = new Date(timestamp);
  if (Number.isNaN(date.valueOf())) return undefined;

  return date.toISOString().split('T')[0];
};

export const parseDate = (timestamp: string | null | undefined): Date | null => {
  if (timestamp) {
    if (timestamp.match(/\d+-\d+-\d+/)) {
      return new Date(timestamp);
    }
    throw new Error(`Invalid date format: ${timestamp}`);
  }
  return null;
};
