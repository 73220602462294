import * as Types from '../../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateBusinessTimeInput = {
  closingTime: any;
  friday: boolean;
  monday: boolean;
  openingTime: any;
  pOIId: any;
  saturday: boolean;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  validFrom?: any | null;
  validUntil?: any | null;
  wednesday: boolean;
};

export type DeleteBusinessTimeInput = {
  id: number;
  pOIId: any;
};

export type UpdateBusinessTimeInput = {
  closingTime: any;
  friday: boolean;
  id: number;
  monday: boolean;
  openingTime: any;
  pOIId: any;
  saturday: boolean;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  validFrom?: any | null;
  validUntil?: any | null;
  wednesday: boolean;
};

export type CreateBusinessTimeMutationVariables = Types.Exact<{
  input: CreateBusinessTimeInput;
}>;

export type CreateBusinessTimeMutation = {
  __typename?: 'Mutation';
  businessTimeCreate: {
    __typename?: 'CreateBusinessTimePayload';
    success: boolean;
    message?: string | null;
    businessTime?: {__typename: 'BusinessTime'} | null;
  };
};

export type UpdateBusinessTimeMutationVariables = Types.Exact<{
  input: UpdateBusinessTimeInput;
}>;

export type UpdateBusinessTimeMutation = {
  __typename?: 'Mutation';
  businessTimeUpdate: {
    __typename?: 'UpdateBusinessTimePayload';
    success: boolean;
    message?: string | null;
    businessTime?: {__typename: 'BusinessTime'} | null;
  };
};

export type DeleteBusinessTimeMutationVariables = Types.Exact<{
  input: DeleteBusinessTimeInput;
}>;

export type DeleteBusinessTimeMutation = {
  __typename?: 'Mutation';
  businessTimeDelete: {
    __typename?: 'DeleteBusinessTimePayload';
    success: boolean;
    message?: string | null;
    businessTime?: {__typename: 'BusinessTime'} | null;
  };
};

export const CreateBusinessTimeDocument = gql`
  mutation CreateBusinessTime($input: CreateBusinessTimeInput!) {
    businessTimeCreate(input: $input) {
      success
      message
      businessTime {
        __typename
      }
    }
  }
`;

export function useCreateBusinessTimeMutation() {
  return Urql.useMutation<CreateBusinessTimeMutation, CreateBusinessTimeMutationVariables>(CreateBusinessTimeDocument);
}
export const UpdateBusinessTimeDocument = gql`
  mutation UpdateBusinessTime($input: UpdateBusinessTimeInput!) {
    businessTimeUpdate(input: $input) {
      success
      message
      businessTime {
        __typename
      }
    }
  }
`;

export function useUpdateBusinessTimeMutation() {
  return Urql.useMutation<UpdateBusinessTimeMutation, UpdateBusinessTimeMutationVariables>(UpdateBusinessTimeDocument);
}
export const DeleteBusinessTimeDocument = gql`
  mutation DeleteBusinessTime($input: DeleteBusinessTimeInput!) {
    businessTimeDelete(input: $input) {
      success
      message
      businessTime {
        __typename
      }
    }
  }
`;

export function useDeleteBusinessTimeMutation() {
  return Urql.useMutation<DeleteBusinessTimeMutation, DeleteBusinessTimeMutationVariables>(DeleteBusinessTimeDocument);
}
