import {FC, useEffect} from 'react';
import {PanoramaLocation} from '../../../../../__generated__/graphql';
import {Panorama} from './panoramas.generated';
import {useFilePicker} from 'use-file-picker';
import {useLoading} from '../../../../../context/loading/loading';

const locationDescriptions: Record<PanoramaLocation, {name: string; description: string}> = {
  [PanoramaLocation.Home]: {name: 'Startseite', description: 'Dieses Bild wird oben auf der Startseite angezeigt.'},
  [PanoramaLocation.FeedingList]: {
    name: 'Fütterungen',
    description: 'Dieses Bild wird oben auf der Liste der Fütterungen angezeigt',
  },
  [PanoramaLocation.TicketInput]: {
    name: 'Manuelle Ticketeingabe',
    description: 'Dieses Bild wird oben auf der Seite zur manuellen Eingabe der Jahreskarten angezeigt.',
  },
};

const EmptyImage: FC = () => {
  return (
    <div className='border border-dashed border-black w-full h-full flex items-center justify-center'>
      <span>Noch kein Bild hinterlegt</span>
    </div>
  );
};

interface PanoramaInputProps {
  location: PanoramaLocation;
  panorama: Panorama | undefined;
  onChange: (file: File) => void;
}

export const PanoramaInput: FC<PanoramaInputProps> = ({panorama, location, onChange}) => {
  const texts = locationDescriptions[location];
  const [open, {loading, filesContent, plainFiles}] = useFilePicker({
    accept: 'image/*',
    readAs: 'DataURL',
    maxFileSize: 2,
  });

  useLoading(loading);

  useEffect(() => {
    if (plainFiles.length === 1) {
      onChange(plainFiles[0]);
    }
  }, [onChange, plainFiles]);

  const src = filesContent?.[0]?.content ?? (panorama ? `/api/file/${panorama?.image.objectName}` : undefined);

  return (
    <div className='flex flex-col md:justify-between md:flex-row'>
      <div className='flex flex-col m-4'>
        <p className='font-bold text-lg'>{texts.name}</p>
        <p className='text-md'>{texts.description}</p>
      </div>

      <button
        type='button'
        className='md:ml-8 md:w-72 md:h-36 flex justify-center items-center overflow-clip hover:opacity-50'
        onClick={open}
      >
        {src ? <img className='object-cover min-h-full' src={src} alt={texts.description} /> : <EmptyImage />}
      </button>
    </div>
  );
};
