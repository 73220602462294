import {Delete, OpenInNew} from '@mui/icons-material';
import {FC, useCallback} from 'react';
import FormButton from '../../../../components/forms/form-button';
import {useDialog} from '../../../../context/dialog/dialog';
import {useOutcomeToast} from '../../../../hooks/useOutcomeToast';
import {useRemoveDocumentMutation} from './documents.generated';

interface DocumentLineProps {
  poiId: string;
  documentId: string;
  link: string;
  originalFileName: string;
}

export const DocumentLine: FC<DocumentLineProps> = ({poiId, documentId, link, originalFileName}) => {
  const [, mutateRemoveDocument] = useRemoveDocumentMutation();
  const toast = useOutcomeToast({successText: 'Löschen erfolgreich!', errorText: 'Löschen fehlgeschlagen.'});

  const removeDocument = useCallback(async () => {
    const response = await mutateRemoveDocument({input: {pOIId: poiId, fileId: documentId}});
    const success = !!response.data?.documentRemove;
    toast(success);
  }, [mutateRemoveDocument, toast, poiId, documentId]);

  const {getConfirmation: confirmAndDelete} = useDialog({
    title: 'Dokument löschen',
    message: 'Sind Sie sicher, dass Sie dieses Dokument löschen wollen?',
    actionCallback: removeDocument,
  });

  return (
    <div className='flex justify-between py-3 border-b border-ochre border-opacity-25 last:border-none'>
      <span className='truncate'>{originalFileName}</span>
      <div className='flex items-end gap-2'>
        <FormButton type='button' onClick={confirmAndDelete}>
          <Delete className='text-red p-0.5' />
        </FormButton>
        <a href={link} target='_blank'>
          <OpenInNew className='p-0.5' />
        </a>
      </div>
    </div>
  );
};
