import React, {FC, RefCallback, useState} from 'react';
import clsx from 'clsx';
import {FieldPath, RegisterOptions, useForm, UseFormRegister} from 'react-hook-form';
import {
  createModalDialog,
  LanguageToggle,
  ModalDialogHeader,
  useModalDialog,
  useModalDialogController,
} from '../../../../components';
import {LanguageType} from '../../../../common/queries/languages.generated';
import {StatusMessageType} from '../../../../common/queries/status-messages.generated';
import FormField from '../../../../components/forms/form-field';
import {ConfirmDialog} from '../../../../components/dialogs/confirm-dialog';

export interface StatusMessageFormData {
  poiType: string;
  translations: StatusMessageType['translations'];
}

export interface StatusMessageFormDialogProps {
  languages: LanguageType[];
  statusMessage: StatusMessageType;
  isNew?: boolean;
}

const statusMessageToFormData = (
  statusMessage: StatusMessageFormData,
  languages: LanguageType[],
): StatusMessageFormData => ({
  poiType: statusMessage.poiType,
  translations: languages.map(({id}) => {
    const translation = statusMessage.translations.find((t) => t.languageId === id);
    return {
      languageId: id,
      title: translation?.title ?? '',
      description: translation?.description ?? '',
      url: translation?.url ?? '',
    };
  }),
});

// This wraps react-hook-form's `register`, intercepting calls to the element's `focus` method.
// We need to know when an element is focused so that we can toggle between language pages if needed.
// An element can gain focus by:
// - Tab navigation
// - Submitting the form (elements containing invalid values are focused by react-hook-form)
const registerWithFocusedCallback = <T,>(
  register: UseFormRegister<T>,
  name: FieldPath<T>,
  options?: RegisterOptions & {onFocused?: (name: string) => void},
) => {
  const {ref, ...rest} = register(name, options);
  const wrappedRefCallback: RefCallback<HTMLElement> = (el) => {
    if (el) {
      const focusHandler = options?.onFocused;
      if (focusHandler) {
        el.addEventListener('focus', () => focusHandler(name));
      }
    }
    ref(el);
  };
  return {ref: wrappedRefCallback, ...rest};
};

const StatusMessageFormDialogContent: FC<StatusMessageFormDialogProps> = (props) => {
  const {languages, statusMessage, isNew} = props;
  const [languageId, setLanguageId] = useState(languages[0].id);
  const {register, handleSubmit, formState} = useForm({
    defaultValues: statusMessageToFormData(statusMessage, languages),
  });
  const {isDirty} = formState;
  const showDialog = useModalDialog();
  const {resolve, dismiss} = useModalDialogController<StatusMessageFormData>({
    onDismiss: () => {
      if (isDirty) {
        return showDialog(ConfirmDialog, {
          title: 'Änderungen verwerfen?',
          description: 'Nicht gespeicherte Änderungen gehen verloren.',
          cancelText: 'Behalten',
          confirmText: 'Verwerfen',
        });
      }
      return true;
    },
  });

  return (
    <>
      <ModalDialogHeader
        title={`Statusmeldung ${isNew ? 'erstellen' : 'bearbeiten'}`}
        description='Passen Sie die Statusmeldung an.'
      />
      <form onSubmit={handleSubmit(resolve)} data-cy='status-message-form'>
        <div className='flex flex-row-reverse'>
          <div className='flex-1 relative'>
            {languages.map((lang, i) => (
              <div
                key={lang.id}
                className={clsx('pr-6 pl-4 py-3 w-full', {'sr-only': lang.id !== languageId})}
                aria-hidden={lang.id !== languageId}
              >
                <FormField name={`translations.${i}.title`} errors={formState.errors}>
                  <label className='flex flex-col'>
                    <span className='text-sm'>
                      Titel
                      <span className='text-opacity-50 text-black'> ({lang.id.toUpperCase()})</span>
                    </span>
                    <input
                      type='text'
                      className='bg-ochre bg-opacity-25 border-ochre text-black'
                      data-cy='input-status-message-title'
                      {...registerWithFocusedCallback(register, `translations.${i}.title` as const, {
                        validate: (s: string) => i > 0 || !!s.trim() || 'Bitte geben Sie einen Titel an.',
                        onFocused: () => setLanguageId(lang.id),
                      })}
                    />
                  </label>
                </FormField>
                <FormField name={`translations.${i}.description`} errors={formState.errors}>
                  <label className='flex flex-col mt-5'>
                    <span className='text-sm'>
                      Beschreibung
                      <span className='text-opacity-50 text-black'> ({lang.id.toUpperCase()})</span>
                    </span>
                    <textarea
                      className='bg-ochre bg-opacity-25 border-ochre text-black'
                      data-cy='input-status-message-description'
                      {...registerWithFocusedCallback(register, `translations.${i}.description` as const, {
                        validate: (s: string) => i > 0 || !!s.trim() || 'Bitte geben Sie eine Beschreibung an.',
                        onFocused: () => setLanguageId(lang.id),
                      })}
                    />
                  </label>
                </FormField>
                <label className='flex flex-col mt-5'>
                  <span className='text-sm'>
                    Link <span className='text-opacity-50 text-black'> ({lang.id.toUpperCase()}, optional)</span>
                  </span>
                  <input
                    type='text'
                    className='bg-ochre bg-opacity-25 border-ochre text-black'
                    data-cy='input-status-message-link'
                    {...registerWithFocusedCallback(register, `translations.${i}.url` as const, {
                      onFocused: () => setLanguageId(lang.id),
                    })}
                  />
                </label>
              </div>
            ))}
          </div>
          <LanguageToggle
            languages={languages}
            value={languageId}
            onChange={setLanguageId}
            vertical
            showFullNames={false}
            className='mt-6'
            data-cy='dialog-language-toggle'
          />
        </div>
        <div className='flex mt-2'>
          <button
            type='button'
            className='flex-1 py-2 bg-gray-400 bg-opacity-25 text-black'
            onClick={dismiss}
            data-cy='dialog-cancel'
          >
            Abbrechen
          </button>
          <button type='submit' className='flex-1 py-2 bg-red text-white' data-cy='dialog-save'>
            {isNew ? 'Hinzufügen' : 'Speichern'}
          </button>
        </div>
      </form>
    </>
  );
};

export const StatusMessageFormDialog = createModalDialog(
  (props: StatusMessageFormDialogProps) => <StatusMessageFormDialogContent {...props} />,
  null as null | StatusMessageFormData,
);

export default StatusMessageFormDialog;
