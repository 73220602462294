import * as Types from '../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type CurrentUserQueryVariables = Types.Exact<{[key: string]: never}>;

export type CurrentUserQuery = {__typename?: 'Query'; my: User};

export type User = {
  __typename?: 'User';
  id: any;
  email: string;
  facilityId: any;
  featureFlags: Array<
    | {__typename?: 'FeatureFlagForPOIType'; pOIType: string; name: Types.FeatureFlagNames}
    | {__typename?: 'GeneralFeatureFlag'; name: Types.FeatureFlagNames}
  >;
};

export const CurrentUserDocument = gql`
  query CurrentUser {
    my @export(exportName: "User") {
      id
      email
      facilityId
      featureFlags {
        name
        ... on FeatureFlagForPOIType {
          pOIType
        }
      }
    }
  }
`;

export function useCurrentUserQuery(options?: Omit<Urql.UseQueryArgs<CurrentUserQueryVariables>, 'query'>) {
  return Urql.useQuery<CurrentUserQuery>({query: CurrentUserDocument, ...options});
}
