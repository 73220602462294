import type {DeltaOperation} from 'quill';

export default function parseQuillDelta(input: string): DeltaOperation[] {
  try {
    const val = JSON.parse(input);
    if (val.ops && Array.isArray(val.ops)) {
      return val.ops;
    }
    return val;
  } catch {
    // Fallback: treat input string as plain text.
    return [{insert: String(input)}];
  }
}
