import React, {FC, useState} from 'react';
import {Add} from '@mui/icons-material';
import BusinessTimeDisplay from './business-time-display';
import FormButton from '../../../../components/forms/form-button';
import {BusinessTime, PoiType} from '../../poi.generated';
import {FeatureGate} from '../../../../components/feature-gate';
import {FeatureFlagNames} from '../../../../__generated__/graphql';

const BusinessTimes: FC<{poi: PoiType}> = ({poi}) => {
  const [creating, setCreating] = useState<Partial<BusinessTime> | null>(null);

  if (poi.__typename === 'Animal') return <div />;

  const businessTimes = poi.businessTimes.sort((a, b) => {
    if (a.openingTime === undefined || b.openingTime === undefined) return 1;
    return new Date(a.openingTime).getTime() < new Date(b.openingTime).getTime() ? -1 : 1;
  });

  return (
    <div data-cy='business-times' className='group w-full bg-ochre-light px-6 py-4 rounded-lg'>
      <div className='flex items-center justify-between'>
        <p className='text-md font-bold'>Öffnungszeiten</p>
        <FeatureGate hasOneOf={{name: FeatureFlagNames.ManagePoIs, poiType: poi.__typename}}>
          <FormButton
            type='button'
            onClick={() => {
              setCreating(() => ({}));
            }}
          >
            <Add />
          </FormButton>
        </FeatureGate>
      </div>
      {businessTimes.map((businessTime) => (
        <BusinessTimeDisplay
          key={`${poi.id} - ${businessTime.id}`}
          poi={poi}
          businessTime={businessTime}
          onDuplicate={setCreating}
        />
      ))}
      {creating && <BusinessTimeDisplay businessTime={creating} poi={poi} creating onClose={() => setCreating(null)} />}
    </div>
  );
};
export default BusinessTimes;
