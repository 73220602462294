import React, {FC} from 'react';

type FormButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const FormButton: FC<FormButtonProps> = ({className, ...rest}) => (
  <button
    data-cy='form-button'
    className={`${className} flex items-center justify-center w-6 h-6 text-red rounded-full hover:bg-red hover:bg-opacity-10 focus:outline-none ml-1`}
    {...rest}
  />
);
export default FormButton;
