import React, {FC, useEffect, useState} from 'react';
import {Edit, FileCopy} from '@mui/icons-material';
import FormButton from '../../../../components/forms/form-button';
import {formatTime} from '../../../../util/timestamp-formatter';
import {BusinessTime, PoiType} from '../../poi.generated';
import WeekdayPicker from './weekday-picker';
import {DateRangeView} from '../../../../components';
import {FeatureFlagNames} from '../../../../__generated__/graphql';
import {FeatureGate} from '../../../../components/feature-gate';
import {PoiBusinessTimeForm} from './poi-business-time-form';

const BusinessTimeDisplay: FC<{
  poi: PoiType;
  businessTime?: Partial<BusinessTime>;
  creating?: boolean;
  onClose?: () => void;
  onDuplicate?: (bt: Partial<BusinessTime>) => void;
}> = ({poi, businessTime, creating = false, onClose, onDuplicate}) => {
  const [editing, setEditing] = useState(creating);

  useEffect(() => {
    if (creating && !editing && onClose !== undefined) {
      onClose();
    }
  }, [creating, editing, onClose]);

  return (
    <div className='border-b border-ochre border-opacity-25 last:border-none py-4'>
      {editing || !businessTime ? (
        <PoiBusinessTimeForm poi={poi} businessTime={businessTime} close={() => setEditing(false)} />
      ) : (
        <div data-cy='business-time-display' className='flex flex-col'>
          <div className='flex justify-between mb-2'>
            <p>
              {formatTime(businessTime.openingTime)} - {formatTime(businessTime.closingTime)} Uhr
            </p>
            <FeatureGate hasOneOf={{name: FeatureFlagNames.ManagePoIs, poiType: poi.__typename}}>
              <div className='flex'>
                {!editing && onDuplicate && (
                  <FormButton
                    title='Duplizieren'
                    type='button'
                    onClick={() => {
                      const {id: _, ...rest} = businessTime;
                      onDuplicate(rest);
                    }}
                    data-cy='duplicate-button'
                  >
                    <FileCopy className='p-1' />
                  </FormButton>
                )}
                <FormButton type='button' onClick={() => setEditing(true)} data-cy='edit-button'>
                  <Edit className='p-1' />
                </FormButton>
              </div>
            </FeatureGate>
          </div>
          <div className='flex flex-wrap justify-between gap-2'>
            <WeekdayPicker value={businessTime} readonly />
            <DateRangeView from={businessTime.validFrom} to={businessTime.validUntil} className='text-sm' />
          </div>
        </div>
      )}
    </div>
  );
};
export default BusinessTimeDisplay;
