import React, {FC} from 'react';
import TopBrush from '../../../assets/brush-top';

const Footer: FC = () => (
  <div className='flex flex-col'>
    <TopBrush />
    <div className='w-full h-16 bg-white' />
  </div>
);

export default Footer;
