import React, {FC} from 'react';
import {User} from './user';
import {UserType} from '../users.generated';

export interface UserListProps {
  className?: string;
  items: UserType[];
  onEditUser: (user: UserType) => void;
  onDeleteUser: (user: UserType) => void;
}

const UserList: FC<UserListProps> = (props) => {
  const {items, onEditUser, onDeleteUser, ...rest} = props;

  return (
    <ul {...rest} data-cy='user-list'>
      {items.map((user) => (
        <User
          key={user.id}
          user={user}
          onEdit={() => onEditUser(user)}
          onDelete={() => onDeleteUser(user)}
          className='even:bg-gray-50 px-4 py-3'
        />
      ))}
    </ul>
  );
};

export default UserList;
