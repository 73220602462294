import {FC} from 'react';
import {useGetSystemInfoQuery} from './system-info.generated';

export const SystemInfoPage: FC = () => {
  const [{data}] = useGetSystemInfoQuery({requestPolicy: 'network-only'});
  return data?.systemInfo ? (
    <div className='p-2 text-black'>
      <dl>
        <dt className='font-bold'>System-Zeit</dt>
        <dd>
          <code>{data.systemInfo.localTime}</code>
        </dd>
        <dt className='font-bold'>Zeit (Berlin)</dt>
        <dd>
          <code>{data.systemInfo.berlinTime}</code>
        </dd>
        <dt className='font-bold'>CultureInfo</dt>
        <dd>
          <details>
            <summary>
              <code>{data.systemInfo.cultures.length}</code>
            </summary>
            <code>{data.systemInfo.cultures.join(', ')}</code>
          </details>
        </dd>
      </dl>
    </div>
  ) : null;
};
