import React, {FC} from 'react';
import {PoiType} from '../../poi.generated';
import {QuillView} from '../../../../components/quill-view';
import parseQuillDelta from '../../../../util/parse-quill-delta';

const InformationDisplay: FC<{poi: PoiType}> = ({poi}) => (
  <div data-cy='information-list'>
    {poi.information
      .sort((a, b) => (a.order < b.order ? -1 : 1))
      .map((information) => (
        <div
          key={information.id}
          data-cy='information-display'
          className='border-b border-ochre border-opacity-25 last:border-none py-4'
        >
          <p className='uppercase font-bold text-sm leading-relaxed' data-cy='information-title'>
            {information.title}
          </p>
          <QuillView
            className='mt-2'
            value={parseQuillDelta(information.description)}
            data-cy='information-description'
          />
        </div>
      ))}
  </div>
);
export default InformationDisplay;
