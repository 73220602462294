import {FC} from 'react';
import {ContentStringLocation} from '../../../../../__generated__/graphql';
import {QuillInput} from '../../../../../components/forms/quill-input';
import {DeltaOperation, DeltaStatic} from 'quill';

const locationDescriptions: Record<ContentStringLocation, {name: string; description: string}> = {
  [ContentStringLocation.Home]: {
    name: 'Startseite',
    description:
      'Dieser Text wird als Begrüßung auf der Startseite direkt unter dem Panorama angezeigt. Ein Link mit Ziel ":wifi-settings" führt den Nutzer zu den WLAN-Einstellungen des Gerätes. Spezielle Links zu POIs in der App können aus den POIs kopiert werden.',
  },
};

interface ContentStringInputProps {
  location: ContentStringLocation;
  value: DeltaStatic | DeltaOperation[];
  onChange: (value: DeltaStatic) => void;
}

export const ContentStringInput: FC<ContentStringInputProps> = ({location, value, onChange}) => {
  const texts = locationDescriptions[location];

  return (
    <div className='flex flex-col md:flex-row justify-between'>
      <div className='flex-[2] flex flex-col m-4'>
        <p className='font-bold text-lg'>{texts.name}</p>
        <p className='text-md'>{texts.description}</p>
      </div>

      <QuillInput className='flex-[3] ml-8 content-string-input' value={value} onChange={onChange} />
    </div>
  );
};
