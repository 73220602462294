import * as Types from '../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type LogoutMutationVariables = Types.Exact<{[key: string]: never}>;

export type LogoutMutation = {__typename?: 'Mutation'; authLogout: boolean};

export const LogoutDocument = gql`
  mutation Logout {
    authLogout
  }
`;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
}
