import * as Types from '../../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInformationInput = {
  description: string;
  order: number;
  title: string;
};

export type UpdateInformationListInput = {
  information: Array<UpdateInformationInput>;
  pOIId: any;
};

export type UpdateInformationMutationVariables = Types.Exact<{
  input: UpdateInformationListInput;
}>;

export type UpdateInformationMutation = {
  __typename?: 'Mutation';
  informationUpdate: {
    __typename?: 'UpdateInformationListPayload';
    success: boolean;
    message?: string | null;
    informationList: Array<{__typename: 'Information'; id: number; title: string; description: string}>;
  };
};

export const UpdateInformationDocument = gql`
  mutation UpdateInformation($input: UpdateInformationListInput!) {
    informationUpdate(input: $input) {
      success
      message
      informationList {
        __typename
        id
        title
        description
      }
    }
  }
`;

export function useUpdateInformationMutation() {
  return Urql.useMutation<UpdateInformationMutation, UpdateInformationMutationVariables>(UpdateInformationDocument);
}
