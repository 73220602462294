import React, {FC} from 'react';
import {Delete, Edit, LinkRounded} from '@mui/icons-material';
import clsx from 'clsx';
import {DateRangeView, Menu} from './index';
import {FeatureGate} from './feature-gate';
import {FeatureFlagNames} from '../__generated__/graphql';
import {BusinessTime} from '../routes/poi/poi.generated';
import WeekdayPicker from '../routes/poi/components/business-times/weekday-picker';
import {formatTime} from '../util/timestamp-formatter';

export interface StatusMessageProps {
  className?: string;
  poiType?: string;
  index: number;
  title: string;
  description: string;
  url?: string | null;
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
  schedule?: BusinessTime | null;
}

export const StatusMessage: FC<StatusMessageProps> = (props) => {
  const {className, index, title, description, url, onEdit, onDelete, poiType, schedule, ...rest} = props;
  return (
    <div className={clsx('text-black flex items-center', className)} data-cy='status-message' {...rest}>
      <div className='flex flex-wrap justify-between items-center gap-2 flex-1'>
        <div className='w-full'>
          <div className='max-w-lg'>
            <h3 className='text-sm font-bold' data-cy='status-message-title'>
              {title}
            </h3>
            <div className='text-sm whitespace-pre-line' data-cy='status-message-description'>
              {description}
            </div>
            {url && (
              <div className='text-sm text-opacity-50 text-black mt-1'>
                <a href={url} rel='noreferrer' className='inline-flex items-center' data-cy='status-message-link'>
                  <LinkRounded className='mr-1' />
                  {url}
                </a>
              </div>
            )}
          </div>
          {schedule && (
            <div className='w-full'>
              {schedule && (
                <div className='flex flex-col gap-2 border-b border-ochre border-opacity-25 last:border-none py-4'>
                  <p data-cy='status-schedule-time'>
                    {formatTime(schedule.openingTime)} - {formatTime(schedule.closingTime)} Uhr
                  </p>
                  <WeekdayPicker value={schedule} readonly />
                  <DateRangeView from={schedule.validFrom} to={schedule.validUntil} className='text-sm' />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <FeatureGate hasOneOf={{name: FeatureFlagNames.ManageStatus, poiType: poiType ?? ''}}>
        {onEdit || onDelete ? (
          <div className='ml-auto'>
            <Menu menuClassName='w-40'>
              {onEdit && (
                <Menu.Item icon={Edit} onClick={() => onEdit(index)} data-cy='edit-status-message'>
                  Bearbeiten
                </Menu.Item>
              )}
              {onDelete && (
                <Menu.Item icon={Delete} onClick={() => onDelete(index)} data-cy='delete-status-message'>
                  Löschen
                </Menu.Item>
              )}
            </Menu>
          </div>
        ) : null}
      </FeatureGate>
    </div>
  );
};
