import * as Types from '../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SetCloseOverrideInput = {
  pOIId: any;
  setClosed: boolean;
};

export type PointsOfInterestQueryVariables = Types.Exact<{
  facilityId: any;
  languageId?: string;
}>;

export type PointsOfInterestQuery = {__typename?: 'Query'; pOIsForFacility: Array<PoiType>};

export type PoiType =
  | PoiType_Animal
  | PoiType_ConstructionSite
  | PoiType_Exhibition
  | PoiType_FirstAid
  | PoiType_Gastronomy
  | PoiType_GuidedTour
  | PoiType_Highlight
  | PoiType_Playground
  | PoiType_SanitaryFacility
  | PoiType_Service
  | PoiType_Shop;

export type PoiType_Animal = {
  __typename: 'Animal';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_ConstructionSite = {
  __typename: 'ConstructionSite';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_Exhibition = {
  __typename: 'Exhibition';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_FirstAid = {
  __typename: 'FirstAid';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_Gastronomy = {
  __typename: 'Gastronomy';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_GuidedTour = {
  __typename: 'GuidedTour';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_Highlight = {
  __typename: 'Highlight';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_Playground = {
  __typename: 'Playground';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_SanitaryFacility = {
  __typename: 'SanitaryFacility';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_Service = {
  __typename: 'Service';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type PoiType_Shop = {
  __typename: 'Shop';
  id: any;
  closed: boolean;
  translation: {__typename?: 'POITranslation'; name: string};
  previewImage: {__typename?: 'Image'; fileId: string};
  information: Array<InformationType>;
  businessTimes: Array<BusinessTime>;
  themeWorld: ThemeWorld;
  allStatuses: Array<StatusType>;
};

export type InformationType = {
  __typename: 'Information';
  id: number;
  order: number;
  title: string;
  description: string;
};

export type BusinessTime = {__typename?: 'BusinessTime'} & BusinessTimeFragment;

export type ThemeWorld = {__typename: 'ThemeWorld'; id: any; name: string};

export type StatusType = {
  __typename: 'Status';
  id: any;
  startDate?: any | null;
  endDate?: any | null;
  order: number;
  message: {
    __typename: 'StatusMessage';
    id: any;
    translation: {__typename?: 'StatusMessageTranslation'; title: string; description: string; url?: string | null};
  };
  schedule?: ({__typename?: 'BusinessTime'} & BusinessTimeFragment) | null;
};

export type SetClosedMutationVariables = Types.Exact<{
  input: SetCloseOverrideInput;
}>;

export type SetClosedMutation = {
  __typename?: 'Mutation';
  pOISetCloseOverride: {
    __typename?: 'SetCloseOverridePayload';
    success: boolean;
    message?: string | null;
    pOI?:
      | {__typename: 'Animal'; id: any; closed: boolean}
      | {__typename: 'ConstructionSite'; id: any; closed: boolean}
      | {__typename: 'Exhibition'; id: any; closed: boolean}
      | {__typename: 'FirstAid'; id: any; closed: boolean}
      | {__typename: 'Gastronomy'; id: any; closed: boolean}
      | {__typename: 'GuidedTour'; id: any; closed: boolean}
      | {__typename: 'Highlight'; id: any; closed: boolean}
      | {__typename: 'Playground'; id: any; closed: boolean}
      | {__typename: 'SanitaryFacility'; id: any; closed: boolean}
      | {__typename: 'Service'; id: any; closed: boolean}
      | {__typename: 'Shop'; id: any; closed: boolean}
      | null;
  };
};

export type BusinessTimeFragment = {
  __typename: 'BusinessTime';
  id: number;
  validFrom?: any | null;
  validUntil?: any | null;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  openingTime: any;
  closingTime: any;
};

export const BusinessTimeFragmentDoc = gql`
  fragment BusinessTime on BusinessTime {
    __typename
    id
    validFrom
    validUntil
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    openingTime
    closingTime
  }
`;
export const PointsOfInterestDocument = gql`
  query PointsOfInterest($facilityId: FacilityId!, $languageId: String! = "de") {
    pOIsForFacility(facilityId: $facilityId) @export(exportName: "PoiType") {
      __typename
      id
      closed
      translation(languageId: $languageId) {
        name
      }
      previewImage {
        fileId
      }
      information @export(exportName: "InformationType") {
        __typename
        id
        order
        title
        description
      }
      businessTimes @export(exportName: "BusinessTime") {
        ...BusinessTime
      }
      themeWorld @export(exportName: "ThemeWorld") {
        __typename
        id
        name
      }
      allStatuses @export(exportName: "StatusType") {
        __typename
        id
        message {
          __typename
          id
          translation(languageId: "de") {
            title
            description
            url
          }
        }
        schedule {
          ...BusinessTime
        }
        startDate
        endDate
        order
      }
    }
  }
  ${BusinessTimeFragmentDoc}
`;

export function usePointsOfInterestQuery(options: Omit<Urql.UseQueryArgs<PointsOfInterestQueryVariables>, 'query'>) {
  return Urql.useQuery<PointsOfInterestQuery>({query: PointsOfInterestDocument, ...options});
}
export const SetClosedDocument = gql`
  mutation SetClosed($input: SetCloseOverrideInput!) {
    pOISetCloseOverride(input: $input) {
      success
      message
      pOI {
        __typename
        id
        closed
      }
    }
  }
`;

export function useSetClosedMutation() {
  return Urql.useMutation<SetClosedMutation, SetClosedMutationVariables>(SetClosedDocument);
}
