import React, {ChangeEventHandler, FC, useCallback} from 'react';
import clsx from 'clsx';

import {LanguageType} from '../common/queries/languages.generated';

export interface LanguageToggleProps {
  className?: string;
  value: string;
  onChange: (id: string) => void;
  showFullNames?: boolean;
  vertical?: boolean;
  languages: LanguageType[];
}

interface LanguageToggleOptionProps {
  language: LanguageType;
  active: boolean;
  onChange: (id: string) => void;
  showFullName?: boolean;
  vertical?: boolean;
}

const LanguageToggleOption: FC<LanguageToggleOptionProps> = ({
  language,
  active,
  onChange,
  showFullName,
  vertical,
  ...rest
}) => {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (evt) => {
      onChange(evt.currentTarget.value);
    },
    [onChange],
  );

  let fullNameClassName: string;
  let codeClassName: string;
  if (showFullName === true) {
    fullNameClassName = 'inline';
    codeClassName = 'hidden';
  } else if (showFullName === false) {
    fullNameClassName = 'hidden';
    codeClassName = 'inline';
  } else {
    fullNameClassName = 'md:inline hidden';
    codeClassName = 'md:hidden inline';
  }

  return (
    <label
      title={language.name}
      className={clsx('cursor-pointer flex items-center text-black p-2', {
        'border-red': active,
        'border-b-2': !vertical,
        'border-l-2': vertical,
      })}
      data-cy={`language-${language.id}`}
      {...rest}
    >
      <input
        type='radio'
        name='__language-toggle'
        className='peer sr-only'
        value={language.id}
        checked={active}
        onChange={handleChange}
      />
      <div
        className={clsx('p-1 uppercase font-bold text-sm ring-red peer-focus:ring-2', {
          'text-red': active,
        })}
      >
        <span className={codeClassName}>{language.id}</span>
        <span className={fullNameClassName}>{language.name}</span>
      </div>
    </label>
  );
};

export const LanguageToggle: FC<LanguageToggleProps> = (props) => {
  const {languages, onChange, value, showFullNames, vertical, ...rest} = props;
  return (
    <div className={clsx('flex', {'flex-col': vertical})} data-cy='language-toggle' {...rest}>
      {languages
        .sort((l1, l2) => (l1.name < l2.name ? -1 : 1))
        .map((language) => (
          <LanguageToggleOption
            key={language.id}
            language={language}
            showFullName={showFullNames}
            active={language.id === value}
            onChange={onChange}
            vertical={vertical}
          />
        ))}
    </div>
  );
};
