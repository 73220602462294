import * as Types from '../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetSystemInfoQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetSystemInfoQuery = {
  __typename?: 'Query';
  systemInfo: {__typename?: 'SystemInfo'; localTime: any; berlinTime: any; cultures: Array<string>};
};

export const GetSystemInfoDocument = gql`
  query GetSystemInfo {
    systemInfo {
      localTime
      berlinTime
      cultures
    }
  }
`;

export function useGetSystemInfoQuery(options?: Omit<Urql.UseQueryArgs<GetSystemInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSystemInfoQuery>({query: GetSystemInfoDocument, ...options});
}
