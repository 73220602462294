export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };





export enum AgeGroup {
  Adult = 'ADULT',
  Minor = 'MINOR'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export enum Category {
  Event = 'EVENT',
  News = 'NEWS',
  Other = 'OTHER'
}

export enum ContentStringLocation {
  Home = 'HOME'
}

export enum ErrorCodeType {
  FeatureFlagMissing = 'FEATURE_FLAG_MISSING',
  InvalidCredential = 'INVALID_CREDENTIAL'
}

export enum FeatureFlagNames {
  ManageNewsAndUpdates = 'MANAGE_NEWS_AND_UPDATES',
  ManagePoIs = 'MANAGE_PO_IS',
  ManageStatus = 'MANAGE_STATUS',
  ManageUsers = 'MANAGE_USERS',
  SetPoiStatus = 'SET_POI_STATUS'
}

export enum PanoramaLocation {
  FeedingList = 'FEEDING_LIST',
  Home = 'HOME',
  TicketInput = 'TICKET_INPUT'
}

export enum TicketCodeType {
  Itf14 = 'ITF14',
  Qr = 'QR'
}

export enum TicketType {
  Tagesticket = 'TAGESTICKET',
  ZooCard = 'ZOO_CARD'
}
