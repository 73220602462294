import React, {FC} from 'react';
import {Menu as HUMenu} from '@headlessui/react';
import clsx from 'clsx';

export interface MenuItemProps {
  className?: string;
  icon?: FC<{className?: string}>;
  disabled?: boolean;
  onClick?: () => void;
}

export const MenuItem: FC<MenuItemProps> = ({icon: Icon, children, ...rest}) => (
  <HUMenu.Item {...rest}>
    {({active, disabled}) => (
      <button
        type='button'
        className={clsx('group flex rounded-md items-center px-2 py-2 text-sm', {
          'w-full': children != null,
          'bg-gray-100': active,
          'text-gray-300': disabled,
          'text-gray-900': !disabled,
        })}
      >
        {Icon ? <Icon className={clsx({'text-gray-200': disabled}, 'h-5 text-gray-300', children && 'mr-2')} /> : null}
        {children}
      </button>
    )}
  </HUMenu.Item>
);
