import {Add} from '@mui/icons-material';
import React, {FC, useCallback, useEffect} from 'react';
import Loader from 'react-loader-spinner';
import {useFilePicker} from 'use-file-picker';
import {FeatureFlagNames} from '../../../../__generated__/graphql';
import {FeatureGate} from '../../../../components/feature-gate';
import FormButton from '../../../../components/forms/form-button';
import {useLoading} from '../../../../context/loading/loading';
import {useOutcomeToast} from '../../../../hooks/useOutcomeToast';
import {PoiType} from '../../poi.generated';
import {DocumentLine} from './document-line';
import {useAttachDocumentMutation, usePoiDocumentsQuery} from './documents.generated';

interface DocumentsProps {
  poi: PoiType;
}

const poiTypesWithDocuments: PoiType['__typename'][] = ['Gastronomy', 'Shop'];

export const Documents: FC<DocumentsProps> = ({poi}) => {
  const [{data}] = usePoiDocumentsQuery({variables: {id: poi.id}});

  const [open, {loading, plainFiles, clear}] = useFilePicker({
    accept: 'application/pdf',
    readAs: 'DataURL',
  });

  useLoading(loading);

  const toast = useOutcomeToast();
  const [{fetching}, mutateAttachDocument] = useAttachDocumentMutation();
  const attachDocument = useCallback(
    async (file: File) => {
      const response = await mutateAttachDocument({input: {pOIId: poi.id, file: file}});
      const success = !!response.data?.documentAttach;
      toast(success);
    },
    [mutateAttachDocument, poi.id, toast],
  );

  useEffect(() => {
    if (plainFiles.length === 1) {
      attachDocument(plainFiles[0]).then(() => clear());
    }
  }, [plainFiles, attachDocument, clear]);

  if (!data || !poiTypesWithDocuments.includes(poi.__typename)) {
    return null;
  }

  return (
    <div data-cy='statuses' className='relative w-full bg-ochre-light px-6 py-4 rounded-lg overflow-hidden'>
      {fetching && (
        <div className='absolute inset-0 flex items-center justify-center bg-gray-800/30'>
          <Loader type='TailSpin' color='#b59067' height={75} width={75} />
        </div>
      )}

      <div className='flex items-center justify-between'>
        <p className='text-md font-bold'>Dokumente</p>
        <FeatureGate hasOneOf={{name: FeatureFlagNames.ManagePoIs, poiType: poi.__typename}}>
          <div className='flex'>
            <FormButton type='button' onClick={open}>
              <Add />
            </FormButton>
          </div>
        </FeatureGate>
      </div>
      {data.pOI.documents.length > 0 && (
        <div className='flex flex-col mt-6'>
          {data.pOI.documents.map((d) => (
            <DocumentLine
              key={d.id}
              poiId={poi.id}
              documentId={d.id}
              link={'/api/file/' + d.objectName}
              originalFileName={d.originalFileName}
            />
          ))}
        </div>
      )}
    </div>
  );
};
