import React, {FC, useCallback, useEffect, useState} from 'react';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import {useFieldArray, useForm} from 'react-hook-form';
import NewsLink from './components/news-link';
import {useGetNewsLinksQuery, useUpdateNewsLinksMutation} from './news.generated';
import {TextButton} from '../../../components';
import {NewsLinkForm} from './form-type';
import AddButton from '../../../components/add-button';
import {useUser} from '../../../context/user/user';
import {useUrqlRequest} from '../../../hooks/useUrqlRequest';
import {useDialog} from '../../../context/dialog/dialog';
import {useIconsQuery} from '../../../common/queries/icons.generated';
import {useOutcomeToast} from '../../../hooks/useOutcomeToast';

const News: FC = () => {
  const {
    control,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: {isDirty, errors},
  } = useForm<NewsLinkForm>();
  const {fields, append, remove, move} = useFieldArray<NewsLinkForm>({control, name: 'links'});
  const user = useUser();

  const [{data, fetching, error}] = useUrqlRequest(useGetNewsLinksQuery, {
    variables: {facilityId: user?.facilityId},
    pause: !user,
  });
  const [, updateNewsLinks] = useUrqlRequest(useUpdateNewsLinksMutation);
  const [{data: iconsData}] = useIconsQuery();

  const [initialLoad, setInitialLoad] = useState(true);

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const {destination, source} = result;
      if (!destination || destination.index === source.index) {
        return;
      }

      move(source.index, destination.index);
    },
    [move],
  );

  const setEmpty = useCallback(
    (isDefault: boolean) => {
      const emptyLinks = [{id: 'empty', href: '', title: '', iconId: iconsData?.icons[0].id || ''}];

      if (!isDefault) {
        setValue('links', emptyLinks, {shouldDirty: true});
      } else {
        reset({links: emptyLinks});
      }
    },
    [reset, setValue, iconsData],
  );

  const removeOrClear = useCallback(
    (index: number) => {
      if (fields.length === 1) {
        setEmpty(false);
      } else {
        remove(index);
      }
    },
    [fields, remove, setEmpty],
  );

  const resetDialog = useDialog({
    title: 'Änderungen verwerfen',
    message: 'Wollen Sie wirklich alle Ihre Änderungen verwerfen?',
    actionCallback: reset,
  });

  const addEmpty = useCallback(() => {
    const unsavedPrefix = 'new-item-';
    const newFieldsCount = fields.filter((f) => f.id.startsWith(unsavedPrefix)).length;
    const newName = unsavedPrefix + newFieldsCount;
    append({id: newName, iconId: iconsData?.icons[0].id || '', href: '', title: ''});
  }, [append, fields, iconsData]);

  const toast = useOutcomeToast();
  const saveChanges = useCallback(
    async (form: NewsLinkForm) => {
      const links = form.links.map(({href, title, iconId}) => ({href, title, iconId}));
      const res = await updateNewsLinks({input: {links}});
      const success = !res.error && res.data?.newsLinksUpdate.success;
      toast(success);
      if (success) {
        reset({links: res.data?.newsLinksUpdate.newsLinks});
      }
    },
    [toast, reset, updateNewsLinks],
  );

  useEffect(() => {
    if (!data || !initialLoad) return;
    const serverLinks = data.newsLinksForFacility;

    if (!isDirty && serverLinks.length !== 0) {
      reset({links: serverLinks});
    } else {
      setEmpty(true);
    }

    setInitialLoad(false);
  }, [data, initialLoad, isDirty, reset, setEmpty, setInitialLoad]);

  if (!data || error || fetching || !iconsData) return <></>; // TODO better handling of those cases?

  return (
    <div className='flex flex-col flex-grow'>
      <div className='mb-8 text-black'>
        Passen Sie hier die Links zu den News & Updates an, welche dem Nutzer der APP angezeigt werden. Ungespeicherte
        Änderungen gehen verloren!
      </div>

      <form className='flex flex-col flex-grow' onSubmit={handleSubmit(saveChanges)}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='news-links'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fields.map((field, i) => (
                  <NewsLink
                    {...field}
                    key={field.id}
                    index={i}
                    onDelete={() => removeOrClear(i)}
                    register={register}
                    control={control}
                    errors={errors}
                    icons={iconsData.icons || []}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <AddButton callback={addEmpty} />

        <div className='flex flex-1 flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 md:justify-end'>
          <TextButton disabled={!isDirty} type='reset' onClick={resetDialog.getConfirmation}>
            Abbrechen
          </TextButton>
          <TextButton disabled={!isDirty} type='submit'>
            Speichern
          </TextButton>
        </div>
      </form>
    </div>
  );
};

export default News;
