import ReactQuill from 'react-quill';
import type {Delta as DeltaType, DeltaOperation, DeltaStatic} from 'quill';
import Quill from 'quill';
import React, {FC} from 'react';
import './quill-view.css';

const Delta = Quill.import('delta') as typeof DeltaType;

export interface QuillViewProps {
  value: DeltaOperation[] | DeltaStatic;
  className?: string;
  children?: never;
}

const READONLY_MODULES: ReactQuill.ReactQuillProps['modules'] = {
  toolbar: false,
};

export const QuillView: FC<QuillViewProps> = (props) => {
  const {value, ...rest} = props;
  const delta = Array.isArray(value) ? new Delta(value) : value;
  return (
    <div data-readonly {...rest}>
      <ReactQuill value={delta} onChange={() => {}} modules={READONLY_MODULES} readOnly {...rest} />
    </div>
  );
};
