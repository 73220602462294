import {SvgIconComponent} from '@mui/icons-material';
import React, {ComponentType, FC} from 'react';
import {FeatureGateSpecification, useFeatureFlags} from '../../context/user/feature-flags';
import {useTitle} from 'react-use';
import {Sidebar} from '../sidebar/sidebar';
import {Navigate, Route, Routes} from 'react-router-dom';

export interface SidebarPageDefinition {
  title: string;
  icon: SvgIconComponent;
  routerId: string;
  component: ComponentType;
  featureGate?: FeatureGateSpecification;
}

type SidebarPageContainerProps = Pick<SidebarPageDefinition, 'title'> & {prefix: string};

const SidebarPageContainer: FC<SidebarPageContainerProps> = ({prefix, title, children}) => {
  useTitle(`${prefix} - ${title}`);
  return <>{children}</>;
};

interface SidebarWithPagesProps {
  defs: SidebarPageDefinition[];
  title: string;
}

export const SidebarWithPages: FC<SidebarWithPagesProps> = ({defs, title: baseTitle}) => {
  useTitle(baseTitle);

  const featureFlags = useFeatureFlags();

  if (!featureFlags.hasUser()) {
    // The first renders don't have a user yet. To prevent redirection at the start we early return here
    return null;
  }

  const usedDefs = defs.filter((d) => !d.featureGate || featureFlags.hasOneOf(d.featureGate));
  const fallback = usedDefs[0].routerId;

  return (
    <div className='flex'>
      <Sidebar className='-ml-5 sm:-ml-6'>
        {usedDefs.map(({component, ...itemProps}) => (
          <Sidebar.Item key={itemProps.routerId} {...itemProps} />
        ))}
      </Sidebar>
      <div className='ml-4 relative flex-1'>
        <Routes>
          {usedDefs.map(({component: Component, title, routerId}) => (
            <Route
              key={routerId}
              path={routerId}
              element={
                <SidebarPageContainer title={title} prefix={baseTitle}>
                  <Component />
                </SidebarPageContainer>
              }
            />
          ))}
          <Route path='*' element={<Navigate to={fallback} />} />
        </Routes>
      </div>
    </div>
  );
};
