import React, {FC, useCallback} from 'react';
import {sortBy} from 'lodash';
import ThemeWorldTag, {ThemeWorldTagProps} from './theme-world-tag';
import {useThemeWorldsQuery} from './theme-worlds.generated';

export interface ThemeWorldFilterProps {
  facilityId: string;
  excludedIds: string[];
  onChange: (nextExcludedIds: string[]) => void;
}

const DATA_CY = 'theme-world-filter';

const ThemeWorldFilter: FC<ThemeWorldFilterProps> = ({excludedIds, onChange, facilityId, ...rest}) => {
  const [themeWorldsQuery] = useThemeWorldsQuery({variables: {facilityId}});
  const themeWorlds = sortBy(themeWorldsQuery.data?.themeWorlds, (w) => w.name);
  const handleClick = useCallback<NonNullable<ThemeWorldTagProps['onClick']>>(
    (themeWorldId) => {
      const nextExcludedIds = excludedIds.includes(themeWorldId)
        ? // Remove filter
          excludedIds.filter((id) => themeWorldId !== id)
        : // Add filter
          [themeWorldId, ...excludedIds];
      onChange(nextExcludedIds);
    },
    [excludedIds, onChange],
  );

  if (themeWorldsQuery.fetching) {
    return <div data-cy={DATA_CY} />;
  }

  return (
    <div {...rest} data-cy={DATA_CY}>
      {(themeWorlds || []).map((world) => (
        <ThemeWorldTag active={!excludedIds.includes(world.id)} key={world.id} {...world} onClick={handleClick} />
      ))}
    </div>
  );
};

export default ThemeWorldFilter;
