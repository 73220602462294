import React, {FC} from 'react';
import {StatusMessage} from '../../../../components/status-message';
import {StatusType} from '../../poi.generated';

export interface StatusMessageDisplayProps {
  className?: string;
  statuses: StatusType[];
}

const StatusList: FC<StatusMessageDisplayProps> = (props) => {
  const {statuses, ...rest} = props;
  return (
    <div {...rest} data-cy='status-list'>
      {statuses.map((status, index) => {
        const {translation} = status.message;
        return (
          <StatusMessage
            key={status.id}
            index={index}
            title={translation.title}
            description={translation.description}
            url={translation.url}
            schedule={status.schedule}
            className='border-b border-ochre border-opacity-25 last:border-none py-4'
          />
        );
      })}
    </div>
  );
};

export default StatusList;
