import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import {useCurrentUserQuery, User} from './user.generated';
import {useUrqlRequest} from '../../hooks/useUrqlRequest';
import {UserContext, UserContextProps} from './context';

const cookieName = 'ZooDomainShadow';
const getIsLoggedIn = () => document.cookie.split(';').some((c) => c.trim().startsWith(cookieName));

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({children}) => {
  const [loggedIn, setLoggedIn] = useState(() => getIsLoggedIn());
  const [user, setUser] = useState<User | undefined>();

  const [{data, error}, refetch] = useUrqlRequest(useCurrentUserQuery, {
    pause: true,
    showLoading: false,
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    setUser(data?.my);
    setLoggedIn(!error && getIsLoggedIn());
  }, [data, error]);

  const isLoggedIn = getIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [refetch, isLoggedIn]);

  const value = useMemo(
    () => ({
      user,
      loggedIn,
      update: refetch,
    }),
    [user, loggedIn, refetch],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

interface UserConsumerProps {
  children: (context: UserContextProps) => React.ReactNode;
}

export const UserConsumer: FC<UserConsumerProps> = ({children}) => (
  <UserContext.Consumer>{children}</UserContext.Consumer>
);

export const useUser = (): User | undefined => {
  const context = useContext(UserContext);
  return context.user;
};
