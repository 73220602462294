import * as Types from '../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateStatusMessageInput = {
  poiType: string;
  translations: Array<StatusMessageTranslationInput>;
};

export type DeleteStatusMessageInput = {
  id: any;
};

export type StatusMessageTranslationInput = {
  description: string;
  languageId: string;
  title: string;
  url?: string | null;
};

export type UpdateStatusMessageInput = {
  id: any;
  poiType?: string | null;
  translations?: Array<StatusMessageTranslationInput> | null;
};

export type CreateStatusMessageMutationVariables = Types.Exact<{
  input: CreateStatusMessageInput;
}>;

export type CreateStatusMessageMutation = {
  __typename?: 'Mutation';
  statusMessageCreate: {__typename?: 'StatusMessagePayload'; statusMessage: {__typename?: 'StatusMessage'; id: any}};
};

export type UpdateStatusMessageMutationVariables = Types.Exact<{
  input: UpdateStatusMessageInput;
}>;

export type UpdateStatusMessageMutation = {
  __typename?: 'Mutation';
  statusMessageUpdate: {__typename?: 'StatusMessagePayload'; statusMessage: {__typename?: 'StatusMessage'; id: any}};
};

export type DeleteStatusMessageMutationVariables = Types.Exact<{
  input: DeleteStatusMessageInput;
}>;

export type DeleteStatusMessageMutation = {
  __typename?: 'Mutation';
  statusMessageDelete: {__typename?: 'StatusMessagePayload'; statusMessage: {__typename?: 'StatusMessage'; id: any}};
};

export const CreateStatusMessageDocument = gql`
  mutation CreateStatusMessage($input: CreateStatusMessageInput!) {
    statusMessageCreate(input: $input) {
      statusMessage {
        id
      }
    }
  }
`;

export function useCreateStatusMessageMutation() {
  return Urql.useMutation<CreateStatusMessageMutation, CreateStatusMessageMutationVariables>(
    CreateStatusMessageDocument,
  );
}
export const UpdateStatusMessageDocument = gql`
  mutation UpdateStatusMessage($input: UpdateStatusMessageInput!) {
    statusMessageUpdate(input: $input) {
      statusMessage {
        id
      }
    }
  }
`;

export function useUpdateStatusMessageMutation() {
  return Urql.useMutation<UpdateStatusMessageMutation, UpdateStatusMessageMutationVariables>(
    UpdateStatusMessageDocument,
  );
}
export const DeleteStatusMessageDocument = gql`
  mutation DeleteStatusMessage($input: DeleteStatusMessageInput!) {
    statusMessageDelete(input: $input) {
      statusMessage {
        id
      }
    }
  }
`;

export function useDeleteStatusMessageMutation() {
  return Urql.useMutation<DeleteStatusMessageMutation, DeleteStatusMessageMutationVariables>(
    DeleteStatusMessageDocument,
  );
}
