import React, {FC, useCallback} from 'react';
import {Cancel, Delete, Save} from '@mui/icons-material';
import {toast} from 'react-hot-toast';
import {useUrqlRequest} from '../../../../hooks/useUrqlRequest';
import {BusinessTime, PoiType} from '../../poi.generated';
import {
  useCreateBusinessTimeMutation,
  useDeleteBusinessTimeMutation,
  useUpdateBusinessTimeMutation,
} from './business-time.generated';

import FormButton from '../../../../components/forms/form-button';
import {extractWeekdays} from './weekdays';
import {useFormBuilder} from '../../../../components/forms/formbuilder';
import BusinessTimeSubform, {BusinessTimeFormValues} from './business-time-subform';

export const PoiBusinessTimeForm: FC<{
  poi: PoiType;
  businessTime?: Partial<BusinessTime>;
  close: () => void;
}> = ({poi, businessTime, close}) => {
  const {handleSubmit, fields} = useFormBuilder<BusinessTimeFormValues>({
    defaultValues: businessTime
      ? {
          ...businessTime,
          weekdays: extractWeekdays(businessTime),
          open: {
            from: businessTime.openingTime ? new Date(businessTime.openingTime) : null,
            to: businessTime.closingTime ? new Date(businessTime.closingTime) : null,
          },
          valid: {
            from: businessTime.validFrom ? new Date(businessTime.validFrom) : null,
            to: businessTime.validUntil ? new Date(businessTime.validUntil) : null,
          },
        }
      : {
          weekdays: extractWeekdays({}),
          valid: {},
        },
  });

  const id = businessTime?.id;

  const [, createBusinessTime] = useUrqlRequest(useCreateBusinessTimeMutation);
  const [, updateBusinessTime] = useUrqlRequest(useUpdateBusinessTimeMutation);
  const [, deleteBusinessTime] = useUrqlRequest(useDeleteBusinessTimeMutation);

  const createTime = useCallback(
    async (bt: BusinessTimeFormValues) => {
      const res = await createBusinessTime({
        input: {
          pOIId: poi.id,
          ...bt.weekdays,
          openingTime: bt.open.from,
          closingTime: bt.open.to,
          validFrom: bt.valid.from ?? null,
          validUntil: bt.valid.to ?? null,
        },
      });
      if (!res.data?.businessTimeCreate.success) {
        toast.error('Erstellen fehlgeschlagen.', {
          icon: '🦥',
        });
      } else {
        toast.success('Erstellen erfolgreich!', {
          icon: '🐵',
        });
        close();
      }
    },
    [close, createBusinessTime, poi.id],
  );

  const updateTime = useCallback(
    async (bt: BusinessTimeFormValues) => {
      if (id != null) {
        const res = await updateBusinessTime({
          input: {
            id,
            pOIId: poi.id,
            ...bt.weekdays,
            openingTime: bt.open.from,
            closingTime: bt.open.to,
            validFrom: bt.valid.from ?? null,
            validUntil: bt.valid.to ?? null,
          },
        });
        if (res.error || !res.data?.businessTimeUpdate.success) {
          toast.error('Speichern fehlgeschlagen.', {
            icon: '🦥',
          });
        } else {
          toast.success('Speichern erfolgreich!', {
            icon: '🐵',
          });
          close();
        }
      }
    },
    [close, id, poi.id, updateBusinessTime],
  );

  const deleteTime = useCallback(async () => {
    if (id != null) {
      const res = await deleteBusinessTime({
        input: {
          pOIId: poi.id,
          id,
        },
      });
      if (res.error || !res.data?.businessTimeDelete.success) {
        toast.error('Löschen fehlgeschlagen.', {
          icon: '🦥',
        });
      } else {
        toast.success('Löschen erfolgreich!', {
          icon: '🐵',
        });
      }
    }
    close();
  }, [close, deleteBusinessTime, id, poi.id]);

  return (
    <form
      data-cy='business-time-form'
      className='relative flex flex-col gap-y-6'
      onSubmit={handleSubmit(id == null ? createTime : updateTime)}
    >
      <div className='flex justify-between'>
        <BusinessTimeSubform on={fields} />
        <div className='flex'>
          <FormButton type='submit'>
            <Save className='p-1' />
          </FormButton>
          <FormButton type='button' onClick={deleteTime}>
            <Delete className='p-1' />
          </FormButton>
          <FormButton type='button' onClick={close}>
            <Cancel className='p-1' />
          </FormButton>
        </div>
      </div>
    </form>
  );
};
