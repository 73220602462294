import type {Delta as DeltaType, DeltaOperation, DeltaStatic} from 'quill';
import Quill from 'quill';
import ReactQuill, {ReactQuillProps} from 'react-quill';
import React, {useCallback} from 'react';
import 'react-quill/dist/quill.snow.css';
import './quill-input.css';

const Delta = Quill.import('delta') as typeof DeltaType;

// Allow poi URL scheme
const customSchemes = ['poi', 'animal'];
Quill.import('formats/link').PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', ...customSchemes];

export interface QuillInputProps {
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
  value: DeltaOperation[] | DeltaStatic;
  onChange: (value: DeltaStatic) => void;
  onBlur?: ReactQuillProps['onBlur'];
  children?: never;
}

// Consider limitations of flutter-quill (e.g. no super/subscript)
const FORMATS_WHITELIST: ReactQuillProps['formats'] = [
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'link',
  'list',
  'blockquote',
  'indent',
  'header',
];

const MODULES: ReactQuillProps['modules'] = {
  toolbar: [
    [{size: ['small', false, 'large', 'huge']}],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'link'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['clean'],
  ],
};

export const QuillInput = React.forwardRef<HTMLDivElement, QuillInputProps>((props, ref) => {
  const {value, onChange, onBlur, ...rest} = props;
  const handleChange: ReactQuillProps['onChange'] = useCallback(
    (_value, _delta, _sources, editor) => {
      // Note: Do not pass _delta here!
      // https://github.com/zenoamaro/react-quill#using-deltas
      onChange(editor.getContents());
    },
    [onChange],
  );

  const delta = Array.isArray(value) ? new Delta(value) : value;

  return (
    <div ref={ref} data-cy='quill-input' {...rest}>
      <ReactQuill
        theme='snow'
        value={delta}
        onChange={handleChange}
        onBlur={onBlur}
        formats={FORMATS_WHITELIST}
        modules={MODULES}
      />
    </div>
  );
});
