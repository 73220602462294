import React, {FC, useCallback, useMemo} from 'react';
import {useLocalStorage, useTitle} from 'react-use';
import {AnimateSharedLayout} from 'framer-motion';
import {useUser} from '../../context/user/user';
import {usePointsOfInterestQuery} from './poi.generated';
import {useUrqlRequest} from '../../hooks/useUrqlRequest';
import AnimalIcon from '../../assets/poi/animal.svg';
import ConstructionSiteIcon from '../../assets/poi/construction-site.svg';
import ExhibitionIcon from '../../assets/poi/exhibition.svg';
import FirstAidIcon from '../../assets/poi/first-aid.svg';
import GastronomyIcon from '../../assets/poi/gastronomy.svg';
import HighlightIcon from '../../assets/poi/highlight.svg';
import PlaygroundIcon from '../../assets/poi/playground.svg';
import SanitaryFacilityIcon from '../../assets/poi/sanitary-facility.svg';
import ServiceIcon from '../../assets/poi/service.svg';
import ShopIcon from '../../assets/poi/shop.svg';
import OpenPoiCard from './components/open-poi-card';
import Category from './components/category';
import ThemeWorldFilter, {ThemeWorldFilterProps} from './components/theme-world-filter/theme-world-filter';
import {usePoiTypesQuery} from '../../common/queries/poi-types.generated';
import ScrollUp from '../../layout/logged-in-layout/components/scroll-up';

const poiTypeIcons: Record<string, string> = {
  Animal: AnimalIcon,
  ConstructionSite: ConstructionSiteIcon,
  Exhibition: ExhibitionIcon,
  FirstAid: FirstAidIcon,
  Gastronomy: GastronomyIcon,
  Highlight: HighlightIcon,
  Playground: PlaygroundIcon,
  SanitaryFacility: SanitaryFacilityIcon,
  Service: ServiceIcon,
  Shop: ShopIcon,
};

const Poi: FC = () => {
  useTitle('Points of Interest');

  const user = useUser();
  const [excludedThemeWorldIds = [], setExcludedThemeWorldIds] = useLocalStorage<string[]>('poi.filter', []);
  const context = useMemo(() => ({additionalTypenames: ['BusinessTime', 'Information', 'Status']}), []);
  const [{data}] = useUrqlRequest(usePointsOfInterestQuery, {
    variables: {facilityId: user?.facilityId},
    pause: !user,
    requestPolicy: 'cache-and-network',
    context,
  });
  const [{data: poiTypesData}] = useUrqlRequest(usePoiTypesQuery, {pause: !user});

  const handleThemeWorldFilterChange = useCallback<ThemeWorldFilterProps['onChange']>(
    (nextExcludedIds) => {
      setExcludedThemeWorldIds(nextExcludedIds);
    },
    [setExcludedThemeWorldIds],
  );

  if (!data || !poiTypesData) return <div>Loading...</div>;

  const pointsOfInterest = data?.pOIsForFacility
    .sort((a, b) => (a.translation.name > b.translation.name ? 1 : -1))
    .filter((poi) => !excludedThemeWorldIds.includes(poi.themeWorld.id));

  if (pointsOfInterest === undefined) return <div>Kein POI gefunden</div>;

  return (
    <div className='flex flex-col flex-grow' data-cy='poi-list'>
      <div>
        <ThemeWorldFilter
          facilityId={user?.facilityId}
          excludedIds={excludedThemeWorldIds}
          onChange={handleThemeWorldFilterChange}
        />
      </div>

      <AnimateSharedLayout type='crossfade'>
        {poiTypesData.poiTypes.map(({name, types}) => (
          <Category
            key={name}
            name={name}
            icon={poiTypeIcons[types[0]]}
            pointsOfInterest={pointsOfInterest.filter((poi) => types.includes(poi.__typename))}
          />
        ))}
        <ScrollUp />
        <OpenPoiCard pointsOfInterest={pointsOfInterest} />
      </AnimateSharedLayout>
    </div>
  );
};

export default Poi;
