import {FC, useEffect} from 'react';
import {ContentStringLocation} from '../../../../../__generated__/graphql';
import {useContentStringsQuery, useUpdateContentStringMutation} from './content-strings.generated';
import {useUrqlRequest} from '../../../../../hooks/useUrqlRequest';
import {useUser} from '../../../../../context/user/user';
import {SubmitHandler, useForm, Controller} from 'react-hook-form';
import {useOutcomeToast} from '../../../../../hooks/useOutcomeToast';
import parseQuillDelta from '../../../../../util/parse-quill-delta';
import {DeltaOperation, DeltaStatic} from 'quill';
import {ContentStringInput} from './content-string-input';
import {TextButton} from '../../../../../components';

type ContentStringsForm = Record<ContentStringLocation, DeltaOperation[] | DeltaStatic>;

const locations = Object.values(ContentStringLocation);

export const ContentStrings: FC = () => {
  const user = useUser();
  const [{data}] = useUrqlRequest(useContentStringsQuery, {variables: {facilityId: user?.facilityId}});
  const [, updateContentStrings] = useUpdateContentStringMutation();

  const {handleSubmit, control, reset} = useForm<ContentStringsForm>();

  useEffect(() => {
    const strings = data?.contentStringsForFacility;
    if (!strings) return;

    const formData: Partial<ContentStringsForm> = {};
    for (const string of strings) {
      formData[string.location] = parseQuillDelta(string.value);
    }

    reset(formData);
  }, [data, reset]);

  const toast = useOutcomeToast();

  const onSubmit: SubmitHandler<ContentStringsForm> = async (form) => {
    const stringify = (delta: DeltaStatic | DeltaOperation[]) =>
      JSON.stringify(Array.isArray(delta) ? delta : delta.ops);

    const strings = locations.map((location) => ({location, value: stringify(form[location])}));

    const response = await updateContentStrings({input: {strings}});
    const success = !response.error && !!response.data?.contentStringsUpdate;
    toast(success);

    if (success) reset(form);
  };

  return (
    <form className='flex flex-col space-y-4' onSubmit={handleSubmit(onSubmit)}>
      <h2 className='text-2xl font-bold text-ochre-dark'>Texte</h2>
      {locations.map((l, i) => (
        <div className={i % 2 === 0 ? '' : 'bg-ochre-light'} key={l}>
          <Controller
            control={control}
            name={l}
            render={({field}) => <ContentStringInput location={l} value={field.value} onChange={field.onChange} />}
          />
        </div>
      ))}
      <TextButton type='submit'>Speichern</TextButton>
    </form>
  );
};
