import React, {FC, Fragment, PropsWithChildren, ReactElement} from 'react';
import {Menu as HUMenu, Transition} from '@headlessui/react';
import {MoreHoriz} from '@mui/icons-material';
import clsx from 'clsx';
import {MenuItem} from './menu-item';

export interface MenuProps {
  className?: string;
  menuClassName?: string;
  renderButtonContents?: MenuButtonRenderer;
  align?: 'left' | 'right';
}
export type MenuButtonRenderer = () => ReactElement;

const renderDefaultMenuButtonContents: MenuButtonRenderer = () => <MoreHoriz />;

export const Menu: FC<MenuProps> & {Item: typeof MenuItem} = Object.assign(
  (props: PropsWithChildren<MenuProps>) => {
    const {
      align = 'right',
      renderButtonContents = renderDefaultMenuButtonContents,
      className,
      menuClassName,
      children,
      ...rest
    } = props;
    return (
      <HUMenu as='div' className={clsx('relative inline-block text-left', className)} {...rest}>
        <div>
          <HUMenu.Button
            className='inline-flex justify-center w-full px-2 py-1 text-gray-500 bg-black rounded-md bg-opacity-5 hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-red focus-visible:ring-opacity-75'
            data-cy='open-menu'
          >
            {renderButtonContents()}
          </HUMenu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <HUMenu.Items
            className={clsx(
              'absolute z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              align === 'left' ? 'left-0' : 'right-0',
            )}
          >
            <div className={clsx('p-1', menuClassName)}>{children}</div>
          </HUMenu.Items>
        </Transition>
      </HUMenu>
    );
  },
  {Item: MenuItem},
);
