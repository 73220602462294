import * as Types from '../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateNewsLinkInput = {
  href: string;
  iconId: string;
  title: string;
};

export type UpdateNewsLinksInput = {
  links: Array<UpdateNewsLinkInput>;
};

export type GetNewsLinksQueryVariables = Types.Exact<{
  facilityId: any;
}>;

export type GetNewsLinksQuery = {__typename?: 'Query'; newsLinksForFacility: Array<NewsLink>};

export type NewsLink = {__typename?: 'NewsLink'; id: any; href: string; title: string; iconId: string};

export type UpdateNewsLinksMutationVariables = Types.Exact<{
  input: UpdateNewsLinksInput;
}>;

export type UpdateNewsLinksMutation = {
  __typename?: 'Mutation';
  newsLinksUpdate: {
    __typename?: 'UpdateNewsLinksPayload';
    success: boolean;
    newsLinks: Array<{__typename?: 'NewsLink'; id: any; title: string; href: string; order: number; iconId: string}>;
  };
};

export const GetNewsLinksDocument = gql`
  query GetNewsLinks($facilityId: FacilityId!) {
    newsLinksForFacility(facilityId: $facilityId) @export(exportName: "NewsLink") {
      id
      href
      title
      iconId
    }
  }
`;

export function useGetNewsLinksQuery(options: Omit<Urql.UseQueryArgs<GetNewsLinksQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNewsLinksQuery>({query: GetNewsLinksDocument, ...options});
}
export const UpdateNewsLinksDocument = gql`
  mutation UpdateNewsLinks($input: UpdateNewsLinksInput!) {
    newsLinksUpdate(input: $input) {
      newsLinks {
        id
        title
        href
        order
        iconId
      }
      success
    }
  }
`;

export function useUpdateNewsLinksMutation() {
  return Urql.useMutation<UpdateNewsLinksMutation, UpdateNewsLinksMutationVariables>(UpdateNewsLinksDocument);
}
