import React, {FC, useMemo} from 'react';
import {useThrottle} from 'react-use';
import {Add} from '@mui/icons-material';
import {useUser} from '../../../context/user/user';
import {LanguageToggle, useModalDialog} from '../../../components';
import StatusMessageList from './components/status-message-list';
import {useNewestState} from '../../../hooks/useNewestState';
import {PoiTypeSelector} from '../../../components/poi-type-selector';
import {useStatusMessagesQuery} from '../../../common/queries/status-messages.generated';
import {useLanguagesQuery} from '../../../common/queries/languages.generated';
import {usePoiTypesQuery} from '../../../common/queries/poi-types.generated';
import {StatusMessageFormDialog} from './components/status-message-form-dialog';
import {
  useCreateStatusMessageMutation,
  useDeleteStatusMessageMutation,
  useUpdateStatusMessageMutation,
} from './status-message-mutations.generated';
import {ConfirmDialog} from '../../../components/dialogs/confirm-dialog';
import {FeatureGate} from '../../../components/feature-gate';
import {FeatureFlagNames} from '../../../__generated__/graphql';

export const StatusMessagesPage: FC = () => {
  const user = useUser();

  const [poiTypesQuery] = usePoiTypesQuery({
    pause: !user,
  });

  const [languagesQuery] = useLanguagesQuery({
    variables: {facilityId: user?.facilityId},
    pause: !user,
  });

  const [languageId, setLanguageId] = useNewestState(languagesQuery.data?.languages[0]?.id);
  const [poiType, setPoiType] = useNewestState('Animal');
  // Use throttled value for the query because it's possible to change the value really quickly using keyboard
  // navigation (up and down arrow keys), leading to many unnecessary requests.
  const poiTypeThrottled = useThrottle(poiType, 500);

  // This ensures proper cache invalidation when an empty list is returned, followed by a create mutation.
  const statusMessagesQueryContext = useMemo(() => ({additionalTypenames: ['StatusMessage']}), []);

  const [statusMessagesQuery] = useStatusMessagesQuery({
    variables: {facilityId: user?.facilityId, poiType: poiTypeThrottled},
    pause: !user,
    context: statusMessagesQueryContext,
  });

  const [, createStatusMessage] = useCreateStatusMessageMutation();
  const [, updateStatusMessage] = useUpdateStatusMessageMutation();
  const [, deleteStatusMessage] = useDeleteStatusMessageMutation();

  const showDialog = useModalDialog();

  if (!user || languagesQuery.data == null || poiTypesQuery.data == null || languageId == null) {
    return <div>Lade...</div>;
  }

  const {languages} = languagesQuery.data;

  return (
    <div className='mb-4'>
      <div className='flex justify-between border-b border-gray-300'>
        <PoiTypeSelector value={poiType} onChange={setPoiType} poiTypes={poiTypesQuery.data.poiTypes} />
        <LanguageToggle languages={languagesQuery.data.languages} value={languageId} onChange={setLanguageId} />
      </div>
      <StatusMessageList
        poiType={poiType}
        languageId={languageId}
        items={statusMessagesQuery.data?.statusMessages || []}
        onEditMessage={async (statusMessage) => {
          const updatedMessage = await showDialog(StatusMessageFormDialog, {statusMessage, languages});
          if (updatedMessage) {
            await updateStatusMessage({input: {id: statusMessage.id, ...updatedMessage}});
          }
        }}
        onDeleteMessage={async (statusMessage) => {
          const confirmed = await showDialog(ConfirmDialog, {
            title: 'Löschen',
            description: 'Die Statusmeldung wird unwiderruflich gelöscht und von allen POIs entfernt.',
            confirmText: 'Löschen',
          });
          if (confirmed) {
            await deleteStatusMessage({input: {id: statusMessage.id}});
          }
        }}
      />
      <FeatureGate hasOneOf={{name: FeatureFlagNames.ManageStatus, poiType}}>
        <button
          className='flex items-center justify-center mt-3 py-2 text-gray-400 border-dashed border-gray-300 border w-full uppercase font-semibold text-sm hover:border-solid hover:border-ochre hover:bg-ochre hover:text-white'
          data-cy='add-status-message'
          onClick={async () => {
            const newMessage = await showDialog(StatusMessageFormDialog, {
              statusMessage: {id: '', translations: [], poiType},
              isNew: true,
              languages,
            });
            if (newMessage) {
              await createStatusMessage({input: newMessage});
            }
          }}
        >
          <Add fontSize='inherit' className='mr-1' />
          Neue Statusmeldung
        </button>
      </FeatureGate>
    </div>
  );
};
