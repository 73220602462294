import * as Types from '../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UsersQueryVariables = Types.Exact<{
  facilityId: any;
}>;

export type UsersQuery = {__typename?: 'Query'; users: Array<UserType>};

export type UserType = {
  __typename?: 'User';
  id: any;
  email: string;
  roles: Array<{__typename?: 'Role'; id: any; name: string}>;
};

export type RolesQueryVariables = Types.Exact<{
  facilityId: any;
}>;

export type RolesQuery = {__typename?: 'Query'; roles: Array<RoleType>};

export type RoleType = {__typename?: 'Role'; id: any; name: string};

export const UsersDocument = gql`
  query Users($facilityId: FacilityId!) {
    users(facilityId: $facilityId) @export(exportName: "UserType") {
      id
      email
      roles {
        id
        name
      }
    }
  }
`;

export function useUsersQuery(options: Omit<Urql.UseQueryArgs<UsersQueryVariables>, 'query'>) {
  return Urql.useQuery<UsersQuery>({query: UsersDocument, ...options});
}
export const RolesDocument = gql`
  query Roles($facilityId: FacilityId!) {
    roles(facilityId: $facilityId) @export(exportName: "RoleType") {
      id
      name
    }
  }
`;

export function useRolesQuery(options: Omit<Urql.UseQueryArgs<RolesQueryVariables>, 'query'>) {
  return Urql.useQuery<RolesQuery>({query: RolesDocument, ...options});
}
