import {FC, SVGProps} from 'react';

import {ReactComponent as announcement} from '../../assets/icons/announcement.svg';
import {ReactComponent as care} from '../../assets/icons/care.svg';
import {ReactComponent as document} from '../../assets/icons/document.svg';
import {ReactComponent as eye} from '../../assets/icons/eye.svg';
import {ReactComponent as globe} from '../../assets/icons/globe.svg';
import {ReactComponent as heart} from '../../assets/icons/heart.svg';
import {ReactComponent as light_bulb} from '../../assets/icons/light_bulb.svg';
import {ReactComponent as point_up} from '../../assets/icons/point_up.svg';
import {ReactComponent as pointer} from '../../assets/icons/pointer.svg';
import {ReactComponent as pointer_right} from '../../assets/icons/pointer_right.svg';
import {ReactComponent as question_mark} from '../../assets/icons/question_mark.svg';
import {ReactComponent as tickets} from '../../assets/icons/tickets.svg';

type IconComponent = FC<SVGProps<SVGSVGElement> & {title?: string}>;

export const iconsById: Record<string, IconComponent> = {
  announcement,
  care,
  document,
  eye,
  globe,
  heart,
  light_bulb,
  point_up,
  pointer,
  pointer_right,
  question_mark,
  tickets,
};
