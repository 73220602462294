import React, {createContext, FC, ReactElement, useContext, useState} from 'react';
import {ModalDialog} from './modal-dialog';

interface ModalDialogStackContextProps {
  stack: ModalStackItem[];
  push: (item: ModalStackItem) => void;
  remove: (idx: number) => void;
}

interface ModalDialogStackItemContextProps {
  index: number;
  options: ModalStackItemOptions;
}

type DismissHandler = () => void | boolean | Promise<boolean>;
type ModalDialogContentRenderer<TParams extends Array<unknown>> = (...params: TParams) => ReactElement;

interface ModalStackItem<TReturn = unknown> {
  element: ReactElement;
  resolve: (x: unknown) => void;
  defaultValue: TReturn;
  options: ModalStackItemOptions;
}

interface ModalStackItemOptions {
  dismissible?: boolean;
  onDismiss?: DismissHandler;
}

const ModalDialogStackContext = createContext<ModalDialogStackContextProps | null>(null);
const ModalDialogContext = createContext<ModalDialogStackItemContextProps | null>(null);

const useModalDialogContext = () => {
  const ctx = useContext(ModalDialogContext);
  if (!ctx) {
    throw new Error(`Missing ModalDialogProvider`);
  }
  return ctx;
};

const useModalDialogStackContext = () => {
  const ctx = useContext(ModalDialogStackContext);
  if (!ctx) {
    throw new Error(`Missing ModalDialogStackProvider`);
  }
  return ctx;
};

export interface ModalDialogConfig<TParams extends unknown[], TReturn> {
  render: ModalDialogContentRenderer<TParams>;
  defaultValue?: TReturn;
}

export const createModalDialog = <TParams extends unknown[], TReturn>(
  render: ModalDialogContentRenderer<TParams>,
  defaultValue?: TReturn,
): ModalDialogConfig<TParams, TReturn> => ({
  render,
  defaultValue,
});

const dismissModal = async (remove: (idx: number) => void, idx: number, handler: DismissHandler | undefined) => {
  if (!handler) {
    remove(idx);
    return true;
  }
  const res = await handler();
  const shouldClose = res === undefined || res;
  if (shouldClose) {
    remove(idx);
  }
  return shouldClose;
};

const ModalDialogProvider: FC<{options: ModalStackItemOptions; index: number}> = ({children, options, index}) => (
  <ModalDialogContext.Provider value={{index, options}}>{children}</ModalDialogContext.Provider>
);

const ModalDialogConsumer: FC<{
  depth: number;
  remove: ModalDialogStackContextProps['remove'];
}> = ({depth, remove, children}) => {
  const {index, options} = useModalDialogContext();
  return (
    <ModalDialog
      isOpen
      onDismiss={() => {
        const {dismissible = true} = options;
        return dismissible ? dismissModal(remove, index, options.onDismiss) : false;
      }}
      style={
        depth ? {transform: `translate(${depth * 20}px, ${depth * 30}px)`, filter: `blur(${depth * 3}px)`} : undefined
      }
      data-active-dialog={depth === 0}
    >
      {children}
    </ModalDialog>
  );
};

const renderStack = (stack: ModalStackItem[], remove: ModalDialogStackContextProps['remove']) => {
  // Nest modals within each other to make this work.
  // https://github.com/tailwindlabs/headlessui/issues/426
  let result: ReactElement | null = null;
  if (stack.length > 0) {
    for (let i = 0; i < stack.length; i += 1) {
      // Reverse order (last modal is innermost in component tree).
      const stackIndex = stack.length - 1 - i;
      const {element, options} = stack[stackIndex];
      result = (
        <ModalDialogProvider index={stackIndex} options={options}>
          <ModalDialogConsumer depth={i} remove={remove}>
            {element}
            {result}
          </ModalDialogConsumer>
        </ModalDialogProvider>
      );
    }
  }
  return result;
};

export const ModalDialogStackProvider: FC = ({children}) => {
  const [stack, setStack] = useState<ModalStackItem[]>([]);

  const push = (item: ModalStackItem) => {
    setStack((prevStack) => [...prevStack, item]);
  };

  const remove = (idx: number) => {
    setStack((prevStack) => {
      const nextStack = [...prevStack];
      nextStack.splice(idx, 1);
      return nextStack;
    });
  };

  return (
    <ModalDialogStackContext.Provider value={{stack, push, remove}}>
      {renderStack(stack, remove)}
      {children}
    </ModalDialogStackContext.Provider>
  );
};

export type ShowDialogFunction = <TParams extends never[], TConfig extends ModalDialogConfig<TParams, unknown>>(
  config: TConfig,
  ...params: ParamsTypeFromConfig<TConfig>
) => Promise<ReturnTypeFromConfig<TConfig>>;

type ParamsTypeFromConfig<T> = T extends ModalDialogConfig<infer TParams, unknown> ? TParams : never;
type ReturnTypeFromConfig<T> = T extends ModalDialogConfig<never[], infer TReturn> ? TReturn : never;

/**
 * A hook to show dialogs imperatively.
 *
 * @return `ShowDialogFunction` A function that pushes an instance of the specified `DialogConfig` onto the modal stack.
 * @exception `Error`. A `ModalDialogStackProvider` is required further up in the tree.
 */
export const useModalDialog = (): ShowDialogFunction => {
  const {push} = useModalDialogStackContext();

  return (config, ...params) => {
    const {render, defaultValue} = config;
    const element = render(...params);
    return new Promise((resolve) => {
      push({element, options: {}, resolve: resolve as (value: unknown) => void, defaultValue});
    });
  };
};

interface ModalDialogController<TReturn> {
  resolve: (value: TReturn) => void;
  close: () => void;
  dismiss: () => Promise<boolean>;
}

/**
 * A hook to control the dialog containing the current component.
 * @param options Overrides the dialog's behavior.
 * @exception `Error`. A `ModalDialogProvider` is required further up in the tree. This is provided automatically when
 * using `useModalDialog` in conjunction with a `ModalDialogStackProvider`.
 */
export const useModalDialogController = <TReturn,>(options?: ModalStackItemOptions): ModalDialogController<TReturn> => {
  const {stack, remove} = useModalDialogStackContext();
  const {index} = useModalDialogContext();
  if (stack.length === 0) {
    throw new Error('No dialogs on screen');
  }
  const item = stack[index];
  // It can be useful to change the `dismissible` and `onDismiss` props from within (for example when deciding when it's
  // safe to unmount a form inside of a dialog).
  // Note that changing any options here does not require a re-render.
  Object.assign(item.options, options);

  return {
    resolve(value) {
      const {resolve} = item;
      remove(index);
      resolve(value);
    },
    close: () => remove(index),
    dismiss: () => {
      const {
        options: {dismissible = true, onDismiss},
      } = item;
      if (dismissible) {
        item.resolve(item.defaultValue);
        return dismissModal(remove, index, onDismiss);
      }
      return Promise.resolve(false);
    },
  };
};
