export interface DateRange {
  from?: Date | null;
  to?: Date | null;
}

export const validateDateRange = (range: DateRange): boolean | string => {
  if (range.from != null && range.to != null && range.from > range.to) {
    return 'Das Startdatum muss vor dem Enddatum liegen.';
  }
  return true;
};
