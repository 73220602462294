import React, {FC} from 'react';
import {Toaster} from 'react-hot-toast';

const StyledToaster: FC = () => (
  <Toaster
    toastOptions={{
      success: {
        style: {
          backgroundColor: '#4caf50',
          color: '#fff',
        },
      },
      error: {
        style: {
          backgroundColor: '#780000',
          color: '#fff',
        },
      },
    }}
  />
);

export default StyledToaster;
