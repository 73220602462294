import React, {FC, useState} from 'react';
import {Edit} from '@mui/icons-material';
import {PoiType} from '../../poi.generated';
import InformationDisplay from './information-display';
import InformationForm from './information-form';
import FormButton from '../../../../components/forms/form-button';
import {FeatureGate} from '../../../../components/feature-gate';
import {FeatureFlagNames} from '../../../../__generated__/graphql';

const Information: FC<{poi: PoiType}> = ({poi}) => {
  const [editing, setEditing] = useState(false);

  return (
    <div data-cy='information' className='relative w-full bg-ochre-light px-6 py-4 rounded-lg'>
      <div className='flex items-center justify-between'>
        <p className='text-md font-bold'>Zusätzliche Informationen</p>
        <FeatureGate hasOneOf={{name: FeatureFlagNames.ManagePoIs, poiType: poi.__typename}}>
          <div className='flex'>
            {!editing && (
              <FormButton
                type='button'
                onClick={() => {
                  setEditing(true);
                }}
                data-cy='form-button-edit'
              >
                <Edit className='p-1' />
              </FormButton>
            )}
          </div>
        </FeatureGate>
      </div>

      {editing ? <InformationForm poi={poi} close={() => setEditing(false)} /> : <InformationDisplay poi={poi} />}
    </div>
  );
};
export default Information;
