import React, {FC} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import LoggedInLayout from '../layout/logged-in-layout/logged-in-layout';
import Home from './home';
import Poi from './poi/poi';
import {Settings} from './settings/settings';
import {AppContent} from './app-content/app-content';
import {ModalDialogStackProvider} from '../components';

const AppRoutes: FC = () => {
  const homeRoute = 'home';
  const appContentRoute = 'app-content/*';
  const poiRoute = 'poi';
  const settingsRoute = 'settings/*';

  return (
    <ModalDialogStackProvider>
      <LoggedInLayout>
        <Routes>
          <Route path={homeRoute} element={<Home />} />
          <Route path={appContentRoute} element={<AppContent />} />
          <Route path={poiRoute} element={<Poi />} />
          <Route path={`${poiRoute}/:id`} element={<Poi />} />
          <Route path={settingsRoute} element={<Settings />} />
          <Route path='*' element={<Navigate to={homeRoute} />} />
        </Routes>
      </LoggedInLayout>
    </ModalDialogStackProvider>
  );
};

export default AppRoutes;
