import React, {FC} from 'react';
import {Menu} from '../menu';
import {iconsById} from '../icons/icons';

export interface IconDefinition {
  id: string;
}

export interface IconPickerProps {
  className?: string;
  icons: IconDefinition[];
  value: string;
  onChange: (value: string) => void;
}

export const IconPicker: FC<IconPickerProps> = (props) => {
  const {className, icons, value, onChange, ...rest} = props;
  const Icon = iconsById[value];
  return (
    <Menu
      align='left'
      className={className}
      menuClassName='flex flex-wrap w-40'
      renderButtonContents={() => (
        <div className='flex items-center text-black'>{(Icon && <Icon className='h-8' />) || 'Kein Icon'}</div>
      )}
      data-cy='icon-picker'
      data-icon={value}
      {...rest}
    >
      {icons.map((icon) => (
        <Menu.Item
          key={icon.id}
          icon={iconsById[icon.id]}
          onClick={() => onChange(icon.id)}
          data-cy={`icon-option-${icon.id}`}
        />
      ))}
    </Menu>
  );
};
