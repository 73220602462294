import React, {FC, useEffect} from 'react';
import Loader from 'react-loader-spinner';
import {useTitle} from 'react-use';
import appstoreLogo from '../../assets/apps/appstore.svg';
import playstoreLogo from '../../assets/apps/playstore.svg';

const iOS = () =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

const android = () => /android/i.test(navigator.userAgent);

const zooApp = 'https://www.zoo-hannover.de/de/app';
const playstoreLink = 'market://details?id=de.zoo_hannover.app';
const appstoreLink = 'https://apps.apple.com/de/app/erlebnis-zoo-hannover/id1573572153';

export const Deeplinks: FC = () => {
  useTitle('Erlebnis Zoo Hannover');

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (iOS()) window.location.href = appstoreLink;
      else if (android()) window.open(playstoreLink);
      else window.location.href = zooApp;
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className='flex flex-col items-center justify-center h-screen space-y-4 p-6'>
      <div className='mb-6'>
        <Loader type='TailSpin' color='#b59067' height={75} width={75} />
      </div>
      <p>Sie werden gleich weitergeleitet.</p>
      <p>Sollten Sie nicht weitergeleitet werden, finden Sie hier weitere Informationen:</p>
      <div>
        <a
          className='underline decoration-ochre-dark decoration-1 hover:decoration-red-dark hover:text-red-dark block mt-4 mb-8'
          href={zooApp}
        >
          Zur App auf den Seiten des Zoo Hannover
        </a>
      </div>
      <div className='flex space-x-8'>
        <a href={playstoreLink}>
          <img src={playstoreLogo} alt='Logo des Google Play Store' className='h-16' />
        </a>
        <a href={appstoreLink}>
          <img src={appstoreLogo} alt='Logo des Apple App Store' className='h-16' />
        </a>
      </div>
    </div>
  );
};
