import React, {FC} from 'react';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {PoiType} from '../poi.generated';
import {ReactComponent as StatusIcon} from '../../../assets/icons/status.svg';

const PoiCard: FC<{poi: PoiType}> = ({poi}) => (
  <div data-cy='poi-card' className='relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2'>
    <Link to={`/poi/${poi.id}`}>
      <motion.div
        className='w-full h-full flex flex-col bg-white shadow-expandable rounded-lg overflow-hidden'
        layoutId={poi.id}
      >
        <motion.div className='w-full h-32 overflow-hidden relative' layoutId={`q${poi.id}`}>
          <motion.div className='h-full flex items-center' layoutId={`image-${poi.id}`}>
            <LazyLoadImage
              src={`/api/file/${poi.previewImage.fileId}`}
              className='object-cover min-h-full'
              alt='Bild des POIs'
            />
            {poi.allStatuses.length > 0 && <StatusIcon className='absolute top-2 left-2 w-8 h-8' />}
          </motion.div>
        </motion.div>
        <motion.div className='w-min max-w-full whitespace-nowrap text-black p-2' layoutId={`t${poi.id}`}>
          <div className='flex items-center'>
            <p className='text-lg overflow-ellipsis overflow-hidden'>{poi?.translation.name}</p>
            {poi.closed && <p className='text-red text-sm font-medium ml-1'>(Geschlossen)</p>}
          </div>
          <p className='opacity-75'>{poi.themeWorld?.name}</p>
        </motion.div>
      </motion.div>
    </Link>
  </div>
);
export default PoiCard;
