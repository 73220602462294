import * as Types from '../../../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContentStringInput = {
  location: Types.ContentStringLocation;
  value: string;
};

export type UpdateContentStringsInput = {
  strings: Array<ContentStringInput>;
};

export type ContentStringsQueryVariables = Types.Exact<{
  facilityId: any;
}>;

export type ContentStringsQuery = {__typename?: 'Query'; contentStringsForFacility: Array<ContentString>};

export type ContentString = {__typename?: 'ContentString'} & ContentStringFragment;

export type UpdateContentStringMutationVariables = Types.Exact<{
  input: UpdateContentStringsInput;
}>;

export type UpdateContentStringMutation = {
  __typename?: 'Mutation';
  contentStringsUpdate: Array<{__typename?: 'ContentString'} & ContentStringFragment>;
};

export type ContentStringFragment = {
  __typename?: 'ContentString';
  id: any;
  location: Types.ContentStringLocation;
  value: string;
};

export const ContentStringFragmentDoc = gql`
  fragment ContentString on ContentString {
    id
    location
    value
  }
`;
export const ContentStringsDocument = gql`
  query ContentStrings($facilityId: FacilityId!) {
    contentStringsForFacility(facilityId: $facilityId) @export(exportName: "ContentString") {
      ...ContentString
    }
  }
  ${ContentStringFragmentDoc}
`;

export function useContentStringsQuery(options: Omit<Urql.UseQueryArgs<ContentStringsQueryVariables>, 'query'>) {
  return Urql.useQuery<ContentStringsQuery>({query: ContentStringsDocument, ...options});
}
export const UpdateContentStringDocument = gql`
  mutation UpdateContentString($input: UpdateContentStringsInput!) {
    contentStringsUpdate(input: $input) {
      ...ContentString
    }
  }
  ${ContentStringFragmentDoc}
`;

export function useUpdateContentStringMutation() {
  return Urql.useMutation<UpdateContentStringMutation, UpdateContentStringMutationVariables>(
    UpdateContentStringDocument,
  );
}
