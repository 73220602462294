import {FC} from 'react';
import {SidebarPageDefinition, SidebarWithPages} from '../../components/sidebar-with-pages/sidebar-with-pages';
import {Feed, Image} from '@mui/icons-material';
import {FeatureFlagNames} from '../../__generated__/graphql';
import NewsLinks from './news-links';
import {Content} from './content/content';

export const AppContent: FC = () => {
  const appContentPageDefs: SidebarPageDefinition[] = [
    {
      title: 'Menü',
      icon: Feed,
      routerId: 'news-links',
      component: NewsLinks,
      featureGate: FeatureFlagNames.ManageNewsAndUpdates,
    },
    {
      title: 'Medien',
      icon: Image,
      routerId: 'content',
      component: Content,
      featureGate: FeatureFlagNames.ManageNewsAndUpdates,
    },
  ];

  return <SidebarWithPages defs={appContentPageDefs} title='Inhalte' />;
};
