import React, {FC, useCallback} from 'react';

export interface ThemeWorldTagProps {
  id: string;
  name: string;
  color: string;
  active?: boolean;
  onClick?: (id: string) => void;
}

const ThemeWorldTag: FC<ThemeWorldTagProps> = ({id, name, color: bgColor, active = true, onClick, ...rest}) => {
  const handleClick = useCallback(() => {
    if (onClick) onClick(id);
  }, [id, onClick]);

  return (
    <button
      style={active ? {backgroundColor: bgColor} : undefined}
      className={`rounded-full shadow mb-2 mr-2 px-4 py-1 cursor-pointer focus:outline-none text-white ${
        active ? '' : 'bg-black-light text-black opacity-50'
      }`}
      onClick={handleClick}
      data-cy='theme-world-tag'
      data-active={active}
      {...rest}
    >
      {name}
    </button>
  );
};

export default ThemeWorldTag;
