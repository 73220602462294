import * as Types from '../../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateUserInput = {
  email: string;
  password: string;
  roleIds: Array<any>;
};

export type DeleteUserInput = {
  id: any;
};

export type UpdateCurrentUserPasswordInput = {
  currentPassword: string;
  newPassword: string;
};

export type UpdateUserInput = {
  email: string;
  id: any;
  password?: string | null;
  roleIds: Array<any>;
};

export type CreateUserMutationVariables = Types.Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  userCreate: {__typename?: 'UserPayload'; user: {__typename?: 'User'; id: any; email: string}};
};

export type UpdateUserMutationVariables = Types.Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  userUpdate: {__typename?: 'UserPayload'; user: {__typename?: 'User'; id: any; email: string}};
};

export type DeleteUserMutationVariables = Types.Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  userDelete: {__typename?: 'UserPayload'; user: {__typename?: 'User'; id: any}};
};

export type UpdateCurrentUserPasswordMutationVariables = Types.Exact<{
  input: UpdateCurrentUserPasswordInput;
}>;

export type UpdateCurrentUserPasswordMutation = {__typename?: 'Mutation'; currentUserUpdatePassword: boolean};

export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    userCreate(input: $input) {
      user {
        id
        email
      }
    }
  }
`;

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument);
}
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    userUpdate(input: $input) {
      user {
        id
        email
      }
    }
  }
`;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
}
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    userDelete(input: $input) {
      user {
        id
      }
    }
  }
`;

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument);
}
export const UpdateCurrentUserPasswordDocument = gql`
  mutation UpdateCurrentUserPassword($input: UpdateCurrentUserPasswordInput!) {
    currentUserUpdatePassword(input: $input)
  }
`;

export function useUpdateCurrentUserPasswordMutation() {
  return Urql.useMutation<UpdateCurrentUserPasswordMutation, UpdateCurrentUserPasswordMutationVariables>(
    UpdateCurrentUserPasswordDocument,
  );
}
