import {toast} from 'react-hot-toast';
import {useCallback} from 'react';

interface UseOutcomeToastProps {
  errorText?: string;
  successText?: string;
}

export const useOutcomeToast = (props?: UseOutcomeToastProps) => {
  const {errorText = 'Speichern fehlgeschlagen.', successText = 'Speichern erfolgreich!'} = props ?? {};

  return useCallback(
    (success: boolean | undefined) => {
      if (!success) {
        toast.error(errorText, {
          icon: '🦥',
        });
      } else {
        toast.success(successText, {
          icon: '🐵',
        });
      }
    },
    [successText, errorText],
  );
};
