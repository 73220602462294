import React, {FC} from 'react';
import {Delete, Edit} from '@mui/icons-material';
import clsx from 'clsx';
import {Menu} from '../../../../components';
import {useUser} from '../../../../context/user/user';
import {UserType} from '../users.generated';

export interface UserProps {
  className?: string;
  user: UserType;
  onEdit: () => void;
  onDelete: () => void;
}

export const User: FC<UserProps> = (props) => {
  const currentUser = useUser();
  const {className, user, onEdit, onDelete, ...rest} = props;
  const isSelf = currentUser?.id === user.id;

  return (
    <div className={clsx('text-black flex items-center', className)} data-cy='user' {...rest}>
      <div className='flex flex-wrap justify-between items-center gap-2 flex-1'>
        <div className='max-w-lg'>
          <h3 className='text-sm' data-cy='user-email'>
            <span className='font-bold'>{user.email}</span> {isSelf ? '(Aktueller Nutzer)' : ''}
          </h3>
          <div className='text-sm whitespace-pre-line' data-cy='user-description'>
            {user.roles.map((r) => r.name).join(', ') || 'Keine Rolle zugewiesen'}
          </div>
        </div>
      </div>
      <div className='ml-auto'>
        <Menu>
          {onEdit && (
            <Menu.Item icon={Edit} onClick={onEdit} data-cy='edit-user'>
              Bearbeiten
            </Menu.Item>
          )}
          {!isSelf && onDelete && (
            <Menu.Item icon={Delete} onClick={onDelete} data-cy='delete-user'>
              Löschen
            </Menu.Item>
          )}
        </Menu>
      </div>
    </div>
  );
};
