import React, {FC, useEffect, useState} from 'react';
import {AccountCircle, Close, Menu, Notifications} from '@mui/icons-material';
import {NavLink, useLocation} from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import Logo from '../../../assets/logo.svg';
import NavigationLinkInterface from './navigation-link-interface';

interface Props {
  navigationLinks: NavigationLinkInterface[];
  profileLinks: NavigationLinkInterface[];
}

const NavigationLink: FC<NavigationLinkInterface> = ({label, href, onClick, dataCy}) => (
  <NavLink
    to={href ? `/${href}` : '/'}
    onClick={onClick}
    className={({isActive}) =>
      isActive
        ? 'bg-ochre-dark text-white block px-3 py-2 rounded-md text-base font-medium'
        : 'text-ochre block px-3 py-2 rounded-md text-base font-medium'
    }
    data-cy={dataCy}
  >
    {label}
  </NavLink>
);

const MobileNavigation: FC<Props> = ({navigationLinks, profileLinks}) => {
  const animationDuration = 500;
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className='block md:hidden w-full pb-52 bg-white pt-4'>
      <div className='flex justify-between text-black px-4'>
        <img src={Logo} alt='Logo' className='w-16 ml-2' />

        <button type='button' className='justify-center p-2 focus:outline-none' onClick={() => setOpen(!open)}>
          {open ? <Close /> : <Menu />}
        </button>
      </div>

      <AnimateHeight duration={animationDuration} height={open ? 'auto' : '0%'}>
        <div className='px-2 pt-8 pb-3 space-y-1 sm:px-3 '>
          {navigationLinks.map((link) => (
            <NavigationLink key={link.label} {...link} />
          ))}
        </div>
        <div className='pt-4 pb-3 border-t border-ochre'>
          <div className='flex items-center justify-between px-5'>
            <div className='max-w-xs text-ochre-light bg-ochre rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-ochre-dark focus:ring-white hover:bg-ochre-dark'>
              <AccountCircle />
            </div>
            <button
              type='button'
              className='p-1 text-ochre rounded-full hover:text-ochre-dark text-sm focus:outline-none'
            >
              <Notifications />
            </button>
          </div>
          <div className='mt-3 px-2 pb-3 space-y-1 border-b border-ochre'>
            {profileLinks.map((link) => (
              <NavigationLink key={link.label} {...link} />
            ))}
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default MobileNavigation;
