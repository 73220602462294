import {
  BrowserNativeObject,
  Control,
  FieldValues,
  InternalFieldName,
  Message,
  Path,
  Primitive,
  useController,
  UseControllerProps,
  UseControllerReturn,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayReturn,
  useForm,
  UseFormProps,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormReturn,
  useWatch,
  UseWatchProps,
  ValidationRule,
} from 'react-hook-form';
import {ValidateResult} from 'react-hook-form/dist/types/validator';
import {useMemo} from 'react';

// Future considerations:
// - scoped versions of RHF methods (e.g. watch(), setValue()) via useContext()
// - improve the stack trace on FormBuilderRegister<T> type mismatch
//   (currently it is big and scary)
// - no-conflict prefix (to enable use of reserved method names 'useFieldArray',
//   'useWatch' and 'useController')
// - figure out how to get FormBuilderRegister<any> to work
// - improve typing of the `control` prop (or make it unnecessary)

/**
 * Partially applied `register` corresponding to the field name and typed to
 * the field value.
 *
 * Use `FormBuilderRegister<T>` as the expected prop to build typed form field
 * components (e.g. `FormBuilderRegister<number>` for numeric inputs).
 */
export type FormBuilderRegister<T> = FormBuilderRegisterImpl<T>;

type FormBuilderRegisterImpl<T> = FormBuilderRegisterFn<T> & {
  // Setting T via alias U avoids contravariance in return value
  // (See below for explanation)
  useController<U = T>(
    props?: Omit<UseControllerProps, 'name' | 'control'>,
  ): UseControllerReturn<{__: U}, U extends Primitive | BrowserNativeObject ? '__' : '__'>;
  useWatch<U = T>(props?: Omit<UseWatchProps, 'name' | 'control'>): U;
} & (T extends Primitive
    ? // Leaf node
      unknown
    : T extends Array<infer U>
    ? {
        [K: number]: FormBuilderRegister<U>;
        useFieldArray<TKey extends string = 'key'>(
          props?: Omit<UseWatchProps<never>, 'name' | 'control'>,
        ): UseFieldArrayReturn<{__: U[]}, U extends Primitive | BrowserNativeObject ? never : '__', TKey>;
      }
    : {
        [K in Exclude<keyof T, `${'__'}${string}`>]-?: FormBuilderRegister<T[K]>;
      });

/*
  Using T as a generic function parameter makes it contravariant. This causes
  unexpected results when trying to match concrete `FormBuilderRegister<T>`s
  against each other. Consider this example:

  declare type A = {foo: string};
  declare type B = {foo: string, bar: string};
  declare let ra: FormBuilderRegister<A>;
  declare let rb: FormBuilderRegister<B>;
  ra = rb; // Error!

  (Contravariance here means A is expected to be assignable to B)

  However, unlike functions, method parameters in TypeScript are bivariant
  (mainly because a lot of existing stuff would break if they weren't). The
  hack is to disguise the function as a method.

  https://stackoverflow.com/questions/52667959

  Proposal: covariance and contravariance generic type arguments annotations
  https://github.com/microsoft/TypeScript/issues/10717
*/

type FormBuilderRegisterFn<T> = {
  bivarianceHack(options?: RegisterOptions<T>): UseFormRegisterReturn;
}['bivarianceHack'];

/*
  Create a recursive proxy object that returns a partially applied `register`
  from `useForm` with `name` bound to the current position (dotted path) in the
  object. Array fields expose a `useFieldArray` helper.

  In a nutshell:

  ```
  const fields = createRegistrator(register, [])
  fields.foo.bar == (options) => register('foo.bar', options)
  fields.foo.bar.useController == (props) => useController({name: 'foo.bar', ...props})
  fields.pingas[0] == (options) => register('pingas.0', options)
  fields.pingas.useFieldArray == (props) => useFieldArray({name: 'pingas', ...props})
  String(fields.foo.bar) == 'foo.bar'
  ```
*/
export function createRegistrator<TFieldValues extends FieldValues>(
  register: UseFormRegister<TFieldValues>,
  path: string[],
  control: Control,
): FormBuilderRegister<TFieldValues> {
  const currentPath = path.join('.');
  // Cache generated functions to stabilize references across re-renders.
  const cache: Record<PropertyKey, () => unknown> = {};
  return new Proxy(
    ((options?: RegisterOptions<TFieldValues>) => {
      // Calling register with an empty name is not an error in RHF, but it
      // hardly makes sense to do so.
      if (currentPath.length === 0) {
        throw new Error('Cannot call register at the root.');
      }
      return register(currentPath as Path<TFieldValues>, options as never);
    }) as FormBuilderRegister<TFieldValues>,
    {
      get(target, prop) {
        let useCached = cache[prop.toString()];
        if (useCached !== undefined) {
          return useCached;
        }
        switch (prop) {
          case Symbol.toPrimitive:
            // Called when used with `String(...)`.
            useCached = () => currentPath;
            break;
          case 'useFieldArray':
            useCached = (props?: Omit<UseFieldArrayProps, 'name' | 'control'>) =>
              useFieldArray({name: currentPath, keyName: 'key', control, ...props});
            break;
          case 'useController':
            useCached = (props?: Omit<UseControllerProps, 'name' | 'control'>) =>
              useController({name: currentPath, control, ...props});
            break;
          case 'useWatch':
            useCached = (props?: Omit<UseWatchProps, 'name' | 'control'>) =>
              useWatch({name: currentPath, control, ...props});
            break;
          default:
            // Recurse
            useCached = createRegistrator<TFieldValues>(register, [...path, prop.toString()], control);
        }
        return (cache[prop.toString()] = useCached);
      },
    },
  );
}

export interface UseFormBuilderReturn<TFieldValues extends FieldValues, TContext extends object = object>
  extends UseFormReturn<TFieldValues, TContext> {
  fields: FormBuilderRegister<TFieldValues>;
}

/**
 * A type-safe alternative to `useForm()`.
 *
 * @example ```tsx
 * const {fields, ...methods} = useFormBuilder({...});
 *
 * // Spread result the same way as with `register`.
 * <input type='number' {...fields.foo.bar({min: ..., max: ...})} />
 *
 * // Field array (only available on array values, name is pre-filled)
 * const {fields, ...arrayMethods} = fields.things.useFieldArray({...});
 *
 * // Obtain the dotted path (e.g. for `setValue`)
 * const path = String(fields.foo.bar); // 'foo.bar'
 * ```
 * @param props additional props passed to `useForm()`
 * @see FormBuilderRegister
 */
export function useFormBuilder<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormBuilderReturn<TFieldValues, TContext> {
  const methods = useForm<TFieldValues, TContext>(props);
  const fields = useMemo(
    () => createRegistrator<TFieldValues>(methods.register, [], methods.control as Control),
    [methods.register, methods.control],
  );

  return {fields, ...methods};
}

// Validate is another source of contravariance.
type Validate<T> = {
  bivarianceHack(value: T): ValidateResult;
}['bivarianceHack'];

// Copied straight from RHF and then simplified to not use the field path
// resolution. This needs to be kept in sync with RHF, but hopefully reduces
// load on the typechecker.
type RegisterOptions<T> = Partial<{
  required: Message | ValidationRule<boolean>;
  min: ValidationRule<number | string>;
  max: ValidationRule<number | string>;
  maxLength: ValidationRule<number>;
  minLength: ValidationRule<number>;
  pattern: ValidationRule<RegExp>;
  validate: Validate<T> | Record<string, Validate<T>>;
  valueAsNumber: boolean;
  valueAsDate: boolean;
  value: T;
  setValueAs: (value: unknown) => T;
  shouldUnregister?: boolean;
  onChange?: (event: Event) => void;
  onBlur?: (event: Event) => void;
  disabled: boolean;
  deps: InternalFieldName[];
}>;
