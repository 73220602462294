import * as Urql from 'urql';
import {UseMutationResponse, UseQueryResponse} from 'urql';
import {useContext, useEffect, useRef} from 'react';
import {useLogoutMutation} from '../layout/logged-in-layout/logout.generated';
import {LoadingContext} from '../context/loading/loading';
import {UserContext} from '../context/user/context';

type UrqlRequestHookOptions<Variables> = Omit<Urql.UseQueryArgs<Variables>, 'query'>;

export type UrqlQueryHook<Data, Variables> = (options: UrqlRequestHookOptions<Variables>) => UseQueryResponse<Data>;
export type UrqlMutationHook<Data, Variables> = () => UseMutationResponse<Data, Variables>;

interface UseUrqlRequestOptions {
  showLoading?: boolean;
}

type UseUrqlQueryOptions<Variables> = UrqlRequestHookOptions<Variables> & UseUrqlRequestOptions;

let nextRequestId = 1;

export function useUrqlRequest<Data, Variables>(
  useUrqlHook: UrqlQueryHook<Data, Variables>,
  options?: UseUrqlQueryOptions<Variables>,
): UseQueryResponse<Data>;
export function useUrqlRequest<Data, Variables>(
  useUrqlHook: UrqlMutationHook<Data, Variables>,
  options?: UseUrqlRequestOptions,
): UseMutationResponse<Data, Variables>;
export function useUrqlRequest<D, V>(
  useUrqlHook: UrqlQueryHook<D, V> | UrqlMutationHook<D, V>,
  options?: UseUrqlRequestOptions | UseUrqlQueryOptions<V>,
): UseQueryResponse<D> | UseMutationResponse<D, V> {
  const mergedWithDefautOptions = {showLoading: true, ...options};
  const {showLoading, ...urqlOptions} = mergedWithDefautOptions;

  const {activeRequests, addActiveRequest, removeActiveRequest} = useContext(LoadingContext);

  const response = useUrqlHook(urqlOptions);
  const [{error, fetching}] = response;
  const [, logout] = useLogoutMutation();

  const requestIdRef = useRef<number>();
  const updateUser = useContext(UserContext).update;

  if (!requestIdRef.current) {
    requestIdRef.current = nextRequestId;
    nextRequestId += 1;
  }

  useEffect(() => {
    if (requestIdRef.current === undefined) return;

    if (fetching && showLoading && !activeRequests.includes(requestIdRef.current)) {
      addActiveRequest(requestIdRef.current ?? -1);
    }

    if (!fetching && showLoading && activeRequests.includes(requestIdRef.current)) {
      removeActiveRequest(requestIdRef.current ?? -1);
    }
  }, [fetching, showLoading, requestIdRef, activeRequests, addActiveRequest, removeActiveRequest]);

  useEffect(() => {
    if (
      error &&
      error.graphQLErrors.some(
        (e) => e.extensions?.code === 'AUTH_NOT_AUTHENTICATED' || e.extensions?.code === 'AUTH_NOT_AUTHORIZED',
      )
    ) {
      logout().then(() => updateUser());
    }
  }, [error, logout, updateUser]);

  return response;
}
