import React, {FC, useCallback} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {FormProvider, useFormContext} from 'react-hook-form';
import {toast} from 'react-hot-toast';
import {Cancel, Delete, Menu, Save} from '@mui/icons-material';
import {PoiType} from '../../poi.generated';
import FormButton from '../../../../components/forms/form-button';
import {StatusMessageType} from '../../../../common/queries/status-messages.generated';
import {StatusMessagePicker} from './status-message-picker';
import {useUpdateStatusesMutation} from './statuses.generated';
import {useUrqlRequest} from '../../../../hooks/useUrqlRequest';
import FormField from '../../../../components/forms/form-field';
import AddButton from '../../../../components/add-button';
import BusinessTimeSubform, {
  BusinessTimeFormValues,
  businessTimeToFormValues,
  formValuesToBusinessTime,
} from '../business-times/business-time-subform';
import {FormBuilderRegister, useFormBuilder} from '../../../../components/forms/formbuilder';

const DraggableStatus = React.memo(
  ({
    on,
    index,
    deleteStatus,
    draggableId,
    statusMessages,
  }: {
    on: FormBuilderRegister<IStatusesForm['statuses'][number]>;
    index: number;
    deleteStatus: (index: number) => void;
    draggableId: string;
    statusMessages: StatusMessageType[];
  }) => {
    const {
      getValues,
      setValue,
      formState: {errors},
    } = useFormContext<IStatusesForm>();

    const handleScheduleToggle = useCallback(() => {
      const name = `statuses.${index as 0}.schedule` as const;
      const today = new Date();
      today.setHours(0, 0, 0);
      setValue(
        name,
        getValues(name) == null
          ? {
              valid: {},
              weekdays: {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
              },
              open: {
                from: today,
                to: today,
              },
            }
          : null,
      );
    }, [getValues, index, setValue]);

    const hasSchedule = on.schedule.useWatch() != null;

    return (
      <Draggable draggableId={draggableId} index={index}>
        {(providedDrag) => (
          <div
            ref={providedDrag.innerRef}
            {...providedDrag.draggableProps}
            className='flex items-center gap-4 my-4 p-4 border border-ochre border-opacity-25 rounded-lg bg-ochre-light shadow-lg'
            data-cy='editable-status'
          >
            <div {...providedDrag.dragHandleProps}>
              <Menu />
            </div>

            <div className='flex flex-col flex-1 max-w-100'>
              <p className='text-lg mb-2'>Welche Statusmeldung soll angezeigt werden?</p>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className='flex flex-col flex-1 max-w-100'>
                <StatusMessagePicker
                  {...on.statusMessageId()}
                  className='w-full overflow-ellipsis'
                  statusMessages={statusMessages}
                />
              </label>
              <FormField name={`statuses.${index}.dateRange` as const} errors={errors} className='mt-5'>
                <p className='text-lg mb-2'>
                  <span className='text-opacity-50 text-black'>Optional: </span>
                  Soll die Statusmeldung nach einem Zeitplan angezeigt werden?
                </p>
                {hasSchedule && <BusinessTimeSubform on={on.schedule as never} entityNamePlural='Zeiten' />}
                <button
                  type='button'
                  className='flex items-center justify-center mt-3 py-2 text-gray-400 border-dashed border-gray-300 border w-full uppercase font-semibold text-sm hover:border-solid hover:border-ochre hover:bg-ochre hover:text-white'
                  data-cy='button-toggle-schedule'
                  onClick={handleScheduleToggle}
                >
                  Zeitplan {hasSchedule ? 'entfernen' : 'hinzufügen'}
                </button>
              </FormField>
            </div>

            <FormButton type='button' onClick={() => deleteStatus(index)} data-cy='delete-status'>
              <Delete className='p-1' />
            </FormButton>
          </div>
        )}
      </Draggable>
    );
  },
);

interface IStatusesForm {
  statuses: {
    id: string | null;
    statusMessageId: string;
    schedule?: BusinessTimeFormValues | null;
  }[];
}

export interface StatusesFormProps {
  poi: PoiType;
  close: () => void;
  statusMessages: StatusMessageType[];
}

const StatusesForm: FC<StatusesFormProps> = ({poi, close, statusMessages}) => {
  const methods = useFormBuilder<IStatusesForm>({
    defaultValues: {
      statuses: poi.allStatuses
        .sort((a, b) => a.order - b.order)
        .map((status) => ({
          id: status.id,
          statusMessageId: status.message.id,
          schedule: status.schedule && businessTimeToFormValues(status.schedule),
        })),
    },
  });
  const {fields, handleSubmit} = methods;
  const {fields: statusesFields, append, remove, move} = fields.statuses.useFieldArray();

  const createStatus = useCallback(() => {
    append({
      id: null,
      statusMessageId: statusMessages[0].id,
      schedule: null,
    });
  }, [append, statusMessages]);

  const [, updateStatuses] = useUrqlRequest(useUpdateStatusesMutation);

  const saveChanges = useCallback(
    async (form: IStatusesForm) => {
      const res = await updateStatuses(
        {
          input: {
            poiId: poi.id,
            statuses: form.statuses.map((status) => ({
              id: status.id,
              statusMessageId: status.statusMessageId,
              schedule: status.schedule && formValuesToBusinessTime(status.schedule, false),
            })),
          },
        },
        {additionalTypenames: ['Status']},
      );
      if (res.error) {
        toast.error('Speichern fehlgeschlagen.', {
          icon: '🦥',
        });
      } else {
        toast.success('Speichern erfolgreich!', {
          icon: '🐵',
        });
        close();
      }
    },
    [close, poi.id, updateStatuses],
  );

  const deleteStatus = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const {destination, source} = result;
      if (!destination || destination.index === source.index) {
        return;
      }
      move(source.index, destination.index);
    },
    [move],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveChanges)}>
          <div className='absolute top-0 right-0 flex px-6 py-4'>
            <FormButton type='submit' data-cy='save-statuses-form'>
              <Save className='p-1' />
            </FormButton>
            <FormButton
              type='button'
              onClick={() => {
                close();
              }}
            >
              <Cancel className='p-1' />
            </FormButton>
          </div>

          <Droppable droppableId='status'>
            {(providedDrop) => (
              <div
                ref={providedDrop.innerRef}
                {...providedDrop.droppableProps}
                className='flex flex-col'
                data-cy='editable-status-list'
              >
                {statusesFields.map((field, index) => (
                  <DraggableStatus
                    on={fields.statuses[index]}
                    key={field.id}
                    index={index}
                    deleteStatus={deleteStatus}
                    draggableId={`item-${field.id}`}
                    statusMessages={statusMessages}
                  />
                ))}
                {providedDrop.placeholder}
              </div>
            )}
          </Droppable>
          <AddButton callback={createStatus} data-cy='button-add-status' />
        </form>
      </FormProvider>
    </DragDropContext>
  );
};

export default StatusesForm;
