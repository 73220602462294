import * as Types from '../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type StatusMessagesQueryVariables = Types.Exact<{
  facilityId: any;
  poiType: string;
}>;

export type StatusMessagesQuery = {__typename?: 'Query'; statusMessages: Array<StatusMessageType>};

export type StatusMessageType = {
  __typename?: 'StatusMessage';
  poiType: string;
  id: any;
  translations: Array<{
    __typename?: 'StatusMessageTranslation';
    languageId: string;
    title: string;
    description: string;
    url?: string | null;
  }>;
};

export const StatusMessagesDocument = gql`
  query StatusMessages($facilityId: FacilityId!, $poiType: String!) {
    statusMessages(facilityId: $facilityId, poiType: $poiType) @export(exportName: "StatusMessageType") {
      poiType
      id
      translations {
        languageId
        title
        description
        url
      }
    }
  }
`;

export function useStatusMessagesQuery(options: Omit<Urql.UseQueryArgs<StatusMessagesQueryVariables>, 'query'>) {
  return Urql.useQuery<StatusMessagesQuery>({query: StatusMessagesDocument, ...options});
}
