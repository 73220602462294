import React, {FC} from 'react';
import logo from '../../../assets/logo.svg';
import BottomBrush from '../../../assets/brush-bottom';

const Header: FC = () => (
  <div>
    <div className='w-full h-32 bg-white'>
      <div className='container mx-auto h-full flex items-center'>
        <img src={logo} alt='Logo' className='h-3/5 m-4' />
      </div>
    </div>
    <BottomBrush />
  </div>
);

export default Header;
