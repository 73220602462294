import React, {FC, useCallback} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch} from 'react-hook-form';
import {Cancel, Delete, Menu, Save} from '@mui/icons-material';
import type {DeltaStatic} from 'quill';
import {InformationType, PoiType} from '../../poi.generated';
import {useUrqlRequest} from '../../../../hooks/useUrqlRequest';
import {useUpdateInformationMutation} from './information.generated';
import FormButton from '../../../../components/forms/form-button';
import {QuillInput} from '../../../../components/forms/quill-input';
import parseQuillDelta from '../../../../util/parse-quill-delta';
import AddButton from '../../../../components/add-button';
import {useOutcomeToast} from '../../../../hooks/useOutcomeToast';

const DraggableInformation = React.memo(
  ({
    index,
    deleteInformation,
    draggableId,
  }: {
    index: number;
    deleteInformation: (index: number) => void;
    draggableId: string;
  }) => {
    const {register, control} = useFormContext<IInformationForm>();

    const value = useWatch({
      control,
    });

    const field = value?.information?.[index];

    return (
      <Draggable draggableId={draggableId} index={index}>
        {(providedDrag) => (
          <div
            ref={providedDrag.innerRef}
            {...providedDrag.draggableProps}
            className='flex items-center gap-4 my-4 p-4 border border-ochre border-opacity-25 rounded-lg bg-ochre-light shadow-lg'
          >
            <div {...providedDrag.dragHandleProps}>
              <Menu />
            </div>

            <div className='flex flex-col gap-3 w-full'>
              <label className='flex flex-col'>
                <p className='text-sm'>Titel</p>
                <input
                  type='text'
                  {...register(`information.${index}.title` as const, {required: true})}
                  defaultValue={field ? field.title : ''}
                  className='bg-ochre bg-opacity-25 border-none focus:ring-ochre-dark'
                  data-cy='input-information-title'
                />
              </label>
              <label htmlFor='description' className='flex flex-col'>
                <p className='text-sm'>Beschreibung</p>
                <Controller
                  name={`information.${index}.description` as const}
                  render={({field: description}) => (
                    <QuillInput
                      value={description.value}
                      onChange={description.onChange}
                      data-cy='input-information-description'
                    />
                  )}
                  defaultValue={field ? field.description : ''}
                />
              </label>
            </div>

            <FormButton type='button' onClick={() => deleteInformation(index)} data-cy='form-button-delete'>
              <Delete className='p-1' />
            </FormButton>
          </div>
        )}
      </Draggable>
    );
  },
);

interface IInformationForm {
  poiId: string;
  information: (Omit<InformationType, 'description'> & {description: DeltaStatic})[];
}

const InformationForm: FC<{poi: PoiType; close: () => void}> = ({poi, close}) => {
  const methods = useForm<IInformationForm>({
    defaultValues: {
      information: poi.information
        .map((info) => ({...info, description: parseQuillDelta(info.description)}))
        .sort((a, b) => (a.order < b.order ? -1 : 1)),
    },
  });
  const {control, handleSubmit} = methods;
  const {fields, append, remove, move} = useFieldArray<IInformationForm>({control, name: 'information'});

  const [, updateInformation] = useUrqlRequest(useUpdateInformationMutation);

  const createInformation = useCallback(() => {
    append({
      __typename: 'Information',
      id: null as never,
      title: '',
      description: null as never,
      order: null as never,
    });
  }, [append]);

  const toast = useOutcomeToast();
  const saveChanges = useCallback(
    async (form: IInformationForm) => {
      const res = await updateInformation(
        {
          input: {
            pOIId: poi.id,
            information: form.information.map((info, index) => ({
              title: info.title,
              description: JSON.stringify(info.description.ops),
              order: index,
            })),
          },
        },
        {additionalTypenames: ['Information']},
      );
      const success = !res.error && res.data?.informationUpdate.success;
      toast(success);

      if (success) close();
    },
    [close, poi.id, toast, updateInformation],
  );

  const deleteInformation = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const {destination, source} = result;
      if (!destination || destination.index === source.index) {
        return;
      }
      move(source.index, destination.index);
    },
    [move],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveChanges)}>
          <div className='absolute top-0 right-0 flex px-6 py-4'>
            <FormButton type='submit' data-cy='form-button-save'>
              <Save className='p-1' />
            </FormButton>
            <FormButton
              type='button'
              onClick={() => {
                close();
              }}
            >
              <Cancel className='p-1' />
            </FormButton>
          </div>

          <Droppable droppableId='information'>
            {(providedDrop) => (
              <div
                ref={providedDrop.innerRef}
                {...providedDrop.droppableProps}
                className='flex flex-col'
                data-cy='editable-information-list'
              >
                {fields.map((field, index) => (
                  <DraggableInformation
                    key={field.id}
                    index={index}
                    deleteInformation={deleteInformation}
                    draggableId={`item-${field.id}`}
                  />
                ))}
                {providedDrop.placeholder}
              </div>
            )}
          </Droppable>
          <AddButton callback={createInformation} data-cy='button-add-information' />
        </form>
      </FormProvider>
    </DragDropContext>
  );
};
export default InformationForm;
