import React, {FC} from 'react';
import {FeatureGateSpecification, useFeatureFlags} from '../context/user/feature-flags';

export const FeatureGate: FC<{hasOneOf: FeatureGateSpecification}> = ({hasOneOf, children}) => {
  const featureFlags = useFeatureFlags();
  if (!featureFlags.hasOneOf(hasOneOf)) {
    return null;
  }

  return <>{children}</>;
};
