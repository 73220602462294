import * as Types from '../../__generated__/graphql';

import {gql} from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type LanguagesQueryVariables = Types.Exact<{
  facilityId: any;
}>;

export type LanguagesQuery = {__typename?: 'Query'; languages: Array<LanguageType>};

export type LanguageType = {__typename?: 'Language'; id: string; localeKey: string; name: string};

export const LanguagesDocument = gql`
  query Languages($facilityId: FacilityId!) {
    languages(facilityId: $facilityId) @export(exportName: "LanguageType") {
      id
      localeKey
      name
    }
  }
`;

export function useLanguagesQuery(options: Omit<Urql.UseQueryArgs<LanguagesQueryVariables>, 'query'>) {
  return Urql.useQuery<LanguagesQuery>({query: LanguagesDocument, ...options});
}
