import React, {DetailedHTMLProps, forwardRef, SelectHTMLAttributes} from 'react';
import {StatusMessageType} from '../../../../common/queries/status-messages.generated';

export type StatusMessagePickerProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
  statusMessages: StatusMessageType[];
};

export const StatusMessagePicker = forwardRef<HTMLSelectElement, StatusMessagePickerProps>((props, ref) => {
  const {statusMessages, ...rest} = props;
  return (
    <select ref={ref} data-cy='status-message-picker' {...rest}>
      {statusMessages.map((msg) => {
        const translation = msg.translations.find((t) => t.languageId === 'de') || msg.translations[0];
        return (
          <option key={msg.id} value={msg.id}>
            {translation.title}
          </option>
        );
      })}
    </select>
  );
});
