import React, {FC, useContext} from 'react';
import {useLogoutMutation} from './logout.generated';
import DesktopNavigation from './components/desktop-navigation';
import MobileNavigation from './components/mobile-navigation';
import NavigationLinkInterface from './components/navigation-link-interface';
import useRandomImage from '../../hooks/useRandomImage';
import bottomBrush from '../../assets/brush-bottom.svg';
import StyledToaster from './components/styled-toaster';
import {useUrqlRequest} from '../../hooks/useUrqlRequest';
import {DialogProvider} from '../../context/dialog/dialog';

import {UserContext} from '../../context/user/context';
import {useModalDialog} from '../../components';
import {FeatureFlagNames} from '../../__generated__/graphql';
import {useFeatureFlags} from '../../context/user/feature-flags';
import Main from './components/main';
import {CurrentUserPasswordDialog} from '../../routes/settings/users/components/current-user-password-dialog';
import {useOutcomeToast} from '../../hooks/useOutcomeToast';

interface LoggedInLayoutProps {
  children: React.ReactNode;
}

const LoggedInLayout: FC<LoggedInLayoutProps> = ({children}) => {
  const userContext = useContext(UserContext);
  const featureFlags = useFeatureFlags();
  const [, logout] = useUrqlRequest(useLogoutMutation, {showLoading: false});
  const toast = useOutcomeToast();
  const showDialog = useModalDialog();

  const navigationLinks: NavigationLinkInterface[] = [
    {label: 'Home', href: 'home'},
    {label: 'Inhalte', href: 'app-content', featureFlags: [FeatureFlagNames.ManageNewsAndUpdates]},
    {label: 'POI', href: 'poi', featureFlags: [FeatureFlagNames.ManagePoIs, FeatureFlagNames.SetPoiStatus]},
    {
      label: 'Einstellungen',
      href: 'settings',
      featureFlags: [FeatureFlagNames.ManageUsers, FeatureFlagNames.ManageStatus],
    },
  ].filter((link) => !link.featureFlags || featureFlags.hasOneOf(link.featureFlags));

  const profileLinks: NavigationLinkInterface[] = [
    {
      label: 'Passwort ändern',
      dataCy: 'change-password',
      onClick: () => {
        showDialog(CurrentUserPasswordDialog).then((success) => success && toast(true));
      },
    },
    {
      label: 'Logout',
      dataCy: 'logout',
      onClick: async () => {
        await logout();

        userContext.update();
      },
    },
  ];

  return (
    <div className={`${useRandomImage()} h-full min-h-screen bg-cover bg-fixed bg-center flex flex-col`}>
      <div>
        <DesktopNavigation navigationLinks={navigationLinks} profileLinks={profileLinks} />
        <MobileNavigation navigationLinks={navigationLinks} profileLinks={profileLinks} />
        <img src={bottomBrush} alt='Brush on the top' className='w-full' />
      </div>
      <DialogProvider>
        <Main>{children}</Main>
      </DialogProvider>
      <StyledToaster />
    </div>
  );
};

export default LoggedInLayout;
