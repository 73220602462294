import React, {FC} from 'react';
import {useForm} from 'react-hook-form';
import {createModalDialog, ModalDialogHeader, useModalDialog, useModalDialogController} from '../../../../components';
import FormField from '../../../../components/forms/form-field';
import {ConfirmDialog} from '../../../../components/dialogs/confirm-dialog';
import {useRolesQuery, UserType} from '../users.generated';
import {useUser} from '../../../../context/user/user';

export interface UserFormData {
  email: string;
  password: string;
  [key: string]: boolean | string;
}

export interface UserFormResult {
  email: string;
  password: string;
  roleIds: string[];
}

export interface UserFormDialogProps {
  user: UserType;
  isNew?: boolean;
}

const userToFormData = (user: UserType): UserFormData => ({
  email: user.email,
  password: '',
  ...Object.fromEntries(user.roles.map((role) => [`role_${role.id}`, true])),
});

const UserFormDialogContent: FC<UserFormDialogProps> = (props) => {
  const {user, isNew} = props;
  const currentUser = useUser();
  const isSelf = currentUser?.id === user.id;

  const [rolesQuery] = useRolesQuery({
    variables: {facilityId: currentUser?.facilityId},
    pause: !currentUser,
  });

  const {register, handleSubmit, formState} = useForm({
    defaultValues: userToFormData(user),
  });
  const showDialog = useModalDialog();
  const {resolve, dismiss} = useModalDialogController<UserFormResult>({
    onDismiss: () => {
      const isDirty = Object.keys(formState.dirtyFields).length > 0;
      if (isDirty) {
        return showDialog(ConfirmDialog, {
          title: 'Änderungen verwerfen?',
          description: 'Nicht gespeicherte Änderungen gehen verloren.',
          cancelText: 'Behalten',
          confirmText: 'Verwerfen',
        });
      }
      return true;
    },
  });

  const submit = (value: UserFormData) => {
    resolve({
      email: value.email,
      password: value.password,
      roleIds: rolesQuery.data?.roles.filter((role) => value[`role_${role.id}`]).map((role) => role.id) ?? [],
    });
  };

  return (
    <>
      <ModalDialogHeader
        title={`Nutzer ${isNew ? 'erstellen' : 'bearbeiten'}`}
        description='Passen Sie den Nutzer an.'
      />
      <form onSubmit={handleSubmit(submit)} data-cy='status-message-form'>
        <div className='flex flex-row-reverse'>
          <div className='flex-1 relative'>
            <div className='pr-6 pl-4 py-3 w-full'>
              <FormField name='email' errors={formState.errors}>
                <label className='flex flex-col mt-5'>
                  <span className='text-sm'>E-Mail</span>
                  <input
                    type='text'
                    className='bg-ochre bg-opacity-25 border-ochre text-black'
                    data-cy='input-user-email'
                    {...register('email', {
                      validate: (s) => !!s.trim() || 'Bitte geben Sie eine E-Mail an.',
                    })}
                  />
                </label>
              </FormField>
              <FormField name='password' errors={formState.errors}>
                <label className='flex flex-col mt-5'>
                  <span className='text-sm'>
                    Passwort {isNew ? '' : '(leer lassen um das aktuelle Passwort zu behalten)'}
                  </span>
                  <input
                    type='password'
                    pattern='.{6,}|(^$)'
                    title='6 oder mehr Zeichen'
                    className='bg-ochre bg-opacity-25 border-ochre text-black'
                    data-cy='input-user-password'
                    {...register('password')}
                  />
                </label>
              </FormField>
              {!isSelf && (
                <fieldset className='mt-5'>
                  <legend>Rollen</legend>
                  {rolesQuery.data?.roles.map((role) => (
                    <div>
                      <FormField name='password' errors={formState.errors}>
                        <label className='mt-1'>
                          <input
                            type='checkbox'
                            className='bg-ochre bg-opacity-25 border-ochre text-black mr-2'
                            {...register(`role_${role.id}`)}
                          />
                          <span className='text-sm'>{role.name}</span>
                        </label>
                      </FormField>
                    </div>
                  ))}
                </fieldset>
              )}
            </div>
          </div>
        </div>
        <div className='flex mt-2'>
          <button
            type='button'
            className='flex-1 py-2 bg-gray-400 bg-opacity-25 text-black'
            onClick={dismiss}
            data-cy='dialog-cancel'
          >
            Abbrechen
          </button>
          <button type='submit' className='flex-1 py-2 bg-red text-white' data-cy='dialog-save'>
            {isNew ? 'Hinzufügen' : 'Speichern'}
          </button>
        </div>
      </form>
    </>
  );
};

export const UserFormDialog = createModalDialog(
  (props: UserFormDialogProps) => <UserFormDialogContent {...props} />,
  null as null | UserFormResult,
);

export default UserFormDialog;
